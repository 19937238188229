import React from 'react';
import InputBase from '@mui/material/InputBase';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import { useLocationsQuery } from '../../generated/graphql';
import { TransferActions } from '../../state/transfers';
import { tidyName } from '../Mobile/LocationPicker';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { useSelector } from '../../state';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      margin: useTheme().spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: useTheme().spacing(3),
    },
  }),
);

const DestinationSelector: React.FC = () => {
  const { data, loading, error } = useLocationsQuery();
  const classes = useStyles();
  const dispatch = useDispatch();
  const destinationFilter = useSelector((s) => s.transfers.destinationFilter);
  const setDestinationFilter = (v: ID[] | null) =>
    dispatch(TransferActions.setDestinationFilter(v));

  if (loading || error || !data || !data.stockLocations) return null;

  const destinations = data.stockLocations.filter((loc) =>
    ['1', '2', '4', '6', '8'].includes(loc.id),
  );

  const handleChange = (e: SelectChangeEvent<typeof destinationFilter>) => {
    const hasShowAll = (e.target.value as ID[]).includes('0');
    const value = (e.target.value as ID[]).filter((x) => x !== '0');

    if (hasShowAll) {
      if (destinationFilter != null) {
        setDestinationFilter(null);
        return;
      }

      if (value.length !== destinations.length) {
        setDestinationFilter(value);
        return;
      }
    }

    if (destinationFilter == null) {
      setDestinationFilter([]);
      return;
    }

    if (value.length === destinations.length) {
      setDestinationFilter(null);
      return;
    }

    setDestinationFilter(value);
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={destinationFilter ?? ['0', ...destinations.map((x) => x.id)]}
        input={<InputBase id="destination-input" />}
        onChange={handleChange}
        renderValue={() =>
          destinationFilter == null ? 'All' : destinationFilter.join(', ')
        }
        multiple
      >
        <MenuItem key="0" value="0" style={{ backgroundColor: 'unset' }}>
          <Checkbox color="primary" checked={destinationFilter == null} />
          <ListItemText primary={'Show All'} />
        </MenuItem>
        {destinations.map((l) => (
          <MenuItem
            key={l.id}
            value={l.id}
            style={{ backgroundColor: 'unset' }}
          >
            <Checkbox
              checked={
                destinationFilter == null || destinationFilter.includes(l.id)
              }
            />
            <ListItemText primary={tidyName(l.name)} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>Destination</FormHelperText>
    </FormControl>
  );
};

export default DestinationSelector;
