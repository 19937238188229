import React from 'react';
import Fade from '@mui/material/Fade';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';

const WHOLE_SCREEN: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
};

export const DelayedLinearProgress: React.FC<
  LinearProgressProps & { wholeScreen?: boolean }
> = ({ wholeScreen, ...props }) => (
  <Fade in unmountOnExit style={{ transitionDelay: '800ms' }}>
    <LinearProgress
      {...props}
      style={{
        zIndex: 2100,
        ...props.style,
        ...(wholeScreen ? WHOLE_SCREEN : {}),
      }}
    />
  </Fade>
);
