import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DESTINATION_COUNTRIES, MANUAL_PACK_STOCK_CODE } from '../../constants';
import { PackLabelData } from './PackLinesDisplay';

export const AddManualPackDialog: React.FC<{
  onAddPack: (data: PackLabelData) => Promise<void> | void;
  checkForDuplicatePacks: (manualPackNum: string) => boolean;
  setManualAddOpen: (showDialog: boolean) => void;
  manualAddOpen: boolean;
  useTreatmentView: boolean;
  treatments: string[] | undefined;
}> = ({ onAddPack, checkForDuplicatePacks, setManualAddOpen, manualAddOpen, useTreatmentView, treatments }) => {
  const returningFromTreatment = useTreatmentView && !treatments;
  const [manualPackNum, setManualPackNum] = useState('');
  const [manualDescription, setManualDescription] = useState('');
  const [manualMetersCubed, setManualMetersCubed] = useState(0);
  const [manualKg, setManualKg] = useState(0);
  const [manualTreatmentType, setManualTreatmentType] = useState(treatments ? treatments[0] : '');
  const [manualShippingDestination, setManualShippingDestination] = useState(returningFromTreatment ? '' : DESTINATION_COUNTRIES[0]);

  const onManualMetersCubedChange = (m3InputString: string): void => {
    const m3 = parseFloat(m3InputString);
    if (isNaN(m3)) {
      return;
    }

    const kgs = Number((m3 * 550).toFixed(3));
    setManualMetersCubed(m3);
    setManualKg(kgs);
  }

  const onManualAdd = async (): Promise<void> => {
    if (checkForDuplicatePacks(manualPackNum)) {
      return;
    }

    const data = {
      packNumber: manualPackNum,
      stockCode: MANUAL_PACK_STOCK_CODE,
      quantity: 1,
      metersCubed: manualMetersCubed,
      kilograms: manualKg,
      manualDescription: manualDescription,
      manualTreatmentType: manualTreatmentType,
      manualShippingDestination: manualShippingDestination,
      unitNumber: null
    };

    await onAddPack(data);

    setManualAddOpen(false);

    setManualPackNum('');
    setManualDescription('');
    setManualMetersCubed(0);
    setManualKg(0);
    setManualTreatmentType(treatments ? treatments[0] : '');
    setManualShippingDestination(DESTINATION_COUNTRIES[0]);
  }

  return (
    <Dialog open={manualAddOpen}>
      <DialogTitle>Manual Add</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="packId"
          label='Pack Id'
          placeholder="Scan Code..."
          type="text"
          fullWidth
          variant="standard"
          required
          onChange={(e) => setManualPackNum(e.target.value as string)}
        />
        {returningFromTreatment
          ?
          <p style={{ marginTop: '15px' }}>Enter the Pack# supplied when the pack was sent out for treatment.</p>
          :
          <>
            <TextField
              margin="dense"
              id="description"
              label="Description"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => setManualDescription(e.target.value as string)}
            />
            {/*
            Per user feedback, trial removing the option to set manual M3 and Kgs but leaving fields here if this doesn't work (March 2023)
            <TextField
              margin="dense"
              id="meters"
              label="M3"
              type="number"
              fullWidth
              variant="standard"
              required
              onChange={(e) => onManualMetersCubedChange(e.target.value as string)}
            />
            <TextField
              margin="dense"
              id="meters"
              label="KGs"
              type="number"
              fullWidth
              variant="standard"
              disabled
              value={manualKg}
            /> 
            */}
            <FormControl variant="standard" fullWidth style={{ marginTop: '15px' }}>
              <InputLabel id="treatment-label">Treatment Type</InputLabel>
              <Select
                labelId="treatment-label"
                onChange={(e) => setManualTreatmentType(e.target.value as string)}
                value={manualTreatmentType}
                variant="standard">
                {treatments?.map((treatment) => (
                  <MenuItem key={treatment} value={treatment}>
                    {treatment}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard" fullWidth style={{ marginTop: '15px' }}>
              <InputLabel id="destination-label">Destination Country</InputLabel>
              <Select
                labelId="destination-label"
                value={manualShippingDestination}
                onChange={(e) => setManualShippingDestination(e.target.value as string)}
                variant="standard">
                {DESTINATION_COUNTRIES.map((country) =>
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>)}
              </Select>
            </FormControl>
          </>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setManualAddOpen(false)}>Cancel</Button>
        <Button
          disabled={!manualPackNum || (!returningFromTreatment && (!manualTreatmentType || !manualShippingDestination))}
          onClick={() => onManualAdd()}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
};