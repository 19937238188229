import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import { Alert, MenuItem, Snackbar, TextField } from '@mui/material';
import {
  LocationsQuery,
  useRedirectTransferMutation,
} from '../../../generated/graphql';
import ConfirmationDialog from '../../ConfirmationDialog';
import { tidyName } from '../../Mobile/LocationPicker';
import {
  Location,
  STANDARD_TRANSFER_LOCATION_WHITELIST,
} from '../../../constants';
import Swal from 'sweetalert2';

export function RedirectDialog({
  open,
  onClose,
  confirmDisabled,
  locations,
  inventoryTransferId,
  currentOrigin,
  currentDestination,
}: {
  open: boolean;
  onClose: () => void;
  confirmDisabled: boolean;
  locations: LocationsQuery['stockLocations'];
  inventoryTransferId: ID;
  currentOrigin: ID;
  currentDestination: ID;
}) {
  const validEndpoints = locations.filter((l) =>
    STANDARD_TRANSFER_LOCATION_WHITELIST.includes(l.id as Location),
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [origin, setOrigin] = useState(currentOrigin);
  const [destination, setDestination] = useState(currentDestination);

  const [redirect, { loading }] = useRedirectTransferMutation({
    variables: {
      input: {
        inventoryTransferId,
        originStockLocationId: origin,
        destinationStockLocationId: destination,
      },
    },
  });

  return (
    <>
      <ConfirmationDialog
        open={open}
        close={onClose}
        handleOk={() => {
          redirect()
            .then((result) => {
              const res = result.data?.result;
              if (!res) {
                onClose();
                return;
              }

              if (res.error) {
                Swal.fire({
                  icon: 'error',
                  title: 'Failed to redirect stock',
                  text: res.error,
                });
                onClose();
                return;
              }

              onClose();
              setSnackbarOpen(true);
            })
            .catch((err) => {
              onClose();
              console.error(err);
              Sentry.captureException(err);
              Swal.fire({
                icon: 'error',
                title: 'Something went wrong',
                text: 'Please contact IT',
              });
            });
        }}
        confirmDisabled={confirmDisabled || origin === destination || loading}
        dialogText={REDIRECT_TEXT}
      >
        <RedirectForm>
          <TextField
            select
            label="New Origin"
            variant="standard"
            value={origin}
            onChange={(e) => setOrigin(e.target.value)}
          >
            {validEndpoints.map((l) => (
              <MenuItem key={l.id} value={l.id}>
                {tidyName(l.name)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="New Destination"
            variant="standard"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
          >
            {validEndpoints.map((l) => (
              <MenuItem key={l.id} value={l.id}>
                {tidyName(l.name)}
              </MenuItem>
            ))}
          </TextField>
        </RedirectForm>
      </ConfirmationDialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="success" variant="filled">
          Successfully redirected transfer
        </Alert>
      </Snackbar>
    </>
  );
}

const RedirectForm = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const REDIRECT_TEXT = (
  <>
    By redirecting this stock transfer, additional stock transfer operations
    will be performed where necessary such that the stock levels end up with the
    same values as they would have if this stock transfer had originally been
    between these locations.
    <br />
    <br />
    Are you sure you would like to continue?
  </>
);
