import React, { useState, useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import TransferPreview from './TransferPreview';
import { Loading } from '../Loading';
import { Error } from '../Error';
import { useSelector } from '../../state';
import {
  InventoryTransferStatus,
  TransfersQuery,
  useTransfersQuery,
} from '../../generated/graphql';
import { tidyName } from '../Mobile/LocationPicker';

export const TransferPreviewList: React.FC = () => {
  const { destinationFilter, status } = useSelector((s) => s.transfers);

  const { error, transfers, fetchedAll, loadMore } = useTransfers({
    status,
    destinationFilter,
  });

  if (error) return <Error />;
  if (!transfers) return <Loading style={{ height: 200 }} />;

  return (
    <div style={{ marginBottom: '4rem', overflow: 'none' }}>
      <List>
        <InfiniteScroll
          dataLength={transfers.length}
          next={loadMore}
          hasMore={!fetchedAll}
          loader={transfers.length !== 0 && <Loading style={{ height: 200 }} />}
          style={{ overflow: 'none' }}
          endMessage={
            <>
              {transfers.length > 0 && (
                <Divider light style={{ marginTop: 16 }} />
              )}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h4" style={{ padding: 16 }}>
                  No {transfers.length > 0 ? 'more ' : ''}transfers <br />
                </Typography>
              </div>
            </>
          }
        >
          {transfers.map((transfer) => (
            <TransferPreview
              key={transfer.id}
              id={transfer.id}
              dateCreated={new Date(transfer.dateCreated)}
              reference={transfer.reference}
              metresCubed={parseInt(transfer.totalMetersCubed.toFixed(3))}
              kilograms={parseInt(transfer.totalKilograms.toFixed(2))}
              packCount={transfer.packCount}
              origin={tidyName(transfer.origin.name)}
              destination={tidyName(transfer.destination.name)}
            />
          ))}
        </InfiniteScroll>
      </List>
    </div>
  );
};

// The number of transfers that are fetched at a time.
// Initially fetches this many, then after scrolling, fetches this many again,
// and so on.
const FETCH_EXTRA_SIZE = 50;

function useTransfers({
  status,
  destinationFilter: destinations,
}: {
  status: InventoryTransferStatus;
  destinationFilter: ID[] | null;
}) {
  const [fetchingMore, setFetchingMore] = useState(false);
  const [fetchCount, setFetchCount] = useState(FETCH_EXTRA_SIZE);
  const { error, data, previousData } = useTransfersQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: 30000,
    variables: {
      status,
      count: fetchCount,
    },
  });

  useEffect(() => {
    if (data != null) {
      setFetchingMore(false);
    }
  }, [data]);

  useEffect(() => {
    setFetchCount(FETCH_EXTRA_SIZE);
  }, [status]);

  const dataToUse = fetchingMore ? data ?? previousData : data;
  const rawTransfers = dataToUse?.inventoryTransfers;
  const transfers = rawTransfers && filterTransfers(rawTransfers, destinations);

  const fetchedAll = data && data.inventoryTransfers.length < fetchCount;

  const loadMore = useCallback(() => {
    if (fetchedAll) return;
    setFetchCount((count) => count + FETCH_EXTRA_SIZE);
    setFetchingMore(true);
  }, [fetchedAll]);

  return {
    transfers,
    fetchedAll,
    error,
    loadMore,
  };
}

function filterTransfers(
  transfers: TransfersQuery['inventoryTransfers'],
  destinations: ID[] | null,
) {
  // Display most recent at the top
  const sortedTransfers = [...transfers].sort(
    (a, b) =>
      new Date(b!.dateCreated).getTime() - new Date(a!.dateCreated).getTime(),
  );

  // Filter by destinations
  if (destinations) {
    const set = new Set(destinations);
    return sortedTransfers.filter((x) => set.has(x.destination.id));
  }

  return sortedTransfers;
}
