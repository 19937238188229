import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorIcon from '@mui/icons-material/Error';
import { DatePicker } from '@mui/lab';
import { Button, TextField, Container, IconButton, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TableRow, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { TreatmentHistoryQuery, useGetTransferDocumentUrlLazyQuery, useTreatmentHistoryQuery, useTreatmentPlantsQuery } from '../../../generated/graphql';
import { Error } from '../../Error';
import { Loading } from '../../Loading';
import { Location } from '../../../constants';
import Swal from 'sweetalert2';

const TreatmentHistoryPage: React.FC = () => {
  const [skipQuery, setSkipQuery] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [packNumberFilter, setPackNumberFilter] = useState('');
  const [unitNumberFilter, setUnitNumberFilter] = useState('');
  const [stockCodeFilter, setStockCodeFilter] = useState('');
  const [treatmentPlantFilter, setTreatmentPlantFilter] = useState('');
  const [treatmentTypeFilter, setTreatmentTypeFilter] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchResult, setSearchResult] = React.useState<TreatmentHistoryQuery['treatmentHistory'] | null>(null)
  const invalidStartDate = startDate != null && isNaN(startDate.getTime());
  const invalidEndDate = endDate != null && isNaN(endDate.getTime());
  const treatmentPlants = useTreatmentPlantsQuery();

  const { data, loading, error: treatmentHistoryError } = useTreatmentHistoryQuery({
    fetchPolicy: 'network-only',
    variables: {
      skip: pageIndex * pageSize,
      take: pageSize,
      dateFrom: invalidStartDate || !startDate ? null : roundStartDate(startDate!).toISOString(),
      dateTo: invalidEndDate || !endDate ? null : roundEndDate(endDate)?.toISOString(),
      packNo: packNumberFilter,
      unitNo: unitNumberFilter,
      stockCode: stockCodeFilter,
      treatmentType: treatmentTypeFilter,
      treatmentPlantId: treatmentPlantFilter
    },
    // only run on initial page load or when the user hits search, changes page size, etc.
    onCompleted: () => setSkipQuery(true),
    skip: skipQuery
  });

  useEffect(() => {
    if (data?.treatmentHistory) {
      setSearchResult(data.treatmentHistory);
    }
  }, [data]);

  if (treatmentHistoryError || treatmentPlants.error) {
    return <Error />;
  }

  const treatmentPacks = searchResult?.records ?? [];

  const getAllTreatments = (treatmentPlants: { __typename: "TreatmentPlant"; id: string; displayName: string; treatments: string[]; }[] | undefined) => {
    if (!treatmentPlants) {
      return [];
    }

    // combine all treatments offered
    let allTreatments: string[] = [];
    treatmentPlants.forEach(plant => {
      allTreatments = allTreatments.concat(plant.treatments);
    });

    // remove duplicates
    return [...new Set(allTreatments)];
  };
  const treatments = getAllTreatments(treatmentPlants.data?.treatmentPlants);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageIndex(0);
    setSkipQuery(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageIndex(newPage);
    setSkipQuery(false);
  };

  const handleSearch = () => {
    setPageIndex(0);
    setSkipQuery(false);
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.active,
      color: theme.palette.common.white,
      fontSize: 15,
    }
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '.description': {
      wordBreak: 'break-word'
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const loadingTableBody = <TableBody>
    <TableRow>
      <TableCell colSpan={14} style={{ textAlign: "center" }}>
        <Loading mobile />
      </TableCell>
    </TableRow>
  </TableBody>;

  const readyTableBody = <TableBody>
    {
      treatmentPacks.length === 0
        ?
        <StyledTableRow>
          <TableCell colSpan={14} style={{ textAlign: "center" }}>
            No Results Found
          </TableCell>
        </StyledTableRow>
        :
        treatmentPacks.map((treatmentPack, i) => (
          <StyledTableRow
            key={i}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>{treatmentPack.packNumber}</TableCell>
            <TableCell>{treatmentPack.stockCode}</TableCell>
            <TableCell>{treatmentPack.unitText ?? <Placeholder />}</TableCell>
            <TableCell className='description' >
              {
                treatmentPack.manualDescription
                ?? treatmentPack.stockItem?.description
                ?? <Placeholder />
              }
            </TableCell>
            <TableCell>{treatmentPack.treatmentType}</TableCell>
            <TableCell>{(new Date(treatmentPack.dateSent)).toLocaleDateString()}</TableCell>
            <TableCell>
              {
                treatmentPack.dateReceived
                  ? (new Date(treatmentPack.dateReceived)).toLocaleDateString()
                  : <Placeholder />
              }
            </TableCell>
            <TableCell align="right">{treatmentPack.quantity}</TableCell>
            <TableCell align="right">{treatmentPack.metresCubed}</TableCell>
            <TableCell align="right">{treatmentPack.weightKilograms}</TableCell>
            <TableCell>{treatmentPack.shippingDestination}</TableCell>
            <TableCell>{treatmentPack.treatmentPlant.displayName}</TableCell>
            <TableCell>{getEnumKeyByEnumValue(Location, treatmentPack.sentFromLocationId)}</TableCell>
            <TableCell>{getEnumKeyByEnumValue(Location, treatmentPack.returnedToLocationId ?? "") ?? <Placeholder />}</TableCell>
            <TableCell style={{ textAlign: 'center' }} >
              <DocumentDownloadButton documentId={treatmentPack.documentId}></DocumentDownloadButton>
            </TableCell>
          </StyledTableRow>
        ))
    }
  </TableBody>;

  const DateContainer = styled('div')`
  width: 10%;
`;

  return (
    <>
      <div style={{ padding: '10px 0 0 15px' }} ><Link to='/'>Home</Link></div>
      <Container maxWidth='xl' >
        <Typography variant="h2" style={{ textAlign: 'center' }}>
          Treatment History
        </Typography>
        <div style={{
          display: "flex",
          gap: "15px",
          margin: "15px"
        }}>
          <DateContainer>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={setStartDate}
              disableFuture
              inputFormat="dd/MM/yyyy"
              mask="__/__/____"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  error={invalidStartDate}
                />
              )}
            />
          </DateContainer>

          <DateContainer>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={setEndDate}
              disableFuture
              inputFormat="dd/MM/yyyy"
              mask="__/__/____"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  error={invalidEndDate}
                />
              )}
            />
          </DateContainer>

          <TextField
            variant="standard"
            autoComplete="off"
            label="Pack Number"
            onChange={(e) => setPackNumberFilter(e.target.value)}
            value={packNumberFilter}
          />

          <TextField
            variant="standard"
            autoComplete="off"
            label="Stock Code"
            onChange={(e) => setStockCodeFilter(e.target.value)}
            value={stockCodeFilter}
          />

          <TextField
            variant="standard"
            autoComplete="off"
            label="Unit No"
            onChange={(e) => setUnitNumberFilter(e.target.value)}
            value={unitNumberFilter}
          />

          <FormControl>
            <InputLabel id="treatment-plant-label">Treatment Plant</InputLabel>
            <Select
              style={{ width: 160 }}
              labelId="treatment-plant-label"
              value={treatmentPlantFilter}
              label="Treatment Plant"
              onChange={(e) => setTreatmentPlantFilter(e.target.value)}
            >
              <MenuItem value={''}>Any</MenuItem>
              {treatmentPlants?.data?.treatmentPlants?.map(plant => <MenuItem value={plant.id}>{plant.displayName}</MenuItem>)}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="treatment-type-label">Treatment Type</InputLabel>
            <Select
              style={{ width: 160 }}
              labelId="treatment-type-label"
              value={treatmentTypeFilter}
              label="Treatment Type"
              onChange={(e) => setTreatmentTypeFilter(e.target.value)}
            >
              <MenuItem value={''}>Any</MenuItem>
              {treatments.map(treatment => <MenuItem value={treatment}>{treatment}</MenuItem>)}
            </Select>
          </FormControl>

          <Button onClick={handleSearch} variant="contained" disabled={loading || invalidStartDate || invalidEndDate}>
            Search
          </Button>
        </div>

        <TableContainer component={Paper}>
          <Table aria-label="treatment history table" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>Pack No.</StyledTableCell>
                <StyledTableCell>Stock Code</StyledTableCell>
                <StyledTableCell>Unit No.</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell>Treatment</StyledTableCell>
                <StyledTableCell>Sent</StyledTableCell>
                <StyledTableCell>Returned</StyledTableCell>
                <StyledTableCell align="right">Qty</StyledTableCell>
                <StyledTableCell align="right">M3</StyledTableCell>
                <StyledTableCell align="right">KG</StyledTableCell>
                <StyledTableCell>Dest.</StyledTableCell>
                <StyledTableCell>Treated by</StyledTableCell>
                <StyledTableCell>Sent from</StyledTableCell>
                <StyledTableCell>Returned to</StyledTableCell>
                <StyledTableCell>Download</StyledTableCell>
              </TableRow>
            </TableHead>
            {loading
              ? loadingTableBody
              : readyTableBody
            }
          </Table>
        </TableContainer>
        {treatmentPacks.length !== 0 &&
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={searchResult?.totalCount ?? 0}
                rowsPerPage={pageSize}
                page={pageIndex}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />}
      </Container>
    </>
  );
};

const Placeholder: React.FC = () => {
  return <div style={{ textAlign: 'center' }}>-</div>
}

const DocumentDownloadButton: React.FC<{ documentId: string | null | undefined }> = ({
                                                                                       documentId,
                                                                                     }) => {
  const [downloadPdf] = useGetTransferDocumentUrlLazyQuery(
    {
      variables: { documentId: documentId ?? '' },
      onCompleted(data) {
        if (data?.file?.info.__typename === 'PresentFile') {
          window.open(data.file.info.accessUrl, documentId!);
        } else {
          Swal.fire("Couldn't download", 'The document is not available');
        }
      },
    }
  );

  return documentId
    ? <IconButton aria-label="download PDF" color='info' onClick={() => downloadPdf()} >
      <DownloadIcon />
    </IconButton>
    : <ErrorIcon color='error' aria-label="No Document Available" />
};

function roundStartDate(start: Date) {
  return new Date(start.setHours(0, 0, 0, 0));
}

function roundEndDate(end: Date | null) {
  if (end == null) return null;
  const res = new Date(end);
  res.setDate(res.getDate() + 1);
  res.setHours(0, 0, 0, 0);
  return res;
}

function getEnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | null {
  const keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : null;
}

export default TreatmentHistoryPage;
