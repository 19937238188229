import React from 'react';
import MobileRouter from './Mobile/Router';
import DesktopRouter from './Desktop/Router';
import { VersionNumber } from './VersionNumber';
import { SentryRoute } from './SentryRoute';
import { OfflineSimulator } from './OfflineSimulator';

const Router: React.FC = () => (
  <>
    <SentryRoute path="/mobile" component={MobileRouter} />
    <SentryRoute path="/" component={DesktopRouter} />
    <VersionNumber />
    <OfflineSimulator />
  </>
);

export default Router;
