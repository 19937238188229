import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import BackIcon from '@mui/icons-material/ArrowBack';
import {
  useLocationsQuery,
  InventoryTransferType,
  useTreatmentPlantsQuery,
  TreatmentPlant,
} from '../../../generated/graphql';
import TreatmentDirectionPicker from '../TreatmentDirectionPicker';
import LocationPicker from '../LocationPicker';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  Location,
  TREATMENT_TRANSFER_LOCATION_WHITELIST,
} from '../../../constants';
import { useCreateTransfer } from '../../../util';
import TreatmentPlantPicker from '../TreatmentPlantPicker';

enum Status {
  ChooseDirection,
  ChooseLocation,
  ChooseTreatmentPlant,
}

const NewTreatmentTransferPage: React.FC = () => {
  const history = useHistory();

  const [status, setStatus] = useState(Status.ChooseDirection);

  const locations = useLocationsQuery();
  const treatmentPlants = useTreatmentPlantsQuery();

  const [direction, setDirection] = useState<'from' | 'to'>(null!);
  const [treatmentSourceLocation, setTreatmentSourceLocation] = useState<Location>(null!);

  const create = useCreateTransfer();

  const back = () => {
    if (status === Status.ChooseDirection) {
      history.goBack();
      return;
    }

    setStatus(status - 1);
  };

  const createTransfer = (sourceLocation: Location, destinationLocation: Location, treatmentPlant: TreatmentPlant | null) => {
    create({
      originStockLocationId: sourceLocation,
      destinationStockLocationId: destinationLocation,
      inventoryTransferType: InventoryTransferType.Instant,
      shippingProviderId: null,
      treatmentPlantId: treatmentPlant?.id
    })
      .then((result) => {
        if (!result.data) {
          Sentry.captureMessage(
            'Error creating transfer',
            Sentry.Severity.Error,
          );
          console.error('Error while creating transfer');
          return;
        }
        history.push('/mobile/packs');
      })
      .catch((error) => {
        console.error(`Error while creating transfer: ${error}`);
        Sentry.captureException(error);
      });
  };

  const onLocationChosen = (locationId: ID) => {
    const leavingToTreatment = direction === 'to';
    const sourceLocation = leavingToTreatment ? (locationId as Location) : Location.Treatment;
    const destinationLocation = leavingToTreatment ? Location.Treatment : (locationId as Location);

    if (leavingToTreatment) {
      setTreatmentSourceLocation(sourceLocation)
      setStatus(Status.ChooseTreatmentPlant);
    } else {
      createTransfer(sourceLocation, destinationLocation, null);
    }
  };

  const onTreatmentPlantChosen = (plant: TreatmentPlant) => {
    createTransfer(treatmentSourceLocation, Location.Treatment, plant);
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '1rem',
        overflow: 'auto',
      }}
    >
      <HomePageStyle>
        <div className="BackButton">
          <IconButton onClick={back} size="large">
            <BackIcon />
          </IconButton>
        </div>
        {status === Status.ChooseDirection && (
          <TreatmentDirectionPicker
            onChoose={(dir) => {
              setDirection(dir);
              setStatus(Status.ChooseLocation);
            }}
          />
        )}
        {status === Status.ChooseLocation && (
          <LocationPicker
            heading={direction === 'to' ? 'FROM' : 'TO'}
            locations={locations}
            locationWhitelist={TREATMENT_TRANSFER_LOCATION_WHITELIST}
            onLocationChosen={onLocationChosen}
          />
        )}
        {status === Status.ChooseTreatmentPlant && (
          <TreatmentPlantPicker
            plants={treatmentPlants}
            onPlantChosen={onTreatmentPlantChosen}
          />
        )}
      </HomePageStyle>
    </Container>
  );
};

export default NewTreatmentTransferPage;

const HomePageStyle = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;

  .BackButton {
    position: absolute;
    top: 7px;
    left: 2px;
  }
`;
