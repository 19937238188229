import React from 'react';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import { Button, Typography } from '@mui/material';

const TreatmentDirectionPicker: React.FC<{
  onChoose: (dir: 'from' | 'to') => void;
}> = ({ onChoose }) => (
  <Container
    maxWidth="sm"
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      overflow: 'auto',
      width: '100%',
    }}
  >
    <List style={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        style={{ marginBottom: '3rem' }}
        variant="outlined"
        onClick={() => onChoose('to')}
      >
        <Typography style={{ fontSize: '0.83rem' }}>
          Going <strong>TO</strong> Treatment
        </Typography>
      </Button>
      <Button variant="outlined" onClick={() => onChoose('from')}>
        <Typography style={{ fontSize: '0.83rem' }}>
          Coming <strong>BACK</strong> from Treatment
        </Typography>
      </Button>
    </List>
  </Container>
);

export default TreatmentDirectionPicker;
