import React, { useCallback, useState } from 'react';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import BackIcon from '@mui/icons-material/ArrowBack';
import { useLocationsQuery } from '../../../generated/graphql';
import LocationPicker from '../LocationPicker';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import ConsumePacks from '../ConsumePacks';
import { CONSUME_PACKS_LOCATION_WHITELIST } from '../../../constants';
import { loadLocalStorage, saveLocalStorage } from '../../../util';

const CONSUME_LOCATION_STORAGE_KEY = 'consume-packs-location';

const NewConsumeTransferPage: React.FC = () => {
  const history = useHistory();

  const [location, setLocation_] = useState(() =>
    loadLocalStorage<ID | null>(CONSUME_LOCATION_STORAGE_KEY, null),
  );
  const setLocation = useCallback((id: ID | null) => {
    saveLocalStorage(CONSUME_LOCATION_STORAGE_KEY, id);
    setLocation_(id);
  }, []);

  const locations = useLocationsQuery();

  const back = () => {
    if (location == null) {
      history.goBack();
      return;
    }

    setLocation(null);
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '1rem',
        overflow: 'auto',
      }}
    >
      {location == null && (
        <HomePageStyle>
          <div className="BackButton">
            <IconButton onClick={back} size="large">
              <BackIcon />
            </IconButton>
          </div>
          <LocationPicker
            heading="PACKS FROM"
            locations={locations}
            locationWhitelist={CONSUME_PACKS_LOCATION_WHITELIST}
            onLocationChosen={setLocation}
          />
        </HomePageStyle>
      )}
      {location != null && (
        <ConsumePacks location={location} onBack={() => setLocation(null)} />
      )}
    </Container>
  );
};

export default NewConsumeTransferPage;

const HomePageStyle = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;

  .BackButton {
    position: absolute;
    top: 7px;
    left: 2px;
  }
`;
