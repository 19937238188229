import React, { useCallback, useEffect, useState } from 'react';
import { PackLineDisplay } from './PackLineDisplay';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ActiveUserTransfersQuery, InventoryTransferPack, InventoryTransferType } from '../../generated/graphql';
import { TransferPacksOperation } from './ScanPacks';
import { useSelector } from '../../state';
import Swal from 'sweetalert2';
import { DetailedPackLineDisplay } from './DetailedPackLineDisplay';
import { AddManualPackDialog } from './AddManualPackDialog';

export interface PackLinesDisplayProps {
  transfer: ActiveUserTransfersQuery['inventoryTransfers'][0];
  performAction: (op: TransferPacksOperation) => Promise<void>;
}

export interface NotificationPrompt {
  open: boolean;
  close: () => void;
  message: string;
}

export const PackLinesDisplay: React.FC<PackLinesDisplayProps> = ({
  transfer,
  performAction,
}) => {
  const currentUserId = useSelector((s) => s.user.user?.id);

  const totalKg = transfer.packs.reduce(
    (acc, pack) => pack.weightKilograms + acc,
    0,
  );
  const totalM3 = transfer.packs.reduce(
    (acc, pack) => pack.metresCubed + acc,
    0,
  );

  const useTreatmentView = transfer.type === InventoryTransferType.Instant;

  const handleDelete = useCallback(
    (packId: ID) => {
      if (!currentUserId) return;
      performAction({
        type: 'remove',
        packId,
        userId: currentUserId,
      });
    },
    [currentUserId, performAction],
  );

  const handleAddPack = useCallback(
    (pack: PackLabelData) => {
      if (!currentUserId) return;
      performAction({
        type: 'add',
        pack,
        userId: currentUserId,
        transferId: transfer.id,
        treatmentWarningAcknowledged: false
      });
    },
    [currentUserId, performAction, transfer.id],
  );

  return (
    <PackLines
      totalKilograms={totalKg}
      totalMetersCubed={totalM3}
      packs={transfer.packs as Array<InventoryTransferPack>}
      onDelete={handleDelete}
      onAddPack={handleAddPack}
      useTreatmentView={useTreatmentView}
      treatments={transfer.treatmentPlant?.treatments}
    />
  );
};

const PackLineDisplayStyle = styled.div`
  .MuiTableContainer-root {
    margin: 15px 0px;
    padding: 0px 15px;
  }
`;

export const PackLines: React.FC<{
  totalKilograms: number;
  totalMetersCubed: number;
  packs: Array<{
    id: ID;
    packNumber: string;
  } | InventoryTransferPack>;
  onDelete: (packId: ID) => void;
  onAddPack: (data: PackLabelData) => Promise<void> | void;
  useTreatmentView: boolean;
  treatments?: string[] | undefined;
}> = ({ totalKilograms, totalMetersCubed, packs, onDelete, onAddPack, useTreatmentView, treatments }) => {
  const [toDeleteId, setToDeleteId] = useState<ID | null>(null);
  const packToDelete = packs.find((p) => p.id === toDeleteId);
  const [scanText, setScanText_] = useState('');
  const setScanText = (value: string) => setScanText_(value.trim());
  const [manualAddOpen, setManualAddOpen] = useState(false);


  const inputRef = React.useRef<HTMLInputElement>(null);

  // ? focus the input on mount
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  // ? focus the input after closing the manual add dialog
  useEffect(() => {
    if (!manualAddOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);  // delay long enough for transition animation to complete
    }
  }, [manualAddOpen])

  // ? focus the input after deleting a pack
  useEffect(() => {
    if (toDeleteId == null) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);  // delay long enough for transition animation to complete
    }
  }, [toDeleteId])

  const tryAddPack = async () => {
    const data = parseUniversalPackLabel(scanText);
    if (data == null) {
      Swal.fire('Oops...', 'Invalid QR Code', 'error');
      return;
    }

    if (packs.find((p) => p.packNumber === data.packNumber)) {
      Swal.fire('Oops...', 'Duplicate Entry!', 'error');
      return;
    }

    await onAddPack(data);
  };

  const checkForDuplicatePacks = (packNum: string): boolean => {
    let duplicateFound = false;
    if (packs.find((p) => p.packNumber === packNum)) {
      Swal.fire('Oops...', 'Duplicate Entry!', 'error');
      duplicateFound = true;
    }

    return duplicateFound;
  }

  const onShipmentPackKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      tryAddPack();
      setScanText('');
    }
  };

  return (
    <>
      <PackLineDisplayStyle>
        <TableContainer>
          <Table aria-label="simple table" padding="none">
            <TableHead>
              <TableRow>
                <TableCell>Total Weight</TableCell>
                <TableCell align="right">Total M3</TableCell>
                <TableCell align="right">Packs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  {totalKilograms}
                </TableCell>
                <TableCell align="right">
                  {totalMetersCubed.toFixed(3)}
                </TableCell>
                <TableCell align="right">
                  {packs.length === 1
                    ? `${packs.length} Pack`
                    : `${packs.length} Packs`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </PackLineDisplayStyle>

      <div
        style={{
          width: '100%',
          flexGrow: 0.95,
          overflow: 'hidden',
          marginTop: 15,
        }}
      >
        <ScanAddPackDivStyle>
          <TextField
            inputRef={inputRef}
            autoComplete="off"
            id="QRCodeInput"
            placeholder="Scan Code..."
            variant="standard"
            value={scanText}
            onChange={(e) => setScanText(e.target.value)}
            onKeyUp={onShipmentPackKeyUp}
            fullWidth
            onBlurCapture={(e) => toDeleteId != null && e.target.focus()}
          />
          {useTreatmentView ? <Button
            style={{ width: '100%' }}
            color="primary"
            variant="outlined"
            onClick={() => setManualAddOpen(true)}>
            Add Manual
          </Button> : null}
        </ScanAddPackDivStyle>
        <div style={{ marginTop: '15px', maxHeight: '90%', overflow: 'auto' }}>
          {useTreatmentView
            ?
            <DetailedLinesStyle>
              {[...packs].reverse().map((pack) => (
                <DetailedPackLineDisplay
                  remove={(pack: InventoryTransferPack) => setToDeleteId(pack.id)}
                  pack={pack as InventoryTransferPack}
                  key={pack.id}
                />

              ))}
            </DetailedLinesStyle>
            : <List>
              {[...packs].reverse().map((pack) => (
                <PackLineDisplay
                  id={pack.id}
                  text={pack.packNumber}
                  remove={setToDeleteId}
                  key={pack.id}
                />
              ))}
            </List>
          }
        </div>
        <Dialog open={toDeleteId !== null}>
          <DialogTitle>Delete?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to remove this line
              {packToDelete !== undefined
                ? ` (${packToDelete.packNumber})`
                : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setToDeleteId(null)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                onDelete(toDeleteId!.toString());
                setToDeleteId(null);
              }}
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <AddManualPackDialog
          onAddPack={onAddPack}
          checkForDuplicatePacks={checkForDuplicatePacks}
          setManualAddOpen={setManualAddOpen}
          manualAddOpen={manualAddOpen}
          useTreatmentView={useTreatmentView}
          treatments={treatments} />

      </div>
    </>
  );
};

// Copied from Pickle client (Scan.tsx)
function parseUniversalPackLabel(scannedValue: string) {
  try {
    // Replace curly quotes
    scannedValue = scannedValue
      .replace(/[\u2018\u2019]/g, "'")
      .replace(/[\u201C\u201D]/g, '"');

    const pack = JSON.parse(scannedValue);
    const data: PackLabelData = {
      packNumber: pack.PackNumber,
      stockCode: pack.ProductCode,
      quantity: pack.Quantity,
      metersCubed: pack.M3,
      kilograms: pack.KG,
      unitNumber: pack.UnitNumber ?? null,
      manualDescription: null,
      manualTreatmentType: null,
      manualShippingDestination: null
    };

    if (data.packNumber === undefined) return null;
    if (data.stockCode === undefined) return null;
    if (data.quantity === undefined) return null;
    if (data.metersCubed === undefined) return null;
    if (data.kilograms === undefined) return null;

    return data;
  } catch {
    console.warn('Failed to parse pack label', scannedValue);
    return null;
  }
}

export type PackLabelData = {
  packNumber: string,
  stockCode: string,
  quantity: number,
  metersCubed: number,
  kilograms: number,
  unitNumber: string | null,
  manualDescription: string | null,
  manualTreatmentType: string | null,
  manualShippingDestination: string | null
};

const DetailedLinesStyle = styled.div`
  padding: 0 16px;  
  .detailed-pack-line:nth-child(even) {
    background-color: #f7f7f7;
  }
  .detailed-pack-line:nth-child(odd) {
    background-color: #f1f1f130;
  }
`;

const ScanAddPackDivStyle = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  TextField {
    background-color: red;
  }
`;