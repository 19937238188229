import React from 'react';
import dateFormat from 'dateformat';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { round } from '../../utils';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const TransferSummary: React.FC<{
  dateCreated: Date;
  destinationLocation?: string;
  originLocation?: string;
  carrier?: string;
  reference: string;
  metresCubed: number;
  weightKilograms: number;
  transferId: string;
}> = ({
  dateCreated,
  destinationLocation,
  originLocation,
  carrier,
  reference,
  metresCubed,
  weightKilograms,
  transferId,
}) => (
  <Grid container justifyContent="space-between">
    <Grid container justifyContent="space-between">
      <Grid item xs={3}>
        <Typography variant="h6">
          {dateFormat(dateCreated, 'dd/mm/yyyy')}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6">
          {(reference || 'No Reference').toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="h6">ID: {transferId}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h6" align="right">
          M3
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h6" align="right">
          Weight
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography
          color="textSecondary"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {`${(originLocation?.substring(2, 5) || 'Unk').toUpperCase()}`}
          <ArrowForwardIcon style={{ margin: 3 }} />
          {`${(destinationLocation?.substring(2, 5) || 'Unk').toUpperCase()}`}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography color="textSecondary">
          {(carrier || 'Unknown Carrier').toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography color="textSecondary" align="right">
          {round(metresCubed, 3)}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography color="textSecondary" align="right">{`${round(
          weightKilograms,
          2,
        )} KG`}</Typography>
      </Grid>
    </Grid>
  </Grid>
);

export default TransferSummary;
