import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { PackManagementConfirmation } from '../PackManagerConfirmation';
import { PrintAndUpdate } from '../PrintAndUpdate';
import { ScanPacks } from '../ScanPacks';
import { useActiveTransfer } from '../../../util';

type Page = 'scan' | 'confirm' | 'reference' | 'commit';

const TransferPacksPage: React.FC = () => {
  const [page, setPage] = useState<Page>('scan');

  const { transfer } = useActiveTransfer(true);

  return (
    <Container
      maxWidth="sm"
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        height: '100vh',
        padding: 12,
      }}
    >
      {page === 'scan' && (
        <ScanPacks
          transfer={transfer ?? null}
          handlePackScan={() => setPage('confirm')}
        />
      )}
      {page === 'confirm' && transfer && (
        <PackManagementConfirmation
          transferId={transfer.id}
          handlePackConfirm={() => setPage('commit')}
        />
      )}
      {page === 'commit' && transfer && (
        <PrintAndUpdate transferId={transfer.id} />
      )}
    </Container>
  );
};

export default TransferPacksPage;
