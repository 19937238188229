import { combineReducers, createStore } from 'redux';
import * as Sentry from '@sentry/react';
import { composeWithDevTools } from '@redux-devtools/extension';
import {
  useSelector as useSelectorGeneric,
  TypedUseSelectorHook,
} from 'react-redux';

import user from './user';
import transfers from './transfers';

const composeEnhancers = composeWithDevTools({
  serialize: true,
});

const rootReducer = combineReducers({
  user,
  transfers,
});

export type AppState = ReturnType<typeof rootReducer>;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  configureScopeWithState: (scope, state: AppState) => {
    const { user } = state.user;
    if (user == null) {
      scope.setUser(null);
      scope.setTag('user.auth', 'logged-out');
    } else {
      scope.setUser({
        id: user.id,
        username: `${user.givenName} ${user.familyName}`,
      });

      scope.setTag('user.auth', 'logged-in');
    }
  },
});

export default function configureStore() {
  return createStore(rootReducer, composeEnhancers(sentryReduxEnhancer));
}

export const useSelector: TypedUseSelectorHook<AppState> = useSelectorGeneric;
