import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useHistory } from 'react-router-dom';
import {
  useDeleteTransferMutation,
  useGiveUpTransferMutation,
} from '../generated/graphql';

interface MainProps {
  open: boolean;
  close: () => void;
  error: boolean;
  message?: string;
}

export type BackButtonDialogProps = MainProps &
  (
    | { inventoryTransferId: ID }
    | { noDeleteTransfer: true; onBack?: () => void }
  );

export const BackButtonDialog: React.FC<BackButtonDialogProps> = (props) => {
  const { open, close, error, message } = props;

  const [deleteTransfer] = useDeleteTransferMutation();
  const [giveUpTransfer] = useGiveUpTransferMutation();
  const history = useHistory();

  const goBack = () => {
    history.push('/mobile');
  };

  const backToStart = async () => {
    if ('noDeleteTransfer' in props) {
      (props.onBack ?? goBack)?.();
      return;
    }

    const { inventoryTransferId } = props;

    if (error) {
      await giveUpTransfer({
        variables: {
          input: { inventoryTransferId },
        },
        refetchQueries: ['ActiveUserTransfers'],
        awaitRefetchQueries: true,
      });
    } else {
      await deleteTransfer({
        variables: {
          input: { inventoryTransferId },
        },
        refetchQueries: ['ActiveUserTransfers'],
        awaitRefetchQueries: true,
      });
    }

    goBack();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message ??
              'Are you sure you want to delete your current transfer?'}
          </DialogContentText>
        </DialogContent>
        <ButtonStyle>
          <DialogActions>
            <Button onClick={close} color="primary">
              Close
            </Button>
            <Button
              onClick={backToStart}
              variant="contained"
              color="secondary"
              disableElevation
            >
              Yes
            </Button>
          </DialogActions>
        </ButtonStyle>
      </Dialog>
    </div>
  );
};

const ButtonStyle = styled.div`
  .MuiDialogActions-root {
    justify-content: center;
  }
`;
