import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { PickerStyle } from './LocationPicker';
import {
  TreatmentPlant,
  TreatmentPlantsQuery,
  TreatmentPlantsQueryVariables,
} from '../../generated/graphql';
import { QueryResult } from '@apollo/client';
import { Loading } from '../Loading';
import { Error } from '../Error';

const TreatmentPlantPicker: React.FC<{
  onPlantChosen: (loc: TreatmentPlant) => void;
  plants: QueryResult<TreatmentPlantsQuery, TreatmentPlantsQueryVariables>;
}> = ({ onPlantChosen, plants }) => {
  if (plants.loading) {
    return <Loading />;
  }

  if (plants.error || !plants.data) {
    return <Error />;
  }

  return (
    <PickerStyle>
      <Typography variant="h5" align="center">
        Going to
      </Typography>
      <List>
        {plants.data.treatmentPlants.map((plant) => (
          <ListItem
            button
            key={plant.id}
            className="ListItem"
            onClick={() => onPlantChosen(plant)}
          >
            <Typography
              align="center"
              style={{
                width: '100%',
                fontSize: '20pt',
              }}
            >
              {plant.displayName}
            </Typography>
          </ListItem>
        ))}
      </List>
    </PickerStyle>
  );
};

export default TreatmentPlantPicker;
