export enum Location {
  Motueka = '1',
  Auckland = '2',
  Export = '3',
  Christchurch = '4',
  InTransit = '5',
  Moutere = '6',
  Treatment = '7',
  Richmond = '8',
}

export const CONSUME_PACKS_LOCATION_WHITELIST = [
  Location.Motueka,
  Location.Auckland,
  Location.Christchurch,
  Location.Moutere,
  Location.Richmond,
];

export const STANDARD_TRANSFER_LOCATION_WHITELIST = [
  Location.Motueka,
  Location.Auckland,
  Location.Christchurch,
  Location.Moutere,
  Location.Richmond,
];

export const TREATMENT_TRANSFER_LOCATION_WHITELIST = [
  Location.Motueka,
  Location.Auckland,
  Location.Export,
  Location.Christchurch,
  Location.Moutere,
  Location.Richmond,
];

export const DESTINATION_COUNTRIES = ["NZ", "ASIA", "AU"];
export const MANUAL_PACK_STOCK_CODE = 'MANUAL';
