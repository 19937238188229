import React from 'react';
import {
  canSimulateOffline,
  toggleSimulateOffline,
  useSimulateOffline,
} from '../services/apollo/network';
import Wifi from '@mui/icons-material/Wifi';
import WifiOff from '@mui/icons-material/WifiOff';

/**
 * TEST UTILITY, ONLY ACTIVE LOCALLY AND IN DEV
 *
 * Small wi-fi icon in the bottom right corner that allows you to toggle
 * simulating network issues. Useful for testing how the application responds to
 * connectivity issues in a variety of situations - any requests triggered
 * through Apollo when "offline" fail (and will be retried as configured).
 */
export const OfflineSimulator: React.FC = () => {
  // Not visible in production
  if (!canSimulateOffline) return null;

  // Condition above is fixed for the application's lifetime
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const simulating = useSimulateOffline();

  return (
    <div
      role="button"
      style={{ position: 'fixed', right: '10px', bottom: '10px', zIndex: 2000 }}
      onClick={toggleSimulateOffline}
    >
      {simulating ? <WifiOff /> : <Wifi />}
    </div>
  );
};
