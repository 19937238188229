import { version } from '../package.json';

const localhost = {
  profile: 'localhost',
  version,
  serverUri: 'https://staging-api.genia.co.nz/graphql', // <-- local or remote server as needed
  //serverUri: 'http://localhost:4040/graphql', // <-- local or remote server as needed
};

const development = {
  profile: 'development',
  version,
  serverUri: 'https://staging-api.genia.co.nz/graphql',
};

const staging = {
  profile: 'staging',
  version,
  serverUri: 'https://staging-api.genia.co.nz/graphql',
};

const production = {
  profile: 'production',
  version,
  serverUri: 'https://api.genia.co.nz/graphql',
};

export const env = (() => {
  const url = window.location.href;
  if (url.includes('universal-print.dev01.genia.co.nz')) return development;
  if (url.includes('staging-transit-tracker.genia.co.nz')) return staging;
  if (url.includes('transit-tracker.genia.co.nz')) return production;
  return localhost;
})();

export const releaseProfile = () => env.profile || 'local';
