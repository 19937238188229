import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import Select from '@mui/material/Select';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../state';
import { TransferActions } from '../../state/transfers';
import FormHelperText from '@mui/material/FormHelperText';
import { InventoryTransferStatus } from '../../generated/graphql';

const TransferStatusSelector: React.FC = () => {
  const dispatch = useDispatch();
  const status = useSelector<AppState, InventoryTransferStatus>(
    (s) => s.transfers.status,
  );

  return (
    <>
      <Select
        value={status}
        onChange={(e) => {
          return dispatch(
            TransferActions.setStatus(
              e.target.value as InventoryTransferStatus,
            ),
          );
        }}
        input={<InputBase id="shipmentstatus-input" />}
      >
        <MenuItem value={InventoryTransferStatus.InTransit}>
          In Transit
        </MenuItem>
        <MenuItem value={InventoryTransferStatus.Complete}>Completed</MenuItem>
      </Select>
      <FormHelperText>Status</FormHelperText>
    </>
  );
};

export default TransferStatusSelector;
