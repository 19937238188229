import React from 'react';
import { SentryRoute } from '../SentryRoute';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserSelectors } from '../../state/user';
import { LoginPage } from '../Mobile/Pages/LoginPage';

import TransferListPage from './Pages/TransferListPage';
import TransferPage from './Pages/TransferPage';
import TreatmentHistoryPage from './Pages/TreatmentHistoryPage';

const Router: React.FC = () => {
  const match = useRouteMatch();
  const { pathname } = useLocation();

  const loggedIn = useSelector(UserSelectors.loggedIn);
  if (!loggedIn && pathname !== '/mobile') return <LoginPage />;

  return (
    <>
      <SentryRoute
        exact
        path={`${match.path}treatment-history`}
        component={TreatmentHistoryPage}
      />
      <SentryRoute exact path={match.path} component={TransferListPage} />
      <SentryRoute
        exact
        path={`${match.path}transfer/:id`}
        component={TransferPage}
      />
    </>
  );
};

export default Router;
