import React from 'react';
import dateFormat from 'dateformat';
import ListItem from '@mui/material/ListItem';
import { Link } from 'react-router-dom';
import ListItemText from '@mui/material/ListItemText';

export interface TransferPreviewProps {
  id: ID;
  reference: string;
  dateCreated: Date;
  packCount: number;
  metresCubed: number;
  kilograms: number;
  origin?: string;
  destination?: string;
}

const TransferPreview: React.FC<TransferPreviewProps> = ({
  id,
  reference,
  dateCreated,
  packCount,
  metresCubed,
  kilograms,
  origin,
  destination,
}) => (
  <Link to={`/transfer/${id}`} style={{ all: 'unset', width: '100%' }}>
    <ListItem button>
      <div
        style={{
          display: 'grid',
          width: '100%',
          gridTemplateColumns: '3fr 8fr 4fr 4fr 4fr 2fr',
        }}
      >
        <ListItemText primary={id} secondary="ID" />
        <ListItemText
          primary={(reference || 'No Reference').toUpperCase()}
          secondary={dateFormat(dateCreated, 'yyyy/mm/dd')}
        />
        <ListItemText primary={origin} secondary="From" />
        <ListItemText primary={destination} secondary="To" />
        <ListItemText
          primary={`${kilograms} KG`}
          secondary={`${metresCubed} M3`}
        />
        <ListItemText primary={packCount} secondary="Count" />
      </div>
    </ListItem>
  </Link>
);

export default TransferPreview;
