import React from 'react';
import { useDispatch } from 'react-redux';
import { TransferActions } from '../../../state/transfers';
import { Button, ButtonBase, Container, Typography } from '@mui/material';
import CONTAINER_ICON from '../../../assets/icons/container.svg';
import TREATMENT_ICON from '../../../assets/icons/treatment.svg';
import CONSUME_PACKS_ICON from '../../../assets/icons/consume-packs.svg';
import TRUCK_ICON from '../../../assets/icons/truck.svg';
import COMPANY_LOGO from '../../../assets/companyLogo.png';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { UserActions } from '../../../state/user';

const HomePage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  type Kind = 'truck' | 'container' | 'treatment' | 'consume';
  const handleNewTransfer = (kind: Kind) => {
    if (kind === 'treatment') {
      history.push('/mobile/treatment');
      return;
    }

    if (kind === 'consume') {
      history.push('/mobile/consume-packs');
      return;
    }

    dispatch(TransferActions.setSelectedTransferKind(kind));
    history.push('/mobile/transfer');
  };

  const logout = () => {
    dispatch(UserActions.logout());
  };

  return (
    <ContainerStyle maxWidth="sm">
      <HomePageStyle>
        <Button
          style={{ position: 'absolute', left: 8, top: 8 }}
          onClick={logout}
        >
          Log Out
        </Button>
        <div className="inner">
          <img
            src={COMPANY_LOGO}
            style={{ width: '100%', height: '100%' }}
            alt="treatment"
          />
          <div style={{ flexDirection: 'row' }}>
            <div
              style={{
                boxShadow: '0 0 15px 0 rgba(0,0,0,0.25)',
                textAlign: 'center',
                height: '8rem',
                width: '8rem',
                marginRight: '1rem',
                backgroundColor: '#000',
              }}
            >
              <ButtonBase onClick={() => handleNewTransfer('truck')}>
                <img
                  src={TRUCK_ICON}
                  style={{ width: '8em', height: '6em' }}
                  alt="treatment"
                />
              </ButtonBase>
              <Typography
                variant="button"
                align="center"
                style={{ color: 'white' }}
              >
                Truck Shipment
              </Typography>
            </div>

            <div
              style={{
                boxShadow: '0 0 15px 0 rgba(0,0,0,0.25)',
                textAlign: 'center',
                height: '8rem',
                width: '8rem',
                backgroundColor: '#000',
              }}
            >
              <ButtonBase onClick={() => handleNewTransfer('container')}>
                <img
                  src={CONTAINER_ICON}
                  style={{ width: '6em', height: '6em' }}
                  alt="treatment"
                />
              </ButtonBase>
              <Typography
                variant="button"
                align="center"
                style={{ color: 'white' }}
              >
                Container Shipment
              </Typography>
            </div>
          </div>

          <div style={{ flexDirection: 'row' }}>
            <div
              style={{
                boxShadow: '0 0 15px 0 rgba(0,0,0,0.25)',
                textAlign: 'center',
                height: '8rem',
                width: '8rem',
                marginRight: '1rem',
                backgroundColor: '#000',
              }}
            >
              <ButtonBase
                style={{ marginTop: '1rem' }}
                onClick={() => handleNewTransfer('treatment')}
              >
                <img
                  src={TREATMENT_ICON}
                  style={{ width: '6em', height: '6em' }}
                  alt="treatment"
                />
              </ButtonBase>
              <Typography
                variant="button"
                align="center"
                style={{ color: 'white' }}
              >
                Treatment
              </Typography>
            </div>
            <div
              style={{
                boxShadow: '0 0 15px 0 rgba(0,0,0,0.25)',
                textAlign: 'center',
                height: '8rem',
                width: '8rem',
                backgroundColor: '#000',
              }}
            >
              <ButtonBase onClick={() => handleNewTransfer('consume')}>
                <img
                  src={CONSUME_PACKS_ICON}
                  style={{ width: '6em' }}
                  alt="consume-packs"
                />
              </ButtonBase>
              <Typography
                variant="button"
                align="center"
                style={{ color: 'white' }}
              >
                Consume packs
              </Typography>
            </div>
          </div>
        </div>
      </HomePageStyle>
    </ContainerStyle>
  );
};

const HomePageStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .BackButton {
    position: absolute;
    top: 7px;
    left: 2px;
  }

  .inner > * {
    display: flex;
    flex-direction: column;
    margin-top: 1.25rem;
    justify-content: center;
  }

  .inner > :first-child {
    margin-top: 0;
  }
`;

const ContainerStyle = styled(Container)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  overflow: auto;
`;

export default HomePage;
