import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import BackIcon from '@mui/icons-material/ArrowBack';
import {
  useLocationsQuery,
  useFreightCarriersQuery,
  InventoryTransferType,
} from '../../../generated/graphql';
import { TransferSelectors } from '../../../state/transfers';
import TruckProvidersPicker from '../TruckProvidersPicker';
import LocationPicker from '../LocationPicker';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from '../../../state';
import { STANDARD_TRANSFER_LOCATION_WHITELIST } from '../../../constants';
import { useCreateTransfer } from '../../../util';

enum Status {
  ChooseTruckType,
  ChooseOrigin,
  ChooseDestination,
}

interface InventoryTransferCreateArgs {
  transferType: InventoryTransferType;
  destination: ID;
}

const NewTransferPage: React.FC = () => {
  const history = useHistory();

  const transferKind = useSelector(TransferSelectors.transferKind);
  const [status, setStatus] = useState(
    transferKind === 'truck' ? Status.ChooseTruckType : Status.ChooseOrigin,
  );

  const locations = useLocationsQuery();
  const freightCarriers = useFreightCarriersQuery();

  const [origin, setOrigin] = useState<ID>(null!);
  const [freightCarrier, setFreightCarrier] = useState<ID>(null!);

  // remove Richmond from selectable locations for containers
  const containerLocations = STANDARD_TRANSFER_LOCATION_WHITELIST.filter((location) => location !== '8');

  const create = useCreateTransfer();

  const back = () => {
    if (
      (status === Status.ChooseOrigin && transferKind === 'container') ||
      status === Status.ChooseTruckType
    ) {
      history.goBack();
      return;
    }

    setStatus((v) => ((v as number) - 1) as Status);
  };

  const createTransfer = ({
    transferType,
    destination,
  }: InventoryTransferCreateArgs) => {
    create({
      originStockLocationId: origin,
      destinationStockLocationId: destination,
      inventoryTransferType: transferType,
      shippingProviderId: freightCarrier,
      treatmentPlantId: null
    })
      .then((result) => {
        if (!result.data) {
          Sentry.captureMessage(
            'Error creating transfer',
            Sentry.Severity.Error,
          );
          console.error('Error while creating transfer');
          return;
        }
        history.push('/mobile/packs');
      })
      .catch((error) => {
        console.error(`Error while creating transfer: ${error}`);
        Sentry.captureException(error);
      });
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '1rem',
        overflow: 'auto',
      }}
    >
      <HomePageStyle>
        <div className="BackButton">
          <IconButton onClick={back} size="large">
            <BackIcon />
          </IconButton>
        </div>
        {status === Status.ChooseTruckType && (
          <TruckProvidersPicker
            freightCarriers={freightCarriers}
            onChoose={(carrier) => {
              setFreightCarrier(carrier);
              setStatus(Status.ChooseOrigin);
            }}
          />
        )}
        {status === Status.ChooseOrigin && (
          <LocationPicker
            heading="FROM"
            locations={locations}
            locationWhitelist={
              transferKind === 'container' ? containerLocations : STANDARD_TRANSFER_LOCATION_WHITELIST
            }
            onLocationChosen={(loc) => {
              setOrigin(loc);
              setStatus(Status.ChooseDestination);
            }}
          />
        )}
        {status === Status.ChooseDestination && (
          <LocationPicker
            heading="TO"
            locations={locations}
            locationWhitelist={
              transferKind === 'container' ? containerLocations : STANDARD_TRANSFER_LOCATION_WHITELIST
            }
            locationBlacklist={[origin]}
            onLocationChosen={(loc) => {
              createTransfer({
                transferType:
                  transferKind === 'container'
                    ? InventoryTransferType.Container
                    : InventoryTransferType.Truck,
                destination: loc,
              });
            }}
          />
        )}
      </HomePageStyle>
    </Container>
  );
};

export default NewTransferPage;

const HomePageStyle = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;

  .BackButton {
    position: absolute;
    top: 7px;
    left: 2px;
  }
`;
