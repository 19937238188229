import { createAction, ActionType, createReducer } from 'typesafe-actions';
import { AppState } from '.';
import { User } from '../generated/graphql';

export type UserWithPin = User & { pin: string };

export interface UserState {
  readonly user: UserWithPin | null;
}

export const UserActions = {
  login: createAction('@user/LOGIN', (user: UserWithPin) => user)(),
  logout: createAction('@user/LOGOUT')(),
};

export const UserSelectors = {
  loggedIn: (state: AppState) => state.user.user !== null,
  current: (state: AppState) => state.user.user,
};

export const clearCachedPin = () => sessionStorage.removeItem('user-pin');
const setCachedPin = (pin: string) => sessionStorage.setItem('user-pin', pin);
export const getCachedPin = () => sessionStorage.getItem('user-pin');

const initialState: UserState = {
  user: null,
};

type Action = ActionType<typeof UserActions>;

const userReducer = createReducer<UserState, Action>(initialState)
  .handleAction(UserActions.login, (_, { payload }) => {
    setCachedPin(payload.pin);
    return {
      user: payload,
    };
  })
  .handleAction(UserActions.logout, (_) => {
    clearCachedPin();
    return { user: null };
  });

export default userReducer;
