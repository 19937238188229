import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: ID;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  JSON: unknown;
  Upload: unknown;
};

/** Describes access permissions to an application. */
export type AppAccess = {
  __typename: 'AppAccess';
  /** Textual ID for identifying the app. */
  id: Scalars['String'];
};

export type ContactEmail = {
  __typename: 'ContactEmail';
  email: Scalars['String'];
  type: ContactEmailType;
};

export enum ContactEmailType {
  Bcc = 'BCC',
  Cc = 'CC',
  To = 'TO'
}

/**
 * Stores a number which changes when used, intended for generating sequences or
 * unique identifiers.
 */
export type Counter = {
  __typename: 'Counter';
  /** Unique identifier for the counter. Can be any string. */
  id: Scalars['ID'];
  /**
   * The amount the `value` of the counter will change when used once. May be
   * negative.
   */
  updateChange: Scalars['Int'];
  /** The counter's current value. */
  value: Scalars['Int'];
};

export type CounterCreateInput = {
  /** Unique identifier for fetching the `Counter` once created. Can be any string. */
  id: Scalars['ID'];
  /**
   * The initial value of the counter. Is the value that will be returned when
   * first running the `useCounter` mutation for this `Counter` ID.
   */
  initialValue: Scalars['Int'];
  /**
   * If `true`, will replace any existing counter. If `false` or unspecified,
   * counter creation would fail if the ID was already registered.
   */
  reinitialize?: InputMaybe<Scalars['Boolean']>;
  /**
   * The amount the `value` of the counter will change when used once. May be
   * negative.
   */
  updateChange: Scalars['Int'];
};

/** Sales order created in the CRM system. */
export type CrmSalesOrder = {
  __typename: 'CrmSalesOrder';
  customerReference: Scalars['String'];
  dateCreated: Scalars['Date'];
  deliverTo: Array<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lines: Array<CrmSalesOrderLine>;
};

/** Line on a `CrmSalesOrder`. */
export type CrmSalesOrderLine = {
  __typename: 'CrmSalesOrderLine';
  description: Scalars['String'];
  quantity: Scalars['Float'];
  stockCode?: Maybe<Scalars['ID']>;
  unitPrice: Scalars['Float'];
};

export type Debtor = {
  __typename: 'Debtor';
  id: Scalars['ID'];
};

export type DebtorInvoice = {
  __typename: 'DebtorInvoice';
  id: Scalars['ID'];
};

export type DebtorInvoiceLine = {
  __typename: 'DebtorInvoiceLine';
  id: Scalars['ID'];
};

/** Information about a file that has been deleted. */
export type DeletedFile = {
  __typename: 'DeletedFile';
  deletionReason: FileDeletionReason;
};

/** A durable file containing human-readable information. Can be printed. */
export type Document = {
  __typename: 'Document';
  /** Unique ID. */
  id: Scalars['ID'];
  /** URL for displaying the document, valid for approximately 30 minutes */
  url: Scalars['String'];
};

export type DocumentGenerateCommandInput = {
  /**
   * The data to apply to the `DocumentTemplate` to generate a document.
   * If the `DocumentTemplate` provides default/example data, this is optional.
   */
  data?: InputMaybe<Scalars['JSON']>;
  /** The `DocumentTemplate` that should be used, for example 'examples.hello'. */
  templateId: Scalars['ID'];
  /**
   * Location to upload the generated document to. Should be an Azure Blob Storage
   * URL (with SAS token or otherwise authenticated), typically obtained via use of
   * `uploadHandlesGet`.
   */
  uploadUrl: Scalars['String'];
};

export type DocumentGenerateInput = {
  /** List of commands for generating documents. */
  commands: Array<DocumentGenerateCommandInput>;
};

export type DocumentGeneratePayload = {
  __typename: 'DocumentGeneratePayload';
  /**
   * Always an empty array
   * @deprecated Always an empty array, so no longer serves a purpose.
   */
  documents: Array<Document>;
  message: Scalars['String'];
};

export type DocumentPrintCommandInput = {
  /** Number of copies to print. Cannot be negative. Defaults to 1. */
  copies?: InputMaybe<Scalars['Int']>;
  /** ID of the printer to use. */
  printer: Scalars['String'];
  /** Location to fetch the document from, before printing. Must be a PDF file. */
  url: Scalars['String'];
};

export type DocumentPrintInput = {
  /** List of commands for printing. */
  commands: Array<DocumentPrintCommandInput>;
};

export type DocumentPrintPayload = {
  __typename: 'DocumentPrintPayload';
  message: Scalars['String'];
};

export enum EmailAttachmentContentKind {
  /** Treat contents as the attachment data itself, encoded as base 64. */
  Base64 = 'BASE64',
  /** Treat contents as a URL to download the attachment data from. */
  DownloadUrl = 'DOWNLOAD_URL'
}

export type EmailSendAttachmentInput = {
  /** The raw content of the attachment. */
  content: Scalars['String'];
  /** Describes how to interpret `content`. */
  contentKind: EmailAttachmentContentKind;
  /** The content type of the attachment, e.g. `application/pdf`. */
  contentType: Scalars['String'];
  /** The filename the attachment should have. */
  filename: Scalars['String'];
};

export type EmailSendEmailInput = {
  /** List of attachments to send. */
  attachments: Array<EmailSendAttachmentInput>;
  /** List of BCC email recipients. If not provided, equivalent to the empty list. */
  bcc?: InputMaybe<Array<Scalars['String']>>;
  /** List of CC email recipients. If not provided, equivalent to the empty list. */
  cc?: InputMaybe<Array<Scalars['String']>>;
  /**
   * The email address acting as the sender. Must be from a domain configured with
   * SendGrid. If not specified, the default from address will be used.
   */
  from?: InputMaybe<Scalars['String']>;
  /** Use to define the header, such as setting Importance levels. */
  headers?: InputMaybe<Scalars['JSON']>;
  /** Only use if `templateId` is null. */
  html?: InputMaybe<Scalars['String']>;
  /** Only use if `templateId` is null. */
  subject?: InputMaybe<Scalars['String']>;
  /** Only use if `templateId` is null. */
  templateData?: InputMaybe<Scalars['JSON']>;
  /** The template id to use for the data (see `templates.staging-production.json`). */
  templateId?: InputMaybe<Scalars['ID']>;
  /** Only use if `templateId` is null. */
  text?: InputMaybe<Scalars['String']>;
  /** List of email recipients. */
  to: Array<Scalars['String']>;
};

export type EmailSendInput = {
  emails: Array<EmailSendEmailInput>;
};

export type EmailSendPayload = {
  __typename: 'EmailSendPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Email template that can be printed. */
export type EmailTemplate = {
  __typename: 'EmailTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** A handle to a file in Azure Blob Storage. */
export type File = {
  __typename: 'File';
  /** Mime-type of the file, e.g. "application/pdf". */
  contentType: Scalars['String'];
  /** When, if at all, the file will expire or did expire. */
  expireAt?: Maybe<Scalars['DateTime']>;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Data relating to the file, different depending on the file's status */
  info: FileInfo;
  /** Name of the file, if specified when creating an upload handle. */
  name?: Maybe<Scalars['String']>;
};

export enum FileDeletionReason {
  /** File was deleted automatically because it expired. */
  Expired = 'EXPIRED'
}

export type FileInfo = DeletedFile | PresentFile;

export enum FilePermissions {
  /** The default. Files can only be read. */
  Read = 'READ',
  /**
   * Files can be updated after creation - an upload url can always be
   * requested. However unlike the `accessUrl` field, doing so requires
   * the admin scope.
   */
  ReadUpdate = 'READ_UPDATE'
}

export type Fulfilment = {
  __typename: 'Fulfilment';
  id: Scalars['ID'];
};

export type FulfilmentLine = {
  __typename: 'FulfilmentLine';
  id: Scalars['ID'];
};

export type FulfilmentLinePack = {
  __typename: 'FulfilmentLinePack';
  id: Scalars['ID'];
};

/** Describes a physical transfer of a collection of physical products 'StockItem's from one location to another. */
export type InventoryTransfer = {
  __typename: 'InventoryTransfer';
  /** Creating user. */
  createdBy: User;
  /** The date of creation. */
  dateCreated: Scalars['Date'];
  /** The date of receival. */
  dateReceived?: Maybe<Scalars['Date']>;
  /** The destination of the stock. */
  destination: StockLocation;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Where stock is drawn from. */
  origin: StockLocation;
  /** Amount of packs being transferred. */
  packCount: Scalars['Int'];
  /** List of packs to be moved. */
  packs: Array<InventoryTransferPack>;
  /** Reference. */
  reference: Scalars['String'];
  /** Only for `InventoryTransferType` `TRUCK`, specifies trucking company. */
  shippingProvider?: Maybe<ShippingProvider>;
  /** The shipment status. */
  status: InventoryTransferStatus;
  /** The total weight in kilograms. */
  totalKilograms: Scalars['Float'];
  /** The M3. */
  totalMetersCubed: Scalars['Float'];
  /** Optional Treatment location for packs are being sent be treated  */
  treatmentPlant?: Maybe<TreatmentPlant>;
  /** The method of transportation. */
  type: InventoryTransferType;
};

export type InventoryTransferAddPackInput = {
  /** The `InventoryTransfer` this pack is being added to. */
  inventoryTransferId: Scalars['ID'];
  /** The description of the stock for manual treatment transfers. Only applicable if stock code = 'MANUAL'. */
  manualDescription?: InputMaybe<Scalars['String']>;
  /** The shipping Destination of the stock for manual treatment transfers. Only applicable if stock code = 'MANUAL'. */
  manualShippingDestination?: InputMaybe<Scalars['String']>;
  /** The treatment type of the stock for manual treatment transfers. Only applicable if stock code = 'MANUAL'. */
  manualTreatmentType?: InputMaybe<Scalars['String']>;
  /** M3 of pack. */
  metresCubed: Scalars['Float'];
  /** The unique ID of the pack. */
  packNumber: Scalars['String'];
  /** The quantity of the given `StockItem` contained in the pack being added. */
  quantity: Scalars['Float'];
  /** Stockcode ID of pack contents. */
  stockCode: Scalars['String'];
  /** In some cases the server may throw an error with a type of 'TREATMENT_WARNING' which the user must acknowledge prior to the pack being added. */
  treatmentWarningAcknowledged?: Scalars['Boolean'];
  /** The Unit Number if it was present in the pack's barcode */
  unitNumber?: InputMaybe<Scalars['String']>;
  /** Weight of pack. */
  weightKilograms: Scalars['Float'];
};

export type InventoryTransferAddPackPayload = {
  __typename: 'InventoryTransferAddPackPayload';
  /** The `InventoryTransfer` the pack was added to. */
  inventoryTransfer: InventoryTransfer;
  /** The `InventoryTransferPack` added. */
  pack: InventoryTransferPack;
  /** Any warning messages about the pack added. */
  warning?: Maybe<Scalars['String']>;
};

export type InventoryTransferCreateInput = {
  /** The location of `InventoryTransfer` destination. */
  destinationStockLocationId: Scalars['ID'];
  /** The method of transfer. */
  inventoryTransferType: InventoryTransferType;
  /** The location of `InventoryTransfer` origin. */
  originStockLocationId: Scalars['ID'];
  /** Only for `InventoryTransferType` = `TRUCK`. Specifies trucking company */
  shippingProviderId?: InputMaybe<Scalars['ID']>;
  /** Only for`InventoryTransferType` = `INSTANT`. Specifies treatment plant */
  treatmentPlantId?: InputMaybe<Scalars['ID']>;
};

export type InventoryTransferCreatePayload = {
  __typename: 'InventoryTransferCreatePayload';
  /** The `InventoryTransfer` that has been created. */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferDeleteInput = {
  /** The `InventoryTransfer` being soft deleted. */
  inventoryTransferId: Scalars['ID'];
};

export type InventoryTransferDeletePayload = {
  __typename: 'InventoryTransferDeletePayload';
  /** The `InventoryTransfer` that has been deleted. */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferGiveUpInput = {
  /** The `InventoryTransfer` to give up. */
  inventoryTransferId: Scalars['ID'];
};

export type InventoryTransferGiveUpPayload = {
  __typename: 'InventoryTransferGiveUpPayload';
  /** The `InventoryTransfer` that has just been given up. */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferInput = {
  __typename: 'InventoryTransferInput';
  destinationLocationId: Scalars['ID'];
  fromLocationId: Scalars['ID'];
  inventoryTransferId: Scalars['ID'];
  packs?: Maybe<Array<InventoryTransferPack>>;
  reference?: Maybe<Scalars['String']>;
};

/** A (typically) large number of items of a particular `StockItem` packed efficiently so as to facilitate transport. */
export type InventoryTransferPack = {
  __typename: 'InventoryTransferPack';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** The `InventoryTransfer` this pack belongs to. */
  inventoryTransfer: InventoryTransfer;
  /** The description given by the user for manual packs. */
  manualDescription?: Maybe<Scalars['String']>;
  /** The total M3 of the pack. */
  metresCubed: Scalars['Float'];
  /** The pack number. */
  packNumber: Scalars['String'];
  /** Total number of this `InventoryTransferPack`. */
  quantity: Scalars['Float'];
  /** The Shipping Destination as recorded for this pack. Separate to the stock item as it may change over time. */
  shippingDestination?: Maybe<Scalars['String']>;
  /** The `StockItem` contained within. Note optional as manual transfers may not have a regular stock item associated with them. */
  stockItem?: Maybe<StockItem>;
  /** The treatment type as recorded for this pack. Separate to the stock item as it may change over time. */
  treatmentType?: Maybe<Scalars['String']>;
  /** The Unit Number if it was present in the pack's barcode */
  unitNumber?: Maybe<Scalars['String']>;
  /** The details text derived from unit number, customer etc. */
  unitText?: Maybe<Scalars['String']>;
  /** The total weight of the pack in kilograms. */
  weightKilograms: Scalars['Float'];
};

export type InventoryTransferReceiveInput = {
  /** The `InventoryTransfer` being received. */
  inventoryTransferId: Scalars['ID'];
};

export type InventoryTransferReceivePayload = {
  __typename: 'InventoryTransferReceivePayload';
  /** The `InventoryTransfer` that was received. */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferRedirectInput = {
  /** The new destination location. If null, destination remains the same. */
  destinationStockLocationId?: InputMaybe<Scalars['ID']>;
  /** The `InventoryTransfer` to redirect. */
  inventoryTransferId: Scalars['ID'];
  /** The new origin location. If null, origin remains the same. */
  originStockLocationId?: InputMaybe<Scalars['ID']>;
};

export type InventoryTransferRedirectPayload = {
  __typename: 'InventoryTransferRedirectPayload';
  /** Error message, if any. */
  error?: Maybe<Scalars['String']>;
  /** The `InventoryTransfer` that has just been redirected. */
  inventoryTransfer?: Maybe<InventoryTransfer>;
};

export type InventoryTransferRemovePackInput = {
  /** The `InventoryTransferPack` being removed from the `InventoryTransfer`. */
  packId: Scalars['ID'];
};

export type InventoryTransferRemovePackPayload = {
  __typename: 'InventoryTransferRemovePackPayload';
  /** The `InventoryTransfer` the `InventoryTransferPack` was removed from */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferStartTransitInput = {
  /** The `InventoryTransfer` who's status is being set to `IN_TRANSIT`. */
  inventoryTransferId: Scalars['ID'];
  /** Reference for quick recognition of `InventoryTransfer` by office staff. */
  reference: Scalars['String'];
};

export type InventoryTransferStartTransitPayload = {
  __typename: 'InventoryTransferStartTransitPayload';
  /** The `InventoryTransfer` describing the packs that have just left the origin `StockLocation`. */
  inventoryTransfer: InventoryTransfer;
};

/** The possible statuses of an `InventoryTransfer` in relation to its desired location. */
export enum InventoryTransferStatus {
  /** Status once received at the desired location */
  Complete = 'COMPLETE',
  /** Status upon creation at the location of origin */
  Creating = 'CREATING',
  /** Status on soft deletion. */
  Deleted = 'DELETED',
  /** Status when a user gives up on a transfer */
  Error = 'ERROR',
  /**
   * Status when error occurs in EXO update when receiving a transfer. The user may
   * safely reattempt `inventoryTransferReceive` again on a shipment with this
   * status.
   */
  ErrorReceiveTransfer = 'ERROR_RECEIVE_TRANSFER',
  /**
   * Status when error occurs in EXO update when starting a transfer. The user may
   * try to perform the `inventoryTransferStartTransit` operation again if they
   * wish. If a transfer is in this state, stock levels are not in an inconsistent
   * state - nothing has happened in EXO due to the transfer.
   */
  ErrorStartTransit = 'ERROR_START_TRANSIT',
  /**
   * Status when error occurs in EXO update when undoing a transfer. The user may
   * safely reattempt `inventoryTransferUndo` again on a shipment with this status.
   */
  ErrorUndoTransit = 'ERROR_UNDO_TRANSIT',
  /** Status during transit, on the way to the desired location */
  InTransit = 'IN_TRANSIT'
}

/** The method of transport for an `InventoryTransfer`. */
export enum InventoryTransferType {
  /** `InventoryTransfer` being sent in a shipping `CONTAINER`. */
  Container = 'CONTAINER',
  /**
   * `InventoryTransfer` is to appear immediately in the destination location,
   * without need for receipting.
   */
  Instant = 'INSTANT',
  /** `InventoryTransfer` being sent by `TRUCK`. */
  Truck = 'TRUCK'
}

export type InventoryTransferUndoInput = {
  /** The `InventoryTransfer` being reverted from status `COMPLETE` back to `IN_TRANSIT`, in case of a user error. */
  inventoryTransferId: Scalars['ID'];
};

export type InventoryTransferUndoPayload = {
  __typename: 'InventoryTransferUndoPayload';
  /** The `InventoryTransfer` that was undone. */
  inventoryTransfer: InventoryTransfer;
};

export type Kiln = {
  __typename: 'Kiln';
  id: Scalars['ID'];
};

export type KilnCharge = {
  __typename: 'KilnCharge';
  chargeNumber: Scalars['String'];
  id: Scalars['ID'];
};

export type KilnChargeItem = {
  __typename: 'KilnChargeItem';
  id: Scalars['ID'];
};

export type LabelPrintCommandInput = {
  /**
   * The data to apply to the `DocumentTemplate` to generate a document.
   * If the `DocumentTemplate` provides default/example data, this is optional.
   */
  data: Array<LabelTemplateFieldData>;
  /** The number of copies to print. Must be nonnegative. */
  printQuantity: Scalars['Int'];
  /** The printer to print to. */
  printerId: Scalars['ID'];
  /** The `LabelTemplate` that should be used. For example 'Porta-FSC'. */
  templateId: Scalars['ID'];
};

export type LabelPrintInput = {
  /** List of commands for printing labels. */
  commands: Array<LabelPrintCommandInput>;
};

export type LabelPrintPayload = {
  __typename: 'LabelPrintPayload';
  message: Scalars['String'];
};

/** Describes a template that can be used to make labels of a particular kind. */
export type LabelTemplate = {
  __typename: 'LabelTemplate';
  /** Free text, way of loosely categorizing templates (group by category). */
  category?: Maybe<Scalars['String']>;
  /**
   * The printer that should be suggested automatically if printing labels with
   * this template.
   */
  defaultPrinter?: Maybe<Scalars['String']>;
  /** Fields for customizing generated labels. */
  fields: Array<LabelTemplateField>;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Name of the template. */
  name: Scalars['String'];
  /**
   * Preset document, if any, in XLSX format, as a base64 string. Can use `age`
   * in the same was as in the `presets` field.
   */
  presetDocument?: Maybe<Scalars['String']>;
  /** How presets should be handled, or whether allowed at all. */
  presetKind: LabelTemplatePresetKind;
  /**
   * Preset data, empty array if none. Can use `age` to get previous versions.
   *   - `age = 0`: current version
   *   - `age = 1`: version before current
   *   - etc.
   */
  presets: Array<Array<Scalars['String']>>;
};


/** Describes a template that can be used to make labels of a particular kind. */
export type LabelTemplatePresetDocumentArgs = {
  age?: InputMaybe<Scalars['Int']>;
};


/** Describes a template that can be used to make labels of a particular kind. */
export type LabelTemplatePresetsArgs = {
  age?: InputMaybe<Scalars['Int']>;
};

/** A point of customization for labels generated with a `LabelTemplate`. */
export type LabelTemplateField = {
  __typename: 'LabelTemplateField';
  /** For multiline fields, how many lines may typically be used. */
  defaultLineCount?: Maybe<Scalars['Int']>;
  /** Comment or example input to clarify the purpose of the field. */
  extraInfo: Scalars['String'];
  /** Null if the field is there for clarity in presets, and not for the label. */
  internalName?: Maybe<Scalars['String']>;
  /** Guide as to how the field should be entered. */
  kind: LabelTemplateFieldKind;
  /** User-friendly field name. */
  name: Scalars['String'];
  /** Whether this field should be provided by a preset, or entered manually. */
  preset: Scalars['Boolean'];
};

export type LabelTemplateFieldData = {
  field: Scalars['String'];
  value: Scalars['String'];
};

export enum LabelTemplateFieldKind {
  /** The field accepts multiline text. */
  Multiline = 'MULTILINE',
  /** The field should be given normal text. */
  Text = 'TEXT'
}

export enum LabelTemplatePresetKind {
  /** Can use presets, but with multi-select printing. */
  Multi = 'MULTI',
  /** Cannot use presets for this template */
  None = 'NONE',
  /** Can use presets, but with single-select printing. */
  Single = 'SINGLE'
}

export type LabelTemplateUploadPresetsError = LabelTemplateUploadPresetsInvalidDataError | LabelTemplateUploadPresetsNotFoundError;

export type LabelTemplateUploadPresetsInput = {
  /** The label template to upload presets to. */
  labelTemplateId: Scalars['ID'];
  /** The presets file, in XLSX format. */
  presets: Scalars['Upload'];
};

export type LabelTemplateUploadPresetsInvalidDataError = UserError & {
  __typename: 'LabelTemplateUploadPresetsInvalidDataError';
  message: Scalars['String'];
};

export type LabelTemplateUploadPresetsNotFoundError = UserError & {
  __typename: 'LabelTemplateUploadPresetsNotFoundError';
  message: Scalars['String'];
};

export type LabelTemplateUploadPresetsPayload = {
  __typename: 'LabelTemplateUploadPresetsPayload';
  userErrors: Array<LabelTemplateUploadPresetsError>;
};

/** Simple filtering object. */
export enum ListFilter {
  /** Exclude entities with this property. */
  Exclude = 'EXCLUDE',
  /** Include entities with this property. */
  Include = 'INCLUDE',
  /** Only include entities with this property. */
  Only = 'ONLY'
}

export type Location = {
  __typename: 'Location';
  id: Scalars['ID'];
};

export type Mutation = {
  __typename: 'Mutation';
  /** Create a counter with the given initial data. */
  counterCreate: Scalars['Boolean'];
  /**
   * Fetches the current counter information and returns it, and then updates the
   * counter as appropriate. If the counter does not exist, will error. You
   * can choose to handle this and create the counter on failure if desired.
   *
   * If `multiplier` is specified, will behave as if the counter was used that many
   * times.
   */
  counterUse: Counter;
  /** Generate documents using a `DocumentTemplate` and data. */
  documentGenerate: DocumentGeneratePayload;
  /** Print documents to a printer using a URL. */
  documentPrint: DocumentPrintPayload;
  /** Send email(s) using SendGrid. */
  emailSend: EmailSendPayload;
  /** Adds a pack to an `InventoryTransfer`. */
  inventoryTransferAddPack: InventoryTransferAddPackPayload;
  /** Create a new `InventoryTransfer` of status `CREATING` with no packs. */
  inventoryTransferCreate: InventoryTransferCreatePayload;
  /** Soft deletes an `InventoryTransfer`. Keeping the entity data but marking it as deleted */
  inventoryTransferDelete: InventoryTransferDeletePayload;
  /** Set the `InventoryTransfer`s status to `ERROR` if a transfer is created with stock items that are not available at the origin or destination location */
  inventoryTransferGiveUp: InventoryTransferGiveUpPayload;
  /** The `InventoryTransfer` that has just been received at the destination `StockLocation`. Status will change accordingly to `COMPLETE`. */
  inventoryTransferReceive: InventoryTransferReceivePayload;
  /**
   * Change the origin and destination locations for an `InventoryTransfer`. This
   * can be done for both in transit and completed transfers.
   */
  inventoryTransferRedirect: InventoryTransferRedirectPayload;
  /** Remove a pack from an `InventoryTransfer`. */
  inventoryTransferRemovePack: InventoryTransferRemovePackPayload;
  /** The `InventoryTransfer` that has just left the origin `StockLocation`. Status will change accordingly to `IN_TRANSIT`. */
  inventoryTransferStartTransit: InventoryTransferStartTransitPayload;
  /**
   * Moves a `InventoryTransfer` from `COMPLETE` back to `IN_TRANSIT`, and creates
   * stock movements which reverse those that came from receiving. Would be used if
   * someone messed up.
   */
  inventoryTransferUndo: InventoryTransferUndoPayload;
  /** Print a label using a `LabelTemplate` and data. */
  labelPrint: LabelPrintPayload;
  /** Upload a set of presets to a `LabelTemplate`. */
  labelTemplateUploadPresets: LabelTemplateUploadPresetsPayload;
  /**
   * Creates a `SalesOrder`, available via the financial system. Returns the ID of
   * the new `SalesOrder`.
   */
  salesOrderCreate: Scalars['ID'];
  /**
   * Supply lines on a `SalesOrder`. Will not allow you to supply more than the
   * unsupplied quantity. Quantities must be positive.
   */
  salesOrderSupply: Scalars['Boolean'];
  /** Replace the internal reference of a `SalesOrder` with the given value. */
  salesOrderUpdateInternalReference: Scalars['Boolean'];
  /**
   * Replace the picked by `User` of a `SalesOrder` with the given value. If the
   * given `User` isn't able to be assigned to this field, will clear the field
   * instead.
   */
  salesOrderUpdatePickedBy: Scalars['Boolean'];
  /** Sets the shipping provider for a `SalesOrder`'s most recent fulfilment. */
  salesOrderUpdateShippingProvider: Scalars['Boolean'];
  /** Adjust out packs at a location, 'consuming' the packs. */
  stockConsume: StockConsumePayload;
  /** Create a (Universal Print) pack label and perform related operations. */
  stockCreate: StockCreatePayload;
  /** Create a product pack label for a single product, and adjust the product in. */
  stockCreateSingle: StockCreateSinglePayload;
  /** Attempt to undo the `stockCreateSingle` operation. */
  stockCreateSingleUndo: StockCreateSingleUndoPayload;
  /** Attempt to undo the `stockCreate` operation, or redo after an undo. */
  stockCreateUndo: StockCreateUndoPayload;
  /**
   * Get one or more handles, which can be used to update existing files for approximately
   * 60 minutes. For each element in the input array, one `UploadHandle` is
   * returned. Requires the `Documents.Admin` scope, and the files must be
   * configured with update permissions.
   */
  updateHandlesGet: UpdateHandlesGetPayload;
  /**
   * Get one or more handles, which can be used to upload files for approximately
   * 60 minutes. For each element in the input array, one `UploadHandle` is
   * returned.
   */
  uploadHandlesGet: UploadHandlesGetPayload;
};


export type MutationCounterCreateArgs = {
  input: CounterCreateInput;
};


export type MutationCounterUseArgs = {
  id: Scalars['ID'];
  multiplier?: InputMaybe<Scalars['Int']>;
};


export type MutationDocumentGenerateArgs = {
  input: DocumentGenerateInput;
};


export type MutationDocumentPrintArgs = {
  input: DocumentPrintInput;
};


export type MutationEmailSendArgs = {
  input: EmailSendInput;
};


export type MutationInventoryTransferAddPackArgs = {
  input: InventoryTransferAddPackInput;
};


export type MutationInventoryTransferCreateArgs = {
  input: InventoryTransferCreateInput;
};


export type MutationInventoryTransferDeleteArgs = {
  input: InventoryTransferDeleteInput;
};


export type MutationInventoryTransferGiveUpArgs = {
  input: InventoryTransferGiveUpInput;
};


export type MutationInventoryTransferReceiveArgs = {
  input: InventoryTransferReceiveInput;
};


export type MutationInventoryTransferRedirectArgs = {
  input: InventoryTransferRedirectInput;
};


export type MutationInventoryTransferRemovePackArgs = {
  input: InventoryTransferRemovePackInput;
};


export type MutationInventoryTransferStartTransitArgs = {
  input: InventoryTransferStartTransitInput;
};


export type MutationInventoryTransferUndoArgs = {
  input: InventoryTransferUndoInput;
};


export type MutationLabelPrintArgs = {
  input: LabelPrintInput;
};


export type MutationLabelTemplateUploadPresetsArgs = {
  input: LabelTemplateUploadPresetsInput;
};


export type MutationSalesOrderCreateArgs = {
  input: SalesOrderCreateInput;
};


export type MutationSalesOrderSupplyArgs = {
  input: SalesOrderSupplyInput;
};


export type MutationSalesOrderUpdateInternalReferenceArgs = {
  input: SalesOrderUpdateInternalReferenceInput;
};


export type MutationSalesOrderUpdatePickedByArgs = {
  input: SalesOrderUpdatePickedByInput;
};


export type MutationSalesOrderUpdateShippingProviderArgs = {
  input: SalesOrderUpdateShippingProviderInput;
};


export type MutationStockConsumeArgs = {
  input: StockConsumeInput;
};


export type MutationStockCreateArgs = {
  input: StockCreateInput;
};


export type MutationStockCreateSingleArgs = {
  input: StockCreateSingleInput;
};


export type MutationStockCreateSingleUndoArgs = {
  input: StockCreateSingleUndoInput;
};


export type MutationStockCreateUndoArgs = {
  input: StockCreateUndoInput;
};


export type MutationUpdateHandlesGetArgs = {
  input: UpdateHandlesGetInput;
};


export type MutationUploadHandlesGetArgs = {
  input: UploadHandlesGetInput;
};

export type PackLog = {
  __typename: 'PackLog';
  consumed: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  id: Scalars['ID'];
  lastUpdated: Scalars['DateTime'];
  packNumber: Scalars['String'];
  printer: Scalars['String'];
  /** Quantity of the pack in the stock item's default unit. */
  quantity: Scalars['Float'];
  stockItem: StockItem;
  /**
   * For variable length packs, describes the composition of the pack.
   * If the pack is not of variable length products (or those configured as such),
   * or the pack was created prior to this feature being added, then this will be
   * an empty array.
   */
  tally: Array<Tally>;
  unitNumber?: Maybe<Scalars['String']>;
  unitNumberCategory?: Maybe<Scalars['String']>;
  workstation: PackWorkstation;
};

/** A machine/location at which product can be produced and entered into EXO. */
export type PackWorkstation = {
  __typename: 'PackWorkstation';
  batchPrintingEnabled: Scalars['Boolean'];
  defaultStockLocation: StockLocation;
  id: Scalars['ID'];
  name: Scalars['String'];
  printer: Scalars['String'];
  stickerPrinter: Scalars['String'];
  stockAdjustmentsDisabled: Scalars['Boolean'];
  /** Link to the corresponding RunType in TimberSmart */
  timberSmartRunTypeId?: Maybe<Scalars['String']>;
  /** Categorizes the `PackWorkstation`. */
  type: PackWorkstationType;
};

/** The kind of pack workstation. */
export enum PackWorkstationType {
  /** Added value - i.e. prebuilt/crafted goods. */
  AddedValue = 'ADDED_VALUE',
  /** Optimizer machine. */
  Opti = 'OPTI',
  /** Producing product using a plan. */
  Planned = 'PLANNED',
  /** Other machine. */
  Standard = 'STANDARD'
}

export type PickGroup = {
  __typename: 'PickGroup';
  id: Scalars['ID'];
};

/** Information about a file that exists (though might not yet be uploaded). */
export type PresentFile = {
  __typename: 'PresentFile';
  /**
   * The base URL, but with SAS query parameters providing read access to the
   * file for approximately 60 minutes.
   */
  accessUrl: Scalars['String'];
  /** URI for the location of the blob. */
  baseUrl: Scalars['String'];
  /** Permissions configured for the file. */
  permissions: FilePermissions;
};

/** A device (physical or not) that documents can be printed to. */
export type Printer = {
  __typename: 'Printer';
  /** Unique ID for the printer */
  id: Scalars['ID'];
};

export type Query = {
  __typename: 'Query';
  _null?: Maybe<Scalars['String']>;
  /** Fetch a counter without modifying. */
  counter?: Maybe<Counter>;
  /** Fetch all counters without modifying. */
  counters: Array<Counter>;
  /** Get all `CrmSalesOrder`s created after the given date. */
  crmSalesOrders: Array<CrmSalesOrder>;
  /** List of email templates available for printing. */
  emailTemplates: Array<EmailTemplate>;
  /** Get a `File` by id */
  file?: Maybe<File>;
  /** Get `File`s by id */
  files: Array<File>;
  /** Get the `InventoryTransfer` with the given ID, or null if does not exist. */
  inventoryTransfer?: Maybe<InventoryTransfer>;
  /** List `InventoryTransfer`s with filtering and other options. */
  inventoryTransfers: Array<InventoryTransfer>;
  /** Get a `LabelTemplate` by ID if it exists, or null if none found. */
  labelTemplate?: Maybe<LabelTemplate>;
  /** Returns a list of all/many `LabelTemplate`s. */
  labelTemplates: Array<LabelTemplate>;
  /** Gets the current user as recognized by the authentication system. */
  me?: Maybe<User>;
  /** Get a pack log by it's pack number, returning null if not present. */
  packLogByPackNumber?: Maybe<PackLog>;
  /** Fetch pack logs, in order of most recent to least. */
  packLogs: Array<PackLog>;
  /**
   * Returns the `PackWorkstation` corresponding to the given ID, or null if not
   * found.
   */
  packWorkstation?: Maybe<PackWorkstation>;
  /**
   * Returns the `PackWorkstation` corresponding to the given ID, or null if not
   * found.
   */
  packWorkstationByName?: Maybe<PackWorkstation>;
  /** Returns a list of all/many `PackWorkstation`s. */
  packWorkstations: Array<PackWorkstation>;
  /** Get a printer by ID if it exists. If a printer is returned, it may be printed to. */
  printer?: Maybe<Printer>;
  /** The list of printers that can be printed to */
  printers: Array<Printer>;
  /** Get all `SalesBranch`es. */
  salesBranches: Array<SalesBranch>;
  /** Returns the `SalesOrder` corresponding to the given ID, or null if not found. */
  salesOrder?: Maybe<SalesOrder>;
  /**
   * Returns the `SalesOrderLine` corresponding to the given ID, or null if not
   * found.
   */
  salesOrderLine?: Maybe<SalesOrderLine>;
  /** Returns a set of `SalesOrderLine`s. */
  salesOrderLines: Array<SalesOrderLine>;
  /** Returns a set of `SalesOrder`s. */
  salesOrders: Array<SalesOrder>;
  /** Get all `ShippingProvider`s. */
  shippingProviders: Array<ShippingProvider>;
  /**
   * Returns the `StockItem` corresponding to the given stock code, or null if not
   * found.
   */
  stockItem?: Maybe<StockItem>;
  /**
   * Determines the best price possible (price that should be quoted) for a
   * `StockItem` given other information. Takes a list of inputs and returns
   * outputs in the same order.
   */
  stockItemCurrentBestPrices: Array<StockItemBestPriceResult>;
  /** Returns a set of `StockItem`s. */
  stockItems: Array<StockItem>;
  /** Returns a set of `StockItem`s, 'fuzzy searched' on the description field. */
  stockItemsFuzzySearch: Array<StockItem>;
  /** Get a list of all `StockLocation`s. */
  stockLocations: Array<StockLocation>;
  treatmentHistory: TreatmentHistorySearchResult;
  treatmentPlants: Array<TreatmentPlant>;
  /** Get a list of all `UnitsOfMeasure */
  unitsOfMeasure: Array<UnitOfMeasure>;
  /** Get's the `User` with the given ID, or null if not found. */
  user?: Maybe<User>;
  /**
   * Fetches a `User` by their email.
   * Returns null if no such user exists, otherwise the `User`.
   */
  userByEmail?: Maybe<User>;
  /**
   * Attempts to log in using the given PIN.
   * Returns null if no such user exists, otherwise the `User`.
   */
  userByPin?: Maybe<User>;
  /** Get's the `User`s with the given IDs or all `User`s. */
  users: Array<User>;
  /** Returns the `Workstation` corresponding to the given ID, or null if not found. */
  workstation?: Maybe<Workstation>;
  /** Returns a list of all `Workstations`. */
  workstations: Array<Workstation>;
};


export type QueryCounterArgs = {
  id: Scalars['ID'];
};


export type QueryCrmSalesOrdersArgs = {
  createdAfter: Scalars['Date'];
};


export type QueryFileArgs = {
  id: Scalars['ID'];
};


export type QueryFilesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryInventoryTransferArgs = {
  id: Scalars['ID'];
};


export type QueryInventoryTransfersArgs = {
  count?: InputMaybe<Scalars['Int']>;
  destinationLocationId?: InputMaybe<Scalars['ID']>;
  fromId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<InventoryTransferStatus>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueryLabelTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryLabelTemplatesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryPackLogByPackNumberArgs = {
  packNumber: Scalars['String'];
};


export type QueryPackLogsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  packWorkstationId?: InputMaybe<Scalars['ID']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  stockCode?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryPackWorkstationArgs = {
  id: Scalars['ID'];
};


export type QueryPackWorkstationByNameArgs = {
  name: Scalars['String'];
};


export type QueryPackWorkstationsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryPrinterArgs = {
  id: Scalars['ID'];
};


export type QuerySalesOrderArgs = {
  id: Scalars['ID'];
};


export type QuerySalesOrderLineArgs = {
  id: Scalars['ID'];
};


export type QuerySalesOrderLinesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerySalesOrdersArgs = {
  backOrderFilter?: InputMaybe<ListFilter>;
  branchId?: InputMaybe<Scalars['ID']>;
  branchIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  createdAfter?: InputMaybe<Scalars['Date']>;
  customerId?: InputMaybe<Scalars['ID']>;
  customerInactive?: InputMaybe<ListFilter>;
  customerOnStopCredit?: InputMaybe<ListFilter>;
  customerReferences?: InputMaybe<Array<Scalars['String']>>;
  descending?: InputMaybe<Scalars['Boolean']>;
  fromId?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  onHoldFilter?: InputMaybe<ListFilter>;
  pickedFilter?: InputMaybe<ListFilter>;
  statuses?: InputMaybe<Array<SalesOrderStatus>>;
  stockLocationId?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
  withLinesInCategory?: InputMaybe<SalesOrderLinePickCategory>;
};


export type QueryShippingProvidersArgs = {
  forFulfilment?: InputMaybe<Scalars['Boolean']>;
};


export type QueryStockItemArgs = {
  stockCode: Scalars['ID'];
};


export type QueryStockItemCurrentBestPricesArgs = {
  input: Array<StockItemBestPriceInput>;
};


export type QueryStockItemsArgs = {
  barcodes?: InputMaybe<Array<Scalars['String']>>;
  skip?: InputMaybe<Scalars['Int']>;
  stockCodes?: InputMaybe<Array<Scalars['ID']>>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryStockItemsFuzzySearchArgs = {
  input: StockItemsFuzzySearchInput;
};


export type QueryTreatmentHistoryArgs = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  packNo?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  stockCode?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  treatmentPlantId?: InputMaybe<Scalars['ID']>;
  treatmentType?: InputMaybe<Scalars['String']>;
  unitNo?: InputMaybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryUserByPinArgs = {
  pin: Scalars['String'];
};


export type QueryUsersArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryWorkstationArgs = {
  id: Scalars['ID'];
};

export type Run = {
  __typename: 'Run';
  id: Scalars['ID'];
};

export type RunInfeedPack = {
  __typename: 'RunInfeedPack';
  packId: Scalars['ID'];
};

export type RunOutfeedPack = {
  __typename: 'RunOutfeedPack';
  packId: Scalars['ID'];
};

/** A segment of the business' sales. */
export type SalesBranch = {
  __typename: 'SalesBranch';
  id: Scalars['ID'];
  /** Readable name for the `Branch`. */
  name: Scalars['String'];
};

/** An order from a customer/`Debtor` requesting goods. */
export type SalesOrder = {
  __typename: 'SalesOrder';
  /**
   * Contact email address to use for the order.
   * @deprecated Use `contactEmails`.
   */
  contactEmail?: Maybe<Scalars['String']>;
  /** Contact email addresses to use for the order. */
  contactEmails: Array<ContactEmail>;
  /** The `Debtor` that made the order. */
  customer: Debtor;
  /**
   * Name of the customer. This defaults to the name of the debtor, but can be used
   * when the name of the debtor is not sufficient to name the intended recipient.
   * For example, orders using the ONLINE ORDERS debtor may use this field to
   * specify the buyer's first and last name.
   */
  customerName: Scalars['String'];
  /**
   * The phone number of the customer. Defaults to the debtor's phone number, but
   * can be different when that phone number is not desirable. For example, with
   * an online order where the debtor wouldn't have a good phone number.
   */
  customerPhoneNumber?: Maybe<Scalars['String']>;
  /** Name/identifier assigned by the customer to the order. */
  customerReference: Scalars['String'];
  /**
   * When the `SalesOrder` was created in EXO. In the uncommon situtation that this
   * is null, it uses the `dateOrdered` field instead.
   */
  dateCreated: Scalars['Date'];
  /** The last time the order was modified. */
  dateModified: Scalars['Date'];
  /** The day the order was made. */
  dateOrdered: Scalars['Date'];
  /** Address purchased goods should be delivered to. */
  deliverTo: Array<Scalars['String']>;
  /** Whether the order contains back-ordered items. */
  hasBackOrders: Scalars['Boolean'];
  /** Whether the order contains unsupplied items. */
  hasUnsupplied: Scalars['Boolean'];
  /** The ID of the `SalesOrder`, directly from the financial system. */
  id: Scalars['ID'];
  /** Extra notes/instructions on the order. */
  instructions?: Maybe<Scalars['String']>;
  /** Internal information assigned to the order for whatever purpose. */
  internalReference?: Maybe<Scalars['String']>;
  /** Whether the order is considered a back order. */
  isBackOrder: Scalars['Boolean'];
  /** Lines associated with the `SalesOrder`. */
  lines: Array<SalesOrderLine>;
  /** Extra notes on the `SalesOrder`. May be an empty string if there are none. */
  notes: Scalars['String'];
  /** Whether the order has been put on hold. */
  onHold: Scalars['Boolean'];
  /** The `SalesBranch` the order belongs to. */
  salesBranch: SalesBranch;
  /** The provider used/that should be used to ship the order. May be null. */
  shippingProvider?: Maybe<ShippingProvider>;
  /** Status - what phase of the order pipeline it is in. */
  status: SalesOrderStatus;
  /** Whether tax is included??? - seems to have no effect on money values. */
  taxInclusive: Scalars['Boolean'];
  /** Total tax on the order. */
  totalTax: Scalars['Float'];
  /** Total order value, including taxes. */
  totalWithTax: Scalars['Float'];
  /** Total order value, excluding taxes. */
  totalWithoutTax: Scalars['Float'];
};


/** An order from a customer/`Debtor` requesting goods. */
export type SalesOrderCustomerNameArgs = {
  titleCaseDebtor?: InputMaybe<Scalars['Boolean']>;
};


/** An order from a customer/`Debtor` requesting goods. */
export type SalesOrderLinesArgs = {
  categories?: InputMaybe<Array<SalesOrderLinePickCategory>>;
  hideSpecial?: InputMaybe<Scalars['Boolean']>;
  stockLocationId?: InputMaybe<Scalars['ID']>;
};

export type SalesOrderCreateInput = {
  /** Debtor to which the order should be delivered. */
  customerId: Scalars['ID'];
  /** The customer's name. */
  customerName?: InputMaybe<Scalars['String']>;
  /** The customer's phone number. */
  customerPhoneNumber?: InputMaybe<Scalars['String']>;
  /** Name the customer has given the order. */
  customerReference: Scalars['String'];
  /** Date on which the payment is due for the order. */
  datePaymentDue?: InputMaybe<Scalars['Date']>;
  /**
   * Stock location stock is withdrawn from by default to fulfil the `SalesOrder`.
   * Can be override on a per-line basis.
   */
  defaultStockLocationId?: InputMaybe<Scalars['ID']>;
  /** Address to which the order should be delivered. */
  deliveryAddress?: InputMaybe<Array<Scalars['String']>>;
  /** Extra notes/instructions on the order. */
  instructions?: InputMaybe<Scalars['String']>;
  /** The internal reference for the sales order. */
  internalReference?: InputMaybe<Scalars['String']>;
  /** Lines for products ordered. */
  lines: Array<SalesOrderLineInput>;
  /** Optional narrative on the order. */
  narrative?: InputMaybe<Scalars['String']>;
  /** Whether the order will be created as 'On Hold' or not. */
  onHold: Scalars['Boolean'];
  /** `SalesBranch` responsible for this order. */
  salesBranchId: Scalars['ID'];
  /** `ShippingProvider` to use when delivering goods for this order, if any. */
  shippingProviderId?: InputMaybe<Scalars['ID']>;
  /** Whether the prices specified are tax inclusive or not. */
  taxInclusive?: InputMaybe<Scalars['Boolean']>;
};

/** A request for a particular good as part of a `SalesOrder`. */
export type SalesOrderLine = {
  __typename: 'SalesOrderLine';
  /**
   * Description of the line. Often the same as the description of the line's
   * `StockItem`, but may be different.
   */
  description: Scalars['String'];
  /** The ID of the `SalesOrderLine`, directly from the financial system. */
  id: Scalars['ID'];
  /**
   * Whether the line is force closed. A force closed line cannot be supplied or
   * invoiced.
   */
  isForceClosed: Scalars['Boolean'];
  /** The quantity of good that is currently on back order. */
  quantityBackOrdered: Scalars['Float'];
  /** The quantity of good that has been invoiced. */
  quantityInvoiced: Scalars['Float'];
  /** The quantity of good that was ordered. */
  quantityOrdered: Scalars['Float'];
  /** The quantity of good that has been fulfilled/supplied so far. */
  quantitySupplied: Scalars['Float'];
  /** The quantity of good that has not yet been supplied. */
  quantityUnsupplied: Scalars['Float'];
  /** The `SalesOrder` the line belongs to. */
  salesOrder: SalesOrder;
  /** The `StockItem` associated with the line. */
  stockItem: StockItem;
  /** The `StockLocation` inventory should be pulled from when fulfilling. */
  stockLocation: StockLocation;
  /** Total price of the goods on the line. */
  totalPrice: Scalars['Float'];
  /** Price per unit of the goods represented on the line. */
  unitPrice: Scalars['Float'];
};

export type SalesOrderLineInput = {
  /** If null, will use the stock item's description */
  description?: InputMaybe<Scalars['String']>;
  /** The quantity ordered in the unit determined by the stock item. */
  quantityOrdered: Scalars['Float'];
  /** The `StockItem` this line is requesting. */
  stockCode: Scalars['ID'];
  /** Stock location stock is withdrawn from to fulfil the `SalesOrder`. */
  stockLocationId?: InputMaybe<Scalars['ID']>;
  /**
   * Price of the product in the `SalesOrder`. Thus total price is
   * `quantityOrdered * unitPrice`. If null, will use the default price from the
   * debtor and stock item.
   */
  unitPrice?: InputMaybe<Scalars['Float']>;
};

/** Non-exhaustive categorization of `SalesOrderLine`s. */
export enum SalesOrderLinePickCategory {
  /** The line is on backorder. */
  BackOrdered = 'BACK_ORDERED',
  /** The line is fully supplied. */
  FullySupplied = 'FULLY_SUPPLIED',
  /** The line can be picked and isn't on backorder. */
  Ready = 'READY'
}

/** Different possible states for a `SalesOrder`. */
export enum SalesOrderStatus {
  /** The order is complete. */
  FullyProcessed = 'FULLY_PROCESSED',
  /** Nothing on the order has been fulfilled. */
  NotProcessed = 'NOT_PROCESSED',
  /** The order is in a state that doesn't match any of the other categories. */
  Other = 'OTHER',
  /** The order has been fulfilled to some degree. */
  PartiallyProcessed = 'PARTIALLY_PROCESSED',
  /** The order is currently a quote. */
  Quote = 'QUOTE'
}

export type SalesOrderSupplyInput = {
  /**
   * Whether to automatically supply any special lines at the same time, such as
   * freight or discount lines.
   */
  autosupplySpecialLines: Scalars['Boolean'];
  /** Description of the lines be supplied. */
  lines: Array<SalesOrderSupplyLineInput>;
  /** The `SalesOrder` containing the lines that are to be supplied. */
  salesOrderId: Scalars['ID'];
};

export type SalesOrderSupplyLineInput = {
  /**
   * Whether or not to backorder the remaining unsupplied quantity. Note that the
   * `quantity` to supply can be zero, which has the effect of simply backordering
   * the line.
   */
  backorderRemaining: Scalars['Boolean'];
  /**
   * If given, the stock location to change the line to use after fulfilling. This
   * is good for when there is enough inventory somewhere else to fulfil the rest
   * of the line.
   */
  newLocationId?: InputMaybe<Scalars['ID']>;
  /** The quantity to supply, in the unit of the relevant `StockItem`. */
  quantity: Scalars['Float'];
  /** The `SalesOrderLine` to supply, in whole or in part. */
  salesOrderLineId: Scalars['ID'];
};

export type SalesOrderUpdateInternalReferenceInput = {
  /** The `SalesOrder` to update. */
  salesOrderId: Scalars['ID'];
  /**
   * The new value of the `internalReference` field. Can clear the field with null
   * if desired.
   */
  value?: InputMaybe<Scalars['String']>;
};

export type SalesOrderUpdatePickedByInput = {
  /** The `SalesOrder` to update. */
  salesOrderId: Scalars['ID'];
  /**
   * The `User` who just picked the order. Can clear the field with null if
   * desired.
   */
  userId?: InputMaybe<Scalars['ID']>;
};

export type SalesOrderUpdateShippingProviderInput = {
  /** The `SalesOrder` to update. */
  salesOrderId: Scalars['ID'];
  /**
   * The `User` who just picked the order. Can clear the field with null if
   * desired.
   */
  shippingProviderId?: InputMaybe<Scalars['ID']>;
};

/** A provider we can use to dispatch goods. */
export type ShippingProvider = {
  __typename: 'ShippingProvider';
  /**
   * Whether we want to consider the `ShippingProvider` for the standard fulfilment
   * process.
   */
  fulfilmentHide: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Name of the `ShippingProvider`. */
  name: Scalars['String'];
};

export type StockConsumeError = StockConsumeNotFoundError;

export type StockConsumeInput = {
  /** The pack numbers of the packs to consume. */
  packNumbers: Array<Scalars['String']>;
  /** Where the packs are located. */
  stockLocationId: Scalars['ID'];
};

export type StockConsumeNotFoundError = UserError & {
  __typename: 'StockConsumeNotFoundError';
  message: Scalars['String'];
};

export type StockConsumePayload = {
  __typename: 'StockConsumePayload';
  userErrors: Array<StockConsumeError>;
};

export type StockCreateError = StockCreateInvalidInputError | StockCreateNotFoundError;

export type StockCreateInput = {
  displayDimensions?: InputMaybe<Scalars['String']>;
  lengthSummary?: InputMaybe<Scalars['String']>;
  packWorkstationId: Scalars['ID'];
  printQuantity: Scalars['Int'];
  printer: Scalars['String'];
  quantity: Scalars['Float'];
  selectedUnitOfMeasure: StockCreateUnitOfMeasure;
  stockCode: Scalars['String'];
  tally?: InputMaybe<Array<TallyInput>>;
  unitNumber?: InputMaybe<Scalars['String']>;
  variableLengthPieces?: InputMaybe<Scalars['Float']>;
};

export type StockCreateInvalidInputError = UserError & {
  __typename: 'StockCreateInvalidInputError';
  message: Scalars['String'];
};

export type StockCreateNotFoundError = UserError & {
  __typename: 'StockCreateNotFoundError';
  message: Scalars['String'];
};

export type StockCreatePayload = {
  __typename: 'StockCreatePayload';
  /** The resulting `PackLog`s, null on failure. */
  packLogs?: Maybe<Array<PackLog>>;
  userErrors: Array<StockCreateError>;
};

export type StockCreateSingleError = StockCreateSingleNoBarcodeError | StockCreateSingleNotFoundError;

export type StockCreateSingleInput = {
  packWorkstationId: Scalars['ID'];
  printer: Scalars['String'];
  stockCode: Scalars['String'];
};

export type StockCreateSingleNoBarcodeError = UserError & {
  __typename: 'StockCreateSingleNoBarcodeError';
  message: Scalars['String'];
};

export type StockCreateSingleNotFoundError = UserError & {
  __typename: 'StockCreateSingleNotFoundError';
  message: Scalars['String'];
};

export type StockCreateSinglePayload = {
  __typename: 'StockCreateSinglePayload';
  userErrors: Array<StockCreateSingleError>;
};

export type StockCreateSingleUndoError = StockCreateSingleUndoNotFoundError;

export type StockCreateSingleUndoInput = {
  packWorkstationId: Scalars['ID'];
  stockCode: Scalars['String'];
};

export type StockCreateSingleUndoNotFoundError = UserError & {
  __typename: 'StockCreateSingleUndoNotFoundError';
  message: Scalars['String'];
};

export type StockCreateSingleUndoPayload = {
  __typename: 'StockCreateSingleUndoPayload';
  userErrors: Array<StockCreateSingleUndoError>;
};

export type StockCreateUndoError = StockCreateUndoNotFoundError;

export type StockCreateUndoInput = {
  /** The pack log to delete or restore. */
  packLogId: Scalars['ID'];
  /** Whether to soft-delete the pack log or restore it. */
  restore?: InputMaybe<Scalars['Boolean']>;
};

export type StockCreateUndoNotFoundError = UserError & {
  __typename: 'StockCreateUndoNotFoundError';
  message: Scalars['String'];
};

export type StockCreateUndoPayload = {
  __typename: 'StockCreateUndoPayload';
  /** The resulting `PackLog`, null on failure. */
  packLog?: Maybe<PackLog>;
  userErrors: Array<StockCreateUndoError>;
};

export enum StockCreateUnitOfMeasure {
  Lm = 'LM',
  Pce = 'PCE'
}

/** A category for a `StockItem`. */
export type StockGroup = {
  __typename: 'StockGroup';
  id: Scalars['ID'];
  /** Name of the group. */
  name: Scalars['String'];
};

/** An item, typically physical, that is sold for a given price to `Debtor`s. */
export type StockItem = {
  __typename: 'StockItem';
  /** Important information to consider when using the `StockItem`, if any. */
  alert?: Maybe<Scalars['String']>;
  /** Barcode/GTIN, or null if not available. */
  barcode?: Maybe<Scalars['String']>;
  /**
   * The value of the product's customer name field in the sales system. Indicates
   * the customer we would typically build this product up in stock for.
   */
  customerName: Scalars['String'];
  /** The last time the `StockItem` record was modified. May return null. */
  dateModified?: Maybe<Scalars['Date']>;
  /** Default location for dispatching the product, if any. */
  defaultStockLocation?: Maybe<StockLocation>;
  /**
   * Any other barcodes that should be recognized as this product, but should not
   * be used by us for the barcode was changed.
   *
   * Ideally we should never change barcodes and this should never be needed, but
   * unfortunately some customers order from us with these barcodes and we need to
   * understand that.
   */
  deprecatedBarcodes: Array<Scalars['String']>;
  /** Sales description. */
  description?: Maybe<Scalars['String']>;
  /**
   * Scaling factor when converting from the order quantity in Get Growing to order
   * quantity in the sales system. For example, if we are selling the product as a
   * 10 pack in Get Growing, this field should be set to 10, so ordering 3 in Get
   * Growing would become 30 in our system.
   */
  getGrowingQuantityMultiplier: Scalars['Float'];
  /** Whether the `StockItem` is actively in use/not retired. */
  isActive: Scalars['Boolean'];
  /** Whether or not the product is considered a 'Variable Length' product. */
  isVariableLengthProduct: Scalars['Boolean'];
  /** Whether or not the product is considered a 'WIP' product. */
  isWipProduct: Scalars['Boolean'];
  /**
   * Weight of the product in kilograms, or null if not available.
   *
   * A value of zero in EXO is assumed to be not specified and thus is null here.
   */
  kilograms?: Maybe<Scalars['Float']>;
  /** Length of the product in millimeters, or null if not available. */
  lengthMillimeters?: Maybe<Scalars['Float']>;
  /** Information about a `StockItem` at the given `StockLocation`. */
  locationInfo: StockItemLocationInfo;
  /** Information about a `StockItem` at each `StockLocation`. */
  locationInfos: Array<StockItemLocationInfo>;
  /**
   * Volume of the product in meters cubed, or null if not available.
   *
   * A value of zero in EXO is assumed to be not specified and thus is null here.
   */
  metresCubed?: Maybe<Scalars['Float']>;
  /**
   * The minimum quantity we are willing to sell of the product in an individual
   * order.
   */
  minimumOrderQuantity?: Maybe<Scalars['Float']>;
  /**
   * Name for the `StockItem`, typically shorter and less detailed than
   * description. If no name is available, will default back to `description`
   */
  name: Scalars['String'];
  /** Extra notes on/about the product, if there are any. */
  notes?: Maybe<Scalars['String']>;
  /**
   * The number/quantity of items in a typical pack/bundle. Could be floating-point
   * e.g. units of linear meters.
   */
  quantityMultiplier: Scalars['Float'];
  /**
   * The price at which the product is typically sold at for the given `Debtor`,
   * or null if not available.
   */
  sellPrice?: Maybe<Scalars['Float']>;
  /**
   * The price at which the product is typically sold at for debtors corresponding
   * to the given index (price group).
   */
  sellPriceAtIndex?: Maybe<Scalars['Float']>;
  /** The default shipping destination of a product. Typically NZ, ASIA, or AU */
  shippingDestination?: Maybe<Scalars['String']>;
  /**
   * The pack size of a typical pack of this product, if it is a product that
   * can be made into packs.
   */
  standardPackSize?: Maybe<Scalars['Int']>;
  /** ID of the stock item, often referred to as the 'stock code'. */
  stockCode: Scalars['ID'];
  /** Group the `StockItem` belongs to. */
  stockGroup: StockGroup;
  /** Thickness/height of the product in millimeters, or null if not available. */
  thicknessMillimeters?: Maybe<Scalars['Float']>;
  /** The default treatment type to be applied to the product */
  treatmentType?: Maybe<Scalars['String']>;
  /** Unit usually used to measure the quantity of this product. */
  unitOfMeasure: UnitOfMeasure;
  /** Width of the product in millimeters, or null if not available. */
  widthMillimeters?: Maybe<Scalars['Float']>;
  /** Whether or not the product is wrapped. */
  wrapped: Scalars['Boolean'];
};


/** An item, typically physical, that is sold for a given price to `Debtor`s. */
export type StockItemLocationInfoArgs = {
  stockLocationId: Scalars['ID'];
};


/** An item, typically physical, that is sold for a given price to `Debtor`s. */
export type StockItemSellPriceArgs = {
  debtorId: Scalars['ID'];
};


/** An item, typically physical, that is sold for a given price to `Debtor`s. */
export type StockItemSellPriceAtIndexArgs = {
  index: Scalars['Int'];
};

export type StockItemBestPriceInput = {
  /** The `Debtor` that would be purchasing. */
  debtorId: Scalars['ID'];
  /** The amount of the `StockItem` that would be ordered. */
  orderQuantity: Scalars['Float'];
  /** The `StockItem` that would be purchased. */
  stockCode: Scalars['ID'];
  /** Applies tax if required to the returned price. Defaults to GST exclusive. */
  taxType?: InputMaybe<TaxType>;
};

export type StockItemBestPriceResult = {
  __typename: 'StockItemBestPriceResult';
  /** The determined price, if it could. */
  price?: Maybe<Scalars['Float']>;
};

/** Information about a `StockItem` at a particular `StockLocation`. */
export type StockItemLocationInfo = {
  __typename: 'StockItemLocationInfo';
  /**
   * The bin code for the `StockItem` at the `StockLocation`.
   *
   * A bin code is a short string code used to help determine the physical location
   * (e.g. which shelf) of a `StockItem` within a larger location.
   *
   * Note that if the `StockItem` does not have any special bin code set for the
   * location, it will use the default, which is set on the `StockItem` itself.
   *
   * If no bin code is found whatsoever, null will be returned.
   */
  binCode?: Maybe<Scalars['String']>;
  /**
   * The current quantity of the `StockItem` available at the `StockLocation` in
   * the appropriate unit, at least as far as the financial system knows.
   */
  freeQuantity: Scalars['Float'];
  /**
   * The current quantity of the `StockItem` present at present in the
   * `StockLocation` in the appropriate unit, at least as far as the financial
   * system knows.
   */
  presentQuantity: Scalars['Float'];
  /** The `StockLocation` the information pertains to. */
  stockLocation: StockLocation;
};

export type StockItemsFuzzySearchInput = {
  description?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
};

/** A place from which goods can be stored/taken. */
export type StockLocation = {
  __typename: 'StockLocation';
  /** Short code for the location. */
  code: Scalars['String'];
  id: Scalars['ID'];
  /** Full name of the location. */
  name: Scalars['String'];
};

/**
 * A measurement of the amount of product.
 * It includes the number of pieces, and the length of these pieces.
 */
export type Tally = {
  __typename: 'Tally';
  length: Scalars['Float'];
  pieces: Scalars['Int'];
};

/** Corresponding input for `Tally` objects. */
export type TallyInput = {
  length: Scalars['Float'];
  pieces: Scalars['Int'];
};

export enum TaxType {
  GstExclusive = 'GST_EXCLUSIVE',
  GstInclusive = 'GST_INCLUSIVE'
}

export type TreatmentHistoryRecord = {
  __typename: 'TreatmentHistoryRecord';
  dateReceived?: Maybe<Scalars['DateTime']>;
  dateSent: Scalars['DateTime'];
  documentId?: Maybe<Scalars['String']>;
  fromTreatmentInventoryTransferId?: Maybe<Scalars['ID']>;
  fromTreatmentPackId?: Maybe<Scalars['ID']>;
  manualDescription?: Maybe<Scalars['String']>;
  metresCubed: Scalars['Float'];
  packNumber: Scalars['String'];
  quantity: Scalars['Float'];
  returnedToLocationId?: Maybe<Scalars['ID']>;
  sentFromLocationId: Scalars['ID'];
  shippingDestination?: Maybe<Scalars['String']>;
  stockCode?: Maybe<Scalars['String']>;
  stockItem?: Maybe<StockItem>;
  toTreatmentInventoryTransferId: Scalars['ID'];
  toTreatmentPackId: Scalars['ID'];
  treatmentPlant: TreatmentPlant;
  treatmentPlantId: Scalars['ID'];
  treatmentType?: Maybe<Scalars['String']>;
  unitNumber?: Maybe<Scalars['String']>;
  unitText?: Maybe<Scalars['String']>;
  weightKilograms: Scalars['Float'];
};

export type TreatmentHistorySearchResult = {
  __typename: 'TreatmentHistorySearchResult';
  records: Array<TreatmentHistoryRecord>;
  totalCount: Scalars['Int'];
};

/** Plants that packs can be sent to for treatment along with the treatment types available at that plant. */
export type TreatmentPlant = {
  __typename: 'TreatmentPlant';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  treatments: Array<Scalars['String']>;
};

/** Data for units for measuring physical objects/goods. */
export type UnitOfMeasure = {
  __typename: 'UnitOfMeasure';
  /** Name enum for different units. */
  name: UnitOfMeasureName;
  /** Lowercase symbol. */
  symbol: Scalars['String'];
  /** Uppercase symbol. */
  symbolCapitalized: Scalars['String'];
  /** English name. */
  word: Scalars['String'];
  /** English name, but pluralized. */
  wordPlural: Scalars['String'];
};

/** Units for measuring physical objects/goods. */
export enum UnitOfMeasureName {
  /** Board Foot (volume of a 1 by 1 foot board of 1 inch thickness. */
  Bdft = 'BDFT',
  /** Boxes/cartons. */
  Box = 'BOX',
  /** Linear Metres. */
  Lm = 'LM',
  /** Metres Cubed. */
  M3 = 'M3',
  /** Thousand Board Feet. (= 1000 x BDFT) */
  Mbf = 'MBF',
  /** Pieces. */
  Pce = 'PCE',
  /** Unit does not match one of those predefined by the benevolent developers. */
  Unknown = 'UNKNOWN'
}

export type UpdateHandlesGetError = UpdateHandlesGetNotFoundError | UpdateHandlesGetNotModifiableError;

export type UpdateHandlesGetHandleInput = {
  /** Content type for the blob, e.g. "application/pdf" */
  contentType: Scalars['String'];
  /** ID of the file to update */
  fileId: Scalars['ID'];
  /**
   * The new name for the file. Even when null, this will rename the old file. This
   * does not affect the name of the blob, just the name of the file according to
   * the API.
   */
  filename?: InputMaybe<Scalars['String']>;
};

export type UpdateHandlesGetInput = {
  handles: Array<UpdateHandlesGetHandleInput>;
};

export type UpdateHandlesGetNotFoundError = UserError & {
  __typename: 'UpdateHandlesGetNotFoundError';
  message: Scalars['String'];
};

export type UpdateHandlesGetNotModifiableError = UserError & {
  __typename: 'UpdateHandlesGetNotModifiableError';
  message: Scalars['String'];
};

export type UpdateHandlesGetPayload = {
  __typename: 'UpdateHandlesGetPayload';
  /** The handles to use for uploading files. */
  handles?: Maybe<Array<UploadHandle>>;
  userErrors: Array<UpdateHandlesGetError>;
};

/** A handle to a file in Azure Blob Storage. */
export type UploadHandle = {
  __typename: 'UploadHandle';
  /** The file record created. */
  file: File;
  /** URL which can be used to upload a file */
  uploadUrl: Scalars['String'];
};

export type UploadHandlesGetError = UploadHandlesGetInvalidInputError;

export type UploadHandlesGetHandleInput = {
  /** The file extension for the blob name, if any. */
  blobExtension?: InputMaybe<Scalars['String']>;
  /**
   * If not set, the blob will be stored internally as
   * "<some-uuid>.<blobExtension>", or if no blob file extension is present, just
   * "<some-uuid>".
   *
   * If set, the blob will be stored as "<prefix> <some-uuid>.<blobExtension>",
   * or if no blob file extension is present, just "<prefix> <some-uuid>"".
   */
  blobNamePrefix?: InputMaybe<Scalars['String']>;
  /**
   * The name of the container to place the blob in. This container does not
   * need to exist to be used - it will be created as required.
   */
  containerName: Scalars['String'];
  /** Content type for the blob, e.g. "application/pdf" */
  contentType: Scalars['String'];
  /**
   * If null, won't ever expire. If set, must be positive. Files will not be
   * deleted until after `expireDays` is met, although the deletion is not
   * immediate.
   */
  expireDays?: InputMaybe<Scalars['Int']>;
  /** Filename for the file according to the API, if any. */
  filename?: InputMaybe<Scalars['String']>;
  /**
   * Permissions that apply to the file. Only enforceable if file operations
   * happen through this api
   */
  permissions?: InputMaybe<FilePermissions>;
};

export type UploadHandlesGetInput = {
  handles: Array<UploadHandlesGetHandleInput>;
};

export type UploadHandlesGetInvalidInputError = UserError & {
  __typename: 'UploadHandlesGetInvalidInputError';
  message: Scalars['String'];
};

export type UploadHandlesGetPayload = {
  __typename: 'UploadHandlesGetPayload';
  /** The handles to use for uploading files. */
  handles?: Maybe<Array<UploadHandle>>;
  userErrors: Array<UploadHandlesGetError>;
};

/** A user of MLC applications. */
export type User = {
  __typename: 'User';
  /** The list of apps the user has access to. */
  apps: Array<AppAccess>;
  /**
   * Default `StockLocation` for the `User` when fulfilling `SalesOrder`s.
   * Some `User`s may not have a default - e.g. they don't work in dispatch.
   */
  defaultFulfillingStockLocation?: Maybe<StockLocation>;
  /**
   * Default `PickGroup` for the `User` when fulfilling `SalesOrder`s.
   * Some `User`s may not have a default - e.g. they don't work in dispatch.
   */
  defaultPickGroup?: Maybe<PickGroup>;
  /**
   * The user's email address, if any are on record. This allows the gateway and
   * services under it to identify a user logged in directly via the gateway, in a
   * way that from a service's perspective behaves identically to being logged in
   * via middleware-clients.
   */
  email?: Maybe<Scalars['String']>;
  /** The user's family (last) name. */
  familyName: Scalars['String'];
  /** The ID of the warehouse staff member in the the financial system. */
  financialWarehouseStaffMemberId?: Maybe<Scalars['ID']>;
  /** The set of branches the user may fulfil from. */
  fulfillingStockLocations: Array<StockLocation>;
  /** The user's given (first) name. */
  givenName: Scalars['String'];
  id: Scalars['ID'];
  /** The set of picking groups the user may fulfil from. */
  pickGroups: Array<PickGroup>;
};

export type UserError = {
  message: Scalars['String'];
};

export type WipSalesOrder = {
  __typename: 'WipSalesOrder';
  id: Scalars['ID'];
};

/** A machine/location at which product can be produced or manipulated. */
export type Workstation = {
  __typename: 'Workstation';
  id: Scalars['ID'];
  /** The label printer to use when a `User` at the machine wants to print a label. */
  labelPrinterId?: Maybe<Scalars['ID']>;
  /** Friendly name. */
  name: Scalars['String'];
  /** The corresponding pack workstation, if any. */
  packWorkstation?: Maybe<PackWorkstation>;
  /** Categorizes the `Workstation`. */
  type: WorkstationType;
};

/** Different types of `Workstation`. */
export enum WorkstationType {
  /** Makes packs disappear. */
  FactoryIn = 'FACTORY_IN',
  /** Preparing timber for drying. */
  Filleting = 'FILLETING',
  /** Any other type of `Workstation` that doesn't suit the other types. */
  Other = 'OTHER'
}

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename: 'Query', me?: { __typename: 'User', id: ID, givenName: string, familyName: string, defaultFulfillingStockLocation?: { __typename: 'StockLocation', id: ID } | null } | null };

export type LocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type LocationsQuery = { __typename: 'Query', stockLocations: Array<{ __typename: 'StockLocation', id: ID, name: string }> };

export type FreightCarriersQueryVariables = Exact<{ [key: string]: never; }>;


export type FreightCarriersQuery = { __typename: 'Query', shippingProviders: Array<{ __typename: 'ShippingProvider', id: ID, name: string }> };

export type ActiveUserTransfersQueryVariables = Exact<{
  status: InventoryTransferStatus;
  userId?: InputMaybe<Scalars['ID']>;
}>;


export type ActiveUserTransfersQuery = { __typename: 'Query', inventoryTransfers: Array<{ __typename: 'InventoryTransfer', id: ID, type: InventoryTransferType, origin: { __typename: 'StockLocation', id: ID }, packs: Array<{ __typename: 'InventoryTransferPack', id: ID, packNumber: string, metresCubed: number, weightKilograms: number, manualDescription?: string | null, unitText?: string | null, shippingDestination?: string | null, treatmentType?: string | null, stockItem?: { __typename: 'StockItem', shippingDestination?: string | null, treatmentType?: string | null, description?: string | null, stockCode: ID } | null }>, treatmentPlant?: { __typename: 'TreatmentPlant', displayName: string, treatments: Array<string> } | null }> };

export type TransferQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TransferQuery = { __typename: 'Query', inventoryTransfer?: { __typename: 'InventoryTransfer', id: ID, reference: string, dateCreated: string, dateReceived?: string | null, packCount: number, totalMetersCubed: number, totalKilograms: number, type: InventoryTransferType, status: InventoryTransferStatus, destination: { __typename: 'StockLocation', id: ID }, origin: { __typename: 'StockLocation', id: ID }, shippingProvider?: { __typename: 'ShippingProvider', id: ID } | null, packs: Array<{ __typename: 'InventoryTransferPack', id: ID, packNumber: string, quantity: number, metresCubed: number, weightKilograms: number, inventoryTransfer: { __typename: 'InventoryTransfer', id: ID }, stockItem?: { __typename: 'StockItem', stockCode: ID, description?: string | null, unitOfMeasure: { __typename: 'UnitOfMeasure', name: UnitOfMeasureName, symbolCapitalized: string } } | null }>, createdBy: { __typename: 'User', id: ID } } | null };

export type TransfersQueryVariables = Exact<{
  status: InventoryTransferStatus;
  destinationLocationId?: InputMaybe<Scalars['ID']>;
  fromId?: InputMaybe<Scalars['ID']>;
  count: Scalars['Int'];
}>;


export type TransfersQuery = { __typename: 'Query', inventoryTransfers: Array<{ __typename: 'InventoryTransfer', id: ID, reference: string, dateCreated: string, packCount: number, totalMetersCubed: number, totalKilograms: number, origin: { __typename: 'StockLocation', name: string, id: ID }, destination: { __typename: 'StockLocation', name: string, id: ID } }> };

export type TreatmentPlantsQueryVariables = Exact<{ [key: string]: never; }>;


export type TreatmentPlantsQuery = { __typename: 'Query', treatmentPlants: Array<{ __typename: 'TreatmentPlant', id: ID, displayName: string, treatments: Array<string> }> };

export type TreatmentHistoryQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  packNo?: InputMaybe<Scalars['String']>;
  unitNo?: InputMaybe<Scalars['String']>;
  stockCode?: InputMaybe<Scalars['String']>;
  treatmentPlantId?: InputMaybe<Scalars['ID']>;
  treatmentType?: InputMaybe<Scalars['String']>;
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
}>;


export type TreatmentHistoryQuery = { __typename: 'Query', treatmentHistory: { __typename: 'TreatmentHistorySearchResult', totalCount: number, records: Array<{ __typename: 'TreatmentHistoryRecord', dateReceived?: string | null, dateSent: string, documentId?: string | null, manualDescription?: string | null, metresCubed: number, packNumber: string, quantity: number, returnedToLocationId?: ID | null, sentFromLocationId: ID, shippingDestination?: string | null, stockCode?: string | null, toTreatmentInventoryTransferId: ID, treatmentType?: string | null, weightKilograms: number, unitText?: string | null, stockItem?: { __typename: 'StockItem', stockCode: ID, description?: string | null } | null, treatmentPlant: { __typename: 'TreatmentPlant', displayName: string, treatments: Array<string> } }> } };

export type GetTransferDocumentUrlQueryVariables = Exact<{
  documentId: Scalars['ID'];
}>;


export type GetTransferDocumentUrlQuery = { __typename: 'Query', file?: { __typename: 'File', info: { __typename: 'DeletedFile' } | { __typename: 'PresentFile', accessUrl: string } } | null };

export type CreateTransferMutationVariables = Exact<{
  input: InventoryTransferCreateInput;
}>;


export type CreateTransferMutation = { __typename: 'Mutation', result: { __typename: 'InventoryTransferCreatePayload', inventoryTransfer: { __typename: 'InventoryTransfer', id: ID } } };

export type ReceiveTransferMutationVariables = Exact<{
  input: InventoryTransferReceiveInput;
}>;


export type ReceiveTransferMutation = { __typename: 'Mutation', result: { __typename: 'InventoryTransferReceivePayload', inventoryTransfer: { __typename: 'InventoryTransfer', id: ID, status: InventoryTransferStatus } } };

export type UndoTransferReceiptMutationVariables = Exact<{
  input: InventoryTransferUndoInput;
}>;


export type UndoTransferReceiptMutation = { __typename: 'Mutation', result: { __typename: 'InventoryTransferUndoPayload', inventoryTransfer: { __typename: 'InventoryTransfer', id: ID, status: InventoryTransferStatus } } };

export type AddPackToTransferMutationVariables = Exact<{
  input: InventoryTransferAddPackInput;
}>;


export type AddPackToTransferMutation = { __typename: 'Mutation', result: { __typename: 'InventoryTransferAddPackPayload', warning?: string | null, inventoryTransfer: { __typename: 'InventoryTransfer', id: ID }, pack: { __typename: 'InventoryTransferPack', id: ID, packNumber: string, metresCubed: number, weightKilograms: number, unitText?: string | null, manualDescription?: string | null, shippingDestination?: string | null, treatmentType?: string | null, stockItem?: { __typename: 'StockItem', shippingDestination?: string | null, treatmentType?: string | null, description?: string | null, stockCode: ID } | null } } };

export type RemovePackFromTransferMutationVariables = Exact<{
  input: InventoryTransferRemovePackInput;
}>;


export type RemovePackFromTransferMutation = { __typename: 'Mutation', result: { __typename: 'InventoryTransferRemovePackPayload', inventoryTransfer: { __typename: 'InventoryTransfer', id: ID } } };

export type StartTransitMutationVariables = Exact<{
  input: InventoryTransferStartTransitInput;
}>;


export type StartTransitMutation = { __typename: 'Mutation', result: { __typename: 'InventoryTransferStartTransitPayload', inventoryTransfer: { __typename: 'InventoryTransfer', id: ID, status: InventoryTransferStatus } } };

export type DeleteTransferMutationVariables = Exact<{
  input: InventoryTransferDeleteInput;
}>;


export type DeleteTransferMutation = { __typename: 'Mutation', result: { __typename: 'InventoryTransferDeletePayload', inventoryTransfer: { __typename: 'InventoryTransfer', id: ID } } };

export type GiveUpTransferMutationVariables = Exact<{
  input: InventoryTransferGiveUpInput;
}>;


export type GiveUpTransferMutation = { __typename: 'Mutation', result: { __typename: 'InventoryTransferGiveUpPayload', inventoryTransfer: { __typename: 'InventoryTransfer', id: ID } } };

export type RedirectTransferMutationVariables = Exact<{
  input: InventoryTransferRedirectInput;
}>;


export type RedirectTransferMutation = { __typename: 'Mutation', result: { __typename: 'InventoryTransferRedirectPayload', error?: string | null, inventoryTransfer?: { __typename: 'InventoryTransfer', id: ID, origin: { __typename: 'StockLocation', id: ID }, destination: { __typename: 'StockLocation', id: ID } } | null } };

export type ConsumePacksMutationVariables = Exact<{
  input: StockConsumeInput;
}>;


export type ConsumePacksMutation = { __typename: 'Mutation', result: { __typename: 'StockConsumePayload', userErrors: Array<{ __typename: 'StockConsumeNotFoundError', message: string }> } };

export type FullShipmentFragment = { __typename: 'InventoryTransfer', id: ID, reference: string, dateCreated: string, dateReceived?: string | null, packCount: number, totalMetersCubed: number, totalKilograms: number, type: InventoryTransferType, status: InventoryTransferStatus, destination: { __typename: 'StockLocation', id: ID }, origin: { __typename: 'StockLocation', id: ID }, shippingProvider?: { __typename: 'ShippingProvider', id: ID } | null, packs: Array<{ __typename: 'InventoryTransferPack', id: ID, packNumber: string, quantity: number, metresCubed: number, weightKilograms: number, inventoryTransfer: { __typename: 'InventoryTransfer', id: ID }, stockItem?: { __typename: 'StockItem', stockCode: ID, description?: string | null, unitOfMeasure: { __typename: 'UnitOfMeasure', name: UnitOfMeasureName, symbolCapitalized: string } } | null }>, createdBy: { __typename: 'User', id: ID } };

export const FullShipmentFragmentDoc = gql`
    fragment FullShipment on InventoryTransfer {
  id
  reference
  dateCreated
  dateReceived
  destination {
    id
  }
  origin {
    id
  }
  shippingProvider {
    id
  }
  packCount
  totalMetersCubed
  totalKilograms
  packs {
    id
    inventoryTransfer {
      id
    }
    stockItem {
      stockCode
      description
      unitOfMeasure {
        name
        symbolCapitalized
      }
    }
    packNumber
    quantity
    metresCubed
    weightKilograms
  }
  type
  status
  createdBy {
    id
  }
}
    `;
export const MeDocument = gql`
    query Me {
  me {
    id
    givenName
    familyName
    defaultFulfillingStockLocation {
      id
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const LocationsDocument = gql`
    query Locations {
  stockLocations {
    id
    name
  }
}
    `;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationsQuery(baseOptions?: Apollo.QueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
      }
export function useLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
        }
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsQueryResult = Apollo.QueryResult<LocationsQuery, LocationsQueryVariables>;
export const FreightCarriersDocument = gql`
    query FreightCarriers {
  shippingProviders {
    id
    name
  }
}
    `;

/**
 * __useFreightCarriersQuery__
 *
 * To run a query within a React component, call `useFreightCarriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFreightCarriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFreightCarriersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFreightCarriersQuery(baseOptions?: Apollo.QueryHookOptions<FreightCarriersQuery, FreightCarriersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FreightCarriersQuery, FreightCarriersQueryVariables>(FreightCarriersDocument, options);
      }
export function useFreightCarriersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FreightCarriersQuery, FreightCarriersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FreightCarriersQuery, FreightCarriersQueryVariables>(FreightCarriersDocument, options);
        }
export type FreightCarriersQueryHookResult = ReturnType<typeof useFreightCarriersQuery>;
export type FreightCarriersLazyQueryHookResult = ReturnType<typeof useFreightCarriersLazyQuery>;
export type FreightCarriersQueryResult = Apollo.QueryResult<FreightCarriersQuery, FreightCarriersQueryVariables>;
export const ActiveUserTransfersDocument = gql`
    query ActiveUserTransfers($status: InventoryTransferStatus!, $userId: ID) {
  inventoryTransfers(status: $status, userId: $userId) {
    id
    type
    origin {
      id
    }
    packs {
      id
      packNumber
      metresCubed
      weightKilograms
      stockItem {
        shippingDestination
        treatmentType
        description
        stockCode
      }
      manualDescription
      unitText
      shippingDestination
      treatmentType
    }
    treatmentPlant {
      displayName
      treatments
    }
  }
}
    `;

/**
 * __useActiveUserTransfersQuery__
 *
 * To run a query within a React component, call `useActiveUserTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveUserTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveUserTransfersQuery({
 *   variables: {
 *      status: // value for 'status'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useActiveUserTransfersQuery(baseOptions: Apollo.QueryHookOptions<ActiveUserTransfersQuery, ActiveUserTransfersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveUserTransfersQuery, ActiveUserTransfersQueryVariables>(ActiveUserTransfersDocument, options);
      }
export function useActiveUserTransfersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveUserTransfersQuery, ActiveUserTransfersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveUserTransfersQuery, ActiveUserTransfersQueryVariables>(ActiveUserTransfersDocument, options);
        }
export type ActiveUserTransfersQueryHookResult = ReturnType<typeof useActiveUserTransfersQuery>;
export type ActiveUserTransfersLazyQueryHookResult = ReturnType<typeof useActiveUserTransfersLazyQuery>;
export type ActiveUserTransfersQueryResult = Apollo.QueryResult<ActiveUserTransfersQuery, ActiveUserTransfersQueryVariables>;
export const TransferDocument = gql`
    query Transfer($id: ID!) {
  inventoryTransfer(id: $id) {
    ...FullShipment
  }
}
    ${FullShipmentFragmentDoc}`;

/**
 * __useTransferQuery__
 *
 * To run a query within a React component, call `useTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTransferQuery(baseOptions: Apollo.QueryHookOptions<TransferQuery, TransferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransferQuery, TransferQueryVariables>(TransferDocument, options);
      }
export function useTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransferQuery, TransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransferQuery, TransferQueryVariables>(TransferDocument, options);
        }
export type TransferQueryHookResult = ReturnType<typeof useTransferQuery>;
export type TransferLazyQueryHookResult = ReturnType<typeof useTransferLazyQuery>;
export type TransferQueryResult = Apollo.QueryResult<TransferQuery, TransferQueryVariables>;
export const TransfersDocument = gql`
    query Transfers($status: InventoryTransferStatus!, $destinationLocationId: ID, $fromId: ID, $count: Int!) {
  inventoryTransfers(
    status: $status
    destinationLocationId: $destinationLocationId
    fromId: $fromId
    count: $count
  ) {
    id
    reference
    dateCreated
    packCount
    totalMetersCubed
    totalKilograms
    origin {
      name
      id
    }
    destination {
      name
      id
    }
  }
}
    `;

/**
 * __useTransfersQuery__
 *
 * To run a query within a React component, call `useTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransfersQuery({
 *   variables: {
 *      status: // value for 'status'
 *      destinationLocationId: // value for 'destinationLocationId'
 *      fromId: // value for 'fromId'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useTransfersQuery(baseOptions: Apollo.QueryHookOptions<TransfersQuery, TransfersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransfersQuery, TransfersQueryVariables>(TransfersDocument, options);
      }
export function useTransfersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransfersQuery, TransfersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransfersQuery, TransfersQueryVariables>(TransfersDocument, options);
        }
export type TransfersQueryHookResult = ReturnType<typeof useTransfersQuery>;
export type TransfersLazyQueryHookResult = ReturnType<typeof useTransfersLazyQuery>;
export type TransfersQueryResult = Apollo.QueryResult<TransfersQuery, TransfersQueryVariables>;
export const TreatmentPlantsDocument = gql`
    query TreatmentPlants {
  treatmentPlants {
    id
    displayName
    treatments
  }
}
    `;

/**
 * __useTreatmentPlantsQuery__
 *
 * To run a query within a React component, call `useTreatmentPlantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentPlantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentPlantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTreatmentPlantsQuery(baseOptions?: Apollo.QueryHookOptions<TreatmentPlantsQuery, TreatmentPlantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TreatmentPlantsQuery, TreatmentPlantsQueryVariables>(TreatmentPlantsDocument, options);
      }
export function useTreatmentPlantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TreatmentPlantsQuery, TreatmentPlantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TreatmentPlantsQuery, TreatmentPlantsQueryVariables>(TreatmentPlantsDocument, options);
        }
export type TreatmentPlantsQueryHookResult = ReturnType<typeof useTreatmentPlantsQuery>;
export type TreatmentPlantsLazyQueryHookResult = ReturnType<typeof useTreatmentPlantsLazyQuery>;
export type TreatmentPlantsQueryResult = Apollo.QueryResult<TreatmentPlantsQuery, TreatmentPlantsQueryVariables>;
export const TreatmentHistoryDocument = gql`
    query TreatmentHistory($skip: Int, $take: Int, $packNo: String, $unitNo: String, $stockCode: String, $treatmentPlantId: ID, $treatmentType: String, $dateFrom: DateTime, $dateTo: DateTime) {
  treatmentHistory(
    skip: $skip
    take: $take
    packNo: $packNo
    unitNo: $unitNo
    stockCode: $stockCode
    treatmentPlantId: $treatmentPlantId
    treatmentType: $treatmentType
    dateFrom: $dateFrom
    dateTo: $dateTo
  ) {
    records {
      dateReceived
      dateSent
      documentId
      manualDescription
      metresCubed
      packNumber
      quantity
      returnedToLocationId
      sentFromLocationId
      shippingDestination
      stockCode
      toTreatmentInventoryTransferId
      treatmentType
      weightKilograms
      stockItem {
        stockCode
        description
      }
      treatmentPlant {
        displayName
        treatments
      }
      unitText
    }
    totalCount
  }
}
    `;

/**
 * __useTreatmentHistoryQuery__
 *
 * To run a query within a React component, call `useTreatmentHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentHistoryQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      packNo: // value for 'packNo'
 *      unitNo: // value for 'unitNo'
 *      stockCode: // value for 'stockCode'
 *      treatmentPlantId: // value for 'treatmentPlantId'
 *      treatmentType: // value for 'treatmentType'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useTreatmentHistoryQuery(baseOptions?: Apollo.QueryHookOptions<TreatmentHistoryQuery, TreatmentHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TreatmentHistoryQuery, TreatmentHistoryQueryVariables>(TreatmentHistoryDocument, options);
      }
export function useTreatmentHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TreatmentHistoryQuery, TreatmentHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TreatmentHistoryQuery, TreatmentHistoryQueryVariables>(TreatmentHistoryDocument, options);
        }
export type TreatmentHistoryQueryHookResult = ReturnType<typeof useTreatmentHistoryQuery>;
export type TreatmentHistoryLazyQueryHookResult = ReturnType<typeof useTreatmentHistoryLazyQuery>;
export type TreatmentHistoryQueryResult = Apollo.QueryResult<TreatmentHistoryQuery, TreatmentHistoryQueryVariables>;
export const GetTransferDocumentUrlDocument = gql`
    query GetTransferDocumentUrl($documentId: ID!) {
  file(id: $documentId) {
    info {
      __typename
      ... on PresentFile {
        accessUrl
      }
    }
  }
}
    `;

/**
 * __useGetTransferDocumentUrlQuery__
 *
 * To run a query within a React component, call `useGetTransferDocumentUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferDocumentUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferDocumentUrlQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetTransferDocumentUrlQuery(baseOptions: Apollo.QueryHookOptions<GetTransferDocumentUrlQuery, GetTransferDocumentUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransferDocumentUrlQuery, GetTransferDocumentUrlQueryVariables>(GetTransferDocumentUrlDocument, options);
      }
export function useGetTransferDocumentUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransferDocumentUrlQuery, GetTransferDocumentUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransferDocumentUrlQuery, GetTransferDocumentUrlQueryVariables>(GetTransferDocumentUrlDocument, options);
        }
export type GetTransferDocumentUrlQueryHookResult = ReturnType<typeof useGetTransferDocumentUrlQuery>;
export type GetTransferDocumentUrlLazyQueryHookResult = ReturnType<typeof useGetTransferDocumentUrlLazyQuery>;
export type GetTransferDocumentUrlQueryResult = Apollo.QueryResult<GetTransferDocumentUrlQuery, GetTransferDocumentUrlQueryVariables>;
export const CreateTransferDocument = gql`
    mutation CreateTransfer($input: InventoryTransferCreateInput!) {
  result: inventoryTransferCreate(input: $input) {
    inventoryTransfer {
      id
    }
  }
}
    `;
export type CreateTransferMutationFn = Apollo.MutationFunction<CreateTransferMutation, CreateTransferMutationVariables>;

/**
 * __useCreateTransferMutation__
 *
 * To run a mutation, you first call `useCreateTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransferMutation, { data, loading, error }] = useCreateTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTransferMutation(baseOptions?: Apollo.MutationHookOptions<CreateTransferMutation, CreateTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTransferMutation, CreateTransferMutationVariables>(CreateTransferDocument, options);
      }
export type CreateTransferMutationHookResult = ReturnType<typeof useCreateTransferMutation>;
export type CreateTransferMutationResult = Apollo.MutationResult<CreateTransferMutation>;
export type CreateTransferMutationOptions = Apollo.BaseMutationOptions<CreateTransferMutation, CreateTransferMutationVariables>;
export const ReceiveTransferDocument = gql`
    mutation ReceiveTransfer($input: InventoryTransferReceiveInput!) {
  result: inventoryTransferReceive(input: $input) {
    inventoryTransfer {
      id
      status
    }
  }
}
    `;
export type ReceiveTransferMutationFn = Apollo.MutationFunction<ReceiveTransferMutation, ReceiveTransferMutationVariables>;

/**
 * __useReceiveTransferMutation__
 *
 * To run a mutation, you first call `useReceiveTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReceiveTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [receiveTransferMutation, { data, loading, error }] = useReceiveTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReceiveTransferMutation(baseOptions?: Apollo.MutationHookOptions<ReceiveTransferMutation, ReceiveTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReceiveTransferMutation, ReceiveTransferMutationVariables>(ReceiveTransferDocument, options);
      }
export type ReceiveTransferMutationHookResult = ReturnType<typeof useReceiveTransferMutation>;
export type ReceiveTransferMutationResult = Apollo.MutationResult<ReceiveTransferMutation>;
export type ReceiveTransferMutationOptions = Apollo.BaseMutationOptions<ReceiveTransferMutation, ReceiveTransferMutationVariables>;
export const UndoTransferReceiptDocument = gql`
    mutation UndoTransferReceipt($input: InventoryTransferUndoInput!) {
  result: inventoryTransferUndo(input: $input) {
    inventoryTransfer {
      id
      status
    }
  }
}
    `;
export type UndoTransferReceiptMutationFn = Apollo.MutationFunction<UndoTransferReceiptMutation, UndoTransferReceiptMutationVariables>;

/**
 * __useUndoTransferReceiptMutation__
 *
 * To run a mutation, you first call `useUndoTransferReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoTransferReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoTransferReceiptMutation, { data, loading, error }] = useUndoTransferReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUndoTransferReceiptMutation(baseOptions?: Apollo.MutationHookOptions<UndoTransferReceiptMutation, UndoTransferReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UndoTransferReceiptMutation, UndoTransferReceiptMutationVariables>(UndoTransferReceiptDocument, options);
      }
export type UndoTransferReceiptMutationHookResult = ReturnType<typeof useUndoTransferReceiptMutation>;
export type UndoTransferReceiptMutationResult = Apollo.MutationResult<UndoTransferReceiptMutation>;
export type UndoTransferReceiptMutationOptions = Apollo.BaseMutationOptions<UndoTransferReceiptMutation, UndoTransferReceiptMutationVariables>;
export const AddPackToTransferDocument = gql`
    mutation AddPackToTransfer($input: InventoryTransferAddPackInput!) {
  result: inventoryTransferAddPack(input: $input) {
    inventoryTransfer {
      id
    }
    pack {
      id
      packNumber
      metresCubed
      weightKilograms
      stockItem {
        shippingDestination
        treatmentType
        description
        stockCode
      }
      unitText
      manualDescription
      shippingDestination
      treatmentType
    }
    warning
  }
}
    `;
export type AddPackToTransferMutationFn = Apollo.MutationFunction<AddPackToTransferMutation, AddPackToTransferMutationVariables>;

/**
 * __useAddPackToTransferMutation__
 *
 * To run a mutation, you first call `useAddPackToTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPackToTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPackToTransferMutation, { data, loading, error }] = useAddPackToTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPackToTransferMutation(baseOptions?: Apollo.MutationHookOptions<AddPackToTransferMutation, AddPackToTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPackToTransferMutation, AddPackToTransferMutationVariables>(AddPackToTransferDocument, options);
      }
export type AddPackToTransferMutationHookResult = ReturnType<typeof useAddPackToTransferMutation>;
export type AddPackToTransferMutationResult = Apollo.MutationResult<AddPackToTransferMutation>;
export type AddPackToTransferMutationOptions = Apollo.BaseMutationOptions<AddPackToTransferMutation, AddPackToTransferMutationVariables>;
export const RemovePackFromTransferDocument = gql`
    mutation RemovePackFromTransfer($input: InventoryTransferRemovePackInput!) {
  result: inventoryTransferRemovePack(input: $input) {
    inventoryTransfer {
      id
    }
  }
}
    `;
export type RemovePackFromTransferMutationFn = Apollo.MutationFunction<RemovePackFromTransferMutation, RemovePackFromTransferMutationVariables>;

/**
 * __useRemovePackFromTransferMutation__
 *
 * To run a mutation, you first call `useRemovePackFromTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePackFromTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePackFromTransferMutation, { data, loading, error }] = useRemovePackFromTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePackFromTransferMutation(baseOptions?: Apollo.MutationHookOptions<RemovePackFromTransferMutation, RemovePackFromTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePackFromTransferMutation, RemovePackFromTransferMutationVariables>(RemovePackFromTransferDocument, options);
      }
export type RemovePackFromTransferMutationHookResult = ReturnType<typeof useRemovePackFromTransferMutation>;
export type RemovePackFromTransferMutationResult = Apollo.MutationResult<RemovePackFromTransferMutation>;
export type RemovePackFromTransferMutationOptions = Apollo.BaseMutationOptions<RemovePackFromTransferMutation, RemovePackFromTransferMutationVariables>;
export const StartTransitDocument = gql`
    mutation StartTransit($input: InventoryTransferStartTransitInput!) {
  result: inventoryTransferStartTransit(input: $input) {
    inventoryTransfer {
      id
      status
    }
  }
}
    `;
export type StartTransitMutationFn = Apollo.MutationFunction<StartTransitMutation, StartTransitMutationVariables>;

/**
 * __useStartTransitMutation__
 *
 * To run a mutation, you first call `useStartTransitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTransitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTransitMutation, { data, loading, error }] = useStartTransitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartTransitMutation(baseOptions?: Apollo.MutationHookOptions<StartTransitMutation, StartTransitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartTransitMutation, StartTransitMutationVariables>(StartTransitDocument, options);
      }
export type StartTransitMutationHookResult = ReturnType<typeof useStartTransitMutation>;
export type StartTransitMutationResult = Apollo.MutationResult<StartTransitMutation>;
export type StartTransitMutationOptions = Apollo.BaseMutationOptions<StartTransitMutation, StartTransitMutationVariables>;
export const DeleteTransferDocument = gql`
    mutation DeleteTransfer($input: InventoryTransferDeleteInput!) {
  result: inventoryTransferDelete(input: $input) {
    inventoryTransfer {
      id
    }
  }
}
    `;
export type DeleteTransferMutationFn = Apollo.MutationFunction<DeleteTransferMutation, DeleteTransferMutationVariables>;

/**
 * __useDeleteTransferMutation__
 *
 * To run a mutation, you first call `useDeleteTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTransferMutation, { data, loading, error }] = useDeleteTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTransferMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTransferMutation, DeleteTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTransferMutation, DeleteTransferMutationVariables>(DeleteTransferDocument, options);
      }
export type DeleteTransferMutationHookResult = ReturnType<typeof useDeleteTransferMutation>;
export type DeleteTransferMutationResult = Apollo.MutationResult<DeleteTransferMutation>;
export type DeleteTransferMutationOptions = Apollo.BaseMutationOptions<DeleteTransferMutation, DeleteTransferMutationVariables>;
export const GiveUpTransferDocument = gql`
    mutation GiveUpTransfer($input: InventoryTransferGiveUpInput!) {
  result: inventoryTransferGiveUp(input: $input) {
    inventoryTransfer {
      id
    }
  }
}
    `;
export type GiveUpTransferMutationFn = Apollo.MutationFunction<GiveUpTransferMutation, GiveUpTransferMutationVariables>;

/**
 * __useGiveUpTransferMutation__
 *
 * To run a mutation, you first call `useGiveUpTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiveUpTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giveUpTransferMutation, { data, loading, error }] = useGiveUpTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGiveUpTransferMutation(baseOptions?: Apollo.MutationHookOptions<GiveUpTransferMutation, GiveUpTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GiveUpTransferMutation, GiveUpTransferMutationVariables>(GiveUpTransferDocument, options);
      }
export type GiveUpTransferMutationHookResult = ReturnType<typeof useGiveUpTransferMutation>;
export type GiveUpTransferMutationResult = Apollo.MutationResult<GiveUpTransferMutation>;
export type GiveUpTransferMutationOptions = Apollo.BaseMutationOptions<GiveUpTransferMutation, GiveUpTransferMutationVariables>;
export const RedirectTransferDocument = gql`
    mutation RedirectTransfer($input: InventoryTransferRedirectInput!) {
  result: inventoryTransferRedirect(input: $input) {
    error
    inventoryTransfer {
      id
      origin {
        id
      }
      destination {
        id
      }
    }
  }
}
    `;
export type RedirectTransferMutationFn = Apollo.MutationFunction<RedirectTransferMutation, RedirectTransferMutationVariables>;

/**
 * __useRedirectTransferMutation__
 *
 * To run a mutation, you first call `useRedirectTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedirectTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redirectTransferMutation, { data, loading, error }] = useRedirectTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRedirectTransferMutation(baseOptions?: Apollo.MutationHookOptions<RedirectTransferMutation, RedirectTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedirectTransferMutation, RedirectTransferMutationVariables>(RedirectTransferDocument, options);
      }
export type RedirectTransferMutationHookResult = ReturnType<typeof useRedirectTransferMutation>;
export type RedirectTransferMutationResult = Apollo.MutationResult<RedirectTransferMutation>;
export type RedirectTransferMutationOptions = Apollo.BaseMutationOptions<RedirectTransferMutation, RedirectTransferMutationVariables>;
export const ConsumePacksDocument = gql`
    mutation ConsumePacks($input: StockConsumeInput!) {
  result: stockConsume(input: $input) {
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type ConsumePacksMutationFn = Apollo.MutationFunction<ConsumePacksMutation, ConsumePacksMutationVariables>;

/**
 * __useConsumePacksMutation__
 *
 * To run a mutation, you first call `useConsumePacksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumePacksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumePacksMutation, { data, loading, error }] = useConsumePacksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConsumePacksMutation(baseOptions?: Apollo.MutationHookOptions<ConsumePacksMutation, ConsumePacksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConsumePacksMutation, ConsumePacksMutationVariables>(ConsumePacksDocument, options);
      }
export type ConsumePacksMutationHookResult = ReturnType<typeof useConsumePacksMutation>;
export type ConsumePacksMutationResult = Apollo.MutationResult<ConsumePacksMutation>;
export type ConsumePacksMutationOptions = Apollo.BaseMutationOptions<ConsumePacksMutation, ConsumePacksMutationVariables>;