import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { AppState } from '../../state';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { useStartTransitMutation } from '../../generated/graphql';
import { Loading } from '../Loading';
import { BackButtonDialog } from '../BackButtonDialog';
import { useHistory } from 'react-router-dom';
import ButtonGroup from '@mui/material/ButtonGroup';
import Swal from 'sweetalert2';
import { ApolloError } from '@apollo/client';

export const PrintAndUpdate: React.FC<{ transferId: ID }> = ({
  transferId,
}) => {
  const reference = useSelector<AppState, string>((s) => s.transfers.reference);
  const [startTransferTransit] = useStartTransitMutation();
  const [errorRender, setErrorRender] = useState(false);
  const [successRender, setSuccessRender] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);

  useEffect(() => {
    startTransferTransit({
      variables: {
        input: {
          inventoryTransferId: transferId,
          reference: reference,
        },
      },
      refetchQueries: ['ActiveUserTransfers'],
      awaitRefetchQueries: true,
    })
      .then((r) => {
        const hasErrors = r.errors && r.errors?.length > 0;
        if (hasErrors) {
          // Hacky, but simple: we want to move in the direction of `userErrors`
          // on responses instead, but that's a big project
          Sentry.captureMessage(
            'Failed to start transit',
            Sentry.Severity.Error,
          );
        } else {
          setSuccessRender(true);
          return;
        }

        const businessError =
          r.errors && r.errors.find((x) => x?.extensions?.code === '218');
        const message =
          businessError?.message ??
          r.errors?.find((x) => x.locations == null)?.message ??
          'Something went wrong. Please contact IT.';

        if (message) {
          triggerError(message);
        }

        if (businessError) {
          backToStart();
        } else {
          setErrorRender(true);
          backToStart();
        }
      })
      .catch((error: ApolloError) => {
        Sentry.captureException(error);
        const errorCode = error.graphQLErrors.find((x) => x.locations == null)
          ?.extensions.code;

        const niceError =
          error.graphQLErrors.find((x) => x.locations == null)?.message ??
          'Something went wrong. Please contact IT.';
        triggerError(niceError);
        // If error code 218 ("This is Fine"), treat as success but warn the user
        // the page did not print
        if (errorCode === '218') {
          backToStart();
        } else {
          setErrorRender(true);
          backToStart();
        }
      });
    // Is supposed to have a one-shot immediate effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();
  const backToStart = () => {
    history.push('/mobile');
  };

  const renderButtons = () => {
    return (
      <>
        <div className="Spacing" />
        <ButtonGroup className="Buttons">
          <Button
            color="secondary"
            variant="contained"
            disableElevation
            onClick={() => setOpenDialogue(true)}
          >
            Cancel
          </Button>
        </ButtonGroup>
        <BackButtonDialog
          inventoryTransferId={transferId}
          open={openDialogue === true}
          close={() => setOpenDialogue(false)}
          error={true}
        />
      </>
    );
  };

  const triggerError = (msg: string) => {
    Swal.fire('Oops...', msg, 'error');
  };

  const triggerSuccess = () => {
    setTimeout(backToStart, 2000);
    Swal.fire('Right on!', 'Shipment successful.', 'success');
  };

  return (
    <PrintAndUpdateStyle>
      <Typography align="center" className="ActionLabel">
        Printing Packing List
        <br />
        <span style={{ fontSize: 24 }}>and</span>
        <br />
        Updating Inventory
      </Typography>
      {successRender
        ? triggerSuccess()
        : !errorRender && (
            <Loading
              message="Make sure you're within Wi-Fi range..."
              mobile={true}
            />
          )}
      {renderButtons()}
    </PrintAndUpdateStyle>
  );
};

const PrintAndUpdateStyle = styled.div`
  .ActionLabel {
    width: 100%;
    font-size: 20pt;
    margin: 20px 0;
  }
  .TryAgainButton,
  .GiveUpButton {
    height: 32px;
    display: flex;
    margin: auto 0;
  }
  .Buttons {
    display: flex;
    place-content: center;
  }

  .Spacing {
    margin-top: 50px;
  }
`;
