import React from 'react';
import styled from 'styled-components';
import logo from '../assets/companyLogo.png';

export interface LoadingProps {
  message?: string;
  mobile?: boolean;
  style?: React.CSSProperties;
}

export const Loading: React.FC<LoadingProps> = ({ message, mobile, style }) => (
  <LoadingStyling mobile={mobile} style={style}>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img src={logo} alt="Company Logo" />
    </div>
    <div className="ErrorMessage">{message}</div>
  </LoadingStyling>
);

const LoadingStyling = styled.div<LoadingProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ErrorMessage {
    margin: 10px 20px 0px 20px;
    font-size: 17px;
  }

  align-items: center;
  ${(LoadingProps) => !LoadingProps.mobile && `height:100vh;`}

  img {
    width: 250px;

    animation-name: heartBeat;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }

  @keyframes heartBeat {
    0% {
      transform: scale(none);
      -webkit-transform: scale(none);
    }

    20% {
      transform: scale(1.2, 1.2);
      -webkit-transform: scale(1.2, 1.2);
    }

    30% {
      transform: scale(1.05, 1.05);
      -webkit-transform: scale(1.05, 1.05);
    }

    45% {
      transform: scale(1.25, 1.25);
      -webkit-transform: scale(1.25, 1.25);
    }

    50% {
      transform: scale(none, none);
      -webkit-transform: scale(none, none);
    }

    100% {
      transform: scale(none, none);
      -webkit-transform: scale(none, none);
    }
  }
`;
