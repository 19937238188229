import React, { useState, useEffect } from 'react';
import { CenterPage } from '../../CenterPage';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {
  UserActions,
  UserWithPin,
  getCachedPin,
  clearCachedPin,
} from '../../../state/user';
import { useDispatch } from 'react-redux';
import { Loading } from '../../Loading';
import { ApolloClient, useApolloClient } from '@apollo/client';
import styled from 'styled-components';
import { MeDocument } from '../../../generated/graphql';
import { releaseProfile } from '../../../runtime-environment';

const fetchMe = async (client: ApolloClient<any>, pin: string) => {
  const result = await client.query<{ me: UserWithPin | null }>({
    query: MeDocument,
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        'x-client-app': 'transit-tracker',
        Authorization: `Basic ${pin}`,
      },
    },
  });

  if (result.data && result.data.me) {
    result.data.me.pin = pin;
  }

  return result;
};

enum LoginStatus {
  NotStarted,
  Pending,
  Success,
  Fail,
}

export const LoginPage = () => {
  const [pin, setPin_] = useState('');
  const [loginStatus, setLoginStatus] = useState(LoginStatus.Pending);
  const [errorText, setErrorText] = useState('');

  const setLoginFailure = (error: string) => {
    setErrorText(error);
    setLoginStatus(LoginStatus.Fail);
  };

  const dispatch = useDispatch();

  const completeLogin = (user: UserWithPin) => {
    dispatch(UserActions.login(user));
  };

  const currentUrl = window.location.href.split('/').pop();

  const client = useApolloClient();
  const startLogin = (pin: string) => {
    setPin('');
    setLoginStatus(LoginStatus.Pending);
    fetchMe(client, pin)
      .then((result) => {
        if (result.loading || !result.data) {
          setLoginFailure('Unknown error while logging in');
          return;
        }

        const me = result.data.me;
        if (me === null || me === undefined) {
          setLoginFailure('Invalid PIN');
          setTimeout(() => setLoginStatus(LoginStatus.NotStarted), 1000);
          return;
        }

        setLoginStatus(LoginStatus.Success);
        setTimeout(() => completeLogin(me), 500);
      })
      .catch(() => {
        // No sentry reporting here because it is most likely due to user
        // error (wrong PIN)
        setLoginFailure('Invalid PIN');
        clearCachedPin();
        setTimeout(() => setLoginStatus(LoginStatus.NotStarted), 1000);
      });
  };

  // Try and use the cached pin if there is one to automatically log in
  useEffect(() => {
    const cachedPin = getCachedPin();
    if (cachedPin !== null) startLogin(cachedPin);
    else setLoginStatus(LoginStatus.NotStarted);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setPin = (value: string) => {
    if (!/^[0-9]*$/.test(value)) return;
    setPin_(value);
    if (value.length === 4) startLogin(value);
  };

  if (loginStatus === LoginStatus.Pending) {
    return <Loading />;
  }

  const profile = releaseProfile();
  let state = '';
  if (profile === 'local') state = 'local';
  if (profile === 'staging') state = 'staging';
  if (profile === 'feat-eval') state = 'feature evaluation';

  const mobile = window.location.pathname.startsWith('/mobile');

  const content = (() => {
    if (loginStatus === LoginStatus.NotStarted) {
      return (
        <>
          <div style={{ paddingBottom: '4rem' }}>
            <Typography
              align="center"
              variant="h2"
              style={mobile ? { fontSize: '3rem' } : undefined}
            >
              Transit Tracker
            </Typography>
            {state && (
              <Typography
                align="center"
                variant="h6"
                style={{ textTransform: 'uppercase' }}
              >
                {state}
              </Typography>
            )}
          </div>
          <TextField
            id="pin"
            value={pin}
            type="password"
            onBlurCapture={(e) => e.target.focus()}
            onChange={(e) => setPin(e.target.value)}
            placeholder="Enter Your PIN"
            variant="standard"
            inputProps={{
              pattern: '\\d*',
              style: {
                textAlign: 'center',
              },
            }}
            autoFocus
          />
        </>
      );
    }

    if (loginStatus === LoginStatus.Fail) {
      return (
        <Typography align="center" variant="h2" color="error">
          {errorText}
        </Typography>
      );
    }

    if (loginStatus === LoginStatus.Success && currentUrl !== 'packs') {
      return (
        <LoginStyle>
          <Typography align="center" variant="h2" style={{ color: '#383' }}>
            Login Successful
          </Typography>
        </LoginStyle>
      );
    }

    // Shouldn't reach here
    return null;
  })();

  return (
    <CenterPage style={{ flexDirection: 'column', padding: '2em' }}>
      {content}
    </CenterPage>
  );
};

const LoginStyle = styled.div`
  animation: fadein 0.5s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
