import React from 'react';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Typography from '@mui/material/Typography';
import { Loading } from '../Loading';
import { Error } from '../Error';
import {
  useTransferQuery,
  useFreightCarriersQuery,
  useLocationsQuery,
  InventoryTransferType,
} from '../../generated/graphql';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { tidyName } from './LocationPicker';

export const PackManagementConfirmation: React.FC<{
  transferId: ID;
  handlePackConfirm: () => void;
}> = ({ transferId, handlePackConfirm }) => {
  const freightCarriers = useFreightCarriersQuery();
  const locations = useLocationsQuery({
    partialRefetch: true,
  });

  const { loading, error, data } = useTransferQuery({
    variables: { id: transferId },
  });

  if (loading && !data) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  const totalPacks = data?.inventoryTransfer?.packCount;
  const totalWeight = data?.inventoryTransfer?.totalKilograms;
  const totalM3 = data?.inventoryTransfer?.totalMetersCubed.toFixed(3);

  return (
    <ConfirmationStyle>
      <div className="MetaInfo">
        <Typography align="center" className="Origin">
          {tidyName(
            locations?.data?.stockLocations.find(
              (x) => x.id === data?.inventoryTransfer?.origin.id,
            )?.name ?? '',
          )}
        </Typography>
        <ArrowDownwardIcon className="DownArrow" />
        <Typography align="center" className="Destination">
          {tidyName(
            locations?.data?.stockLocations.find(
              (x) => x.id === data?.inventoryTransfer?.destination.id,
            )?.name ?? '',
          )}
        </Typography>
        {data?.inventoryTransfer?.type !== InventoryTransferType.Instant && (
          <>
            <Typography align="center" className="By">
              via
            </Typography>

            <Typography align="center" className="FreightCarrier">
              {data?.inventoryTransfer?.type === InventoryTransferType.Container
                ? 'Container'
                : freightCarriers?.data?.shippingProviders.find(
                    (x) =>
                      x.id === data?.inventoryTransfer?.shippingProvider?.id!,
                  )?.name}
            </Typography>
          </>
        )}
      </div>

      <TableContainer style={{ margin: '40px 0px' }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Weight</TableCell>
              <TableCell align="right">M3</TableCell>
              <TableCell align="right">Packs</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="right">{totalWeight}</TableCell>
              <TableCell align="right">{totalM3}</TableCell>
              <TableCell align="right">{totalPacks}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        className="ConfirmButton"
        color="primary"
        variant="contained"
        disableElevation
        onClick={handlePackConfirm}
      >
        Confirm
      </Button>
    </ConfirmationStyle>
  );
};

const ConfirmationStyle = styled.div`
  margin-top: 20px;
  .Origin,
  .Destination,
  .TotalPacks,
  .FreightCarrier,
  .TotalM3,
  .TotalWeight {
    width: 100%;
    font-size: 17pt;
    margin: 5px 0;
  }

  .LeadingText {
    width: 100%;
    font-size: 16pt;
  }

  .MetaInfo {
    background-color: #37595417;
    padding: 20px 0px;
  }

  .DownArrow {
    width: 100%;
    font-size: 20pt;
  }

  .ConfirmButton {
    height: 32px;
    display: flex;
    margin: 10px auto 0 auto;
  }
`;
