import React from 'react';
import { Loading } from '../Loading';
import { Error } from '../Error';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import styled from 'styled-components';
import {
  FreightCarriersQuery,
  FreightCarriersQueryVariables,
} from '../../generated/graphql';
import { QueryResult } from '@apollo/client';

const TruckProvidersPicker: React.FC<{
  onChoose: (freightCarrierId: ID) => void;
  freightCarriers: QueryResult<
    FreightCarriersQuery,
    FreightCarriersQueryVariables
  >;
}> = ({ onChoose, freightCarriers }) => {
  const filteredFreightCarriers = freightCarriers.data?.shippingProviders.filter(
    (freightCarrier) => ['32', '33', '144'].includes(freightCarrier.id),
  );

  if (freightCarriers.loading) return <Loading />;
  if (freightCarriers.error) return <Error />;

  return (
    <Container
      maxWidth="sm"
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        overflow: 'auto',
        width: '100%',
      }}
    >
      <List style={{ display: 'flex', flexDirection: 'column' }}>
        {filteredFreightCarriers?.map((carrier) => (
          <ShippingMethodStyle key={carrier.id}>
            <ListItem
              button
              style={{ padding: '2rem 1rem' }}
              onClick={() => onChoose(carrier.id)}
            >
              <Typography
                align="center"
                style={{
                  width: '100%',
                  fontSize: '22pt',
                }}
              >
                {carrier.name}
              </Typography>
            </ListItem>
          </ShippingMethodStyle>
        ))}
      </List>
    </Container>
  );
};

export default TruckProvidersPicker;

const ShippingMethodStyle = styled.div`
  width: 552px;
  :nth-child(even) {
    background-color: #f7f7f7;
  }
  :nth-child(odd) {
    background-color: #f1f1f130;
  }
`;
