import { Button, Grid } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { displayUserErrors } from '../../errors';
import { useConsumePacksMutation } from '../../generated/graphql';
import {
  deleteLocalStorage,
  loadLocalStorage,
  saveLocalStorage,
} from '../../util';
import { BackButtonDialog } from '../BackButtonDialog';
import { PackLabelData, PackLines } from './PackLinesDisplay';

const ConsumePacks: React.FC<{ location: ID; onBack: () => void }> = ({
  location,
  onBack,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { packs, addPack, removePack, clearPacks } = usePacks();

  const [consume, { loading }] = useConsumePacksMutation();
  const history = useHistory();
  const backToStart = () => {
    clearPacks();
    onBack();
    history.push('/mobile');
  };

  const handleAdjust = () => {
    displayUserErrors(
      'result',
      consume({
        variables: {
          input: {
            packNumbers: packs.map((x) => x.packNumber),
            stockLocationId: location,
          },
        },
      }).then((res) => {
        const errors = res.data?.result.userErrors;
        if (errors != null && errors.length === 0) {
          Swal.fire({
            icon: 'success',
            title: 'Consumed Packs',
            timer: 800,
            showConfirmButton: false,
          }).then(() => {
            backToStart();
          });
        }

        return res;
      }),
      true,
    );
  };

  return (
    <>
      <PackLines
        onAddPack={addPack}
        onDelete={removePack}
        packs={packs.map((x) => ({
          id: x.packNumber,
          packNumber: x.packNumber,
        }))}
        totalKilograms={packs.reduce((acc, pack) => acc + pack.kilograms, 0)}
        totalMetersCubed={packs.reduce(
          (acc, pack) => acc + pack.metersCubed,
          0,
        )}
        useTreatmentView={false}
      />
      <Grid
        container
        justifyContent="center"
        direction="row"
        alignItems="center"
        spacing={2}
        style={{ marginTop: '0.5rem' }}
      >
        <Grid item xs={6} sm={3}>
          <Button
            style={{ width: '100%', height: '32px' }}
            color="secondary"
            variant="contained"
            disableElevation
            onClick={() => setOpenDialog(true)}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            style={{ width: '100%', height: '32px' }}
            color="primary"
            variant="contained"
            onClick={handleAdjust}
            disableElevation
            disabled={packs.length === 0 || loading}
          >
            Consume
          </Button>
        </Grid>
      </Grid>

      <BackButtonDialog
        noDeleteTransfer
        message="You will lose your progress"
        open={openDialog}
        onBack={backToStart}
        close={() => setOpenDialog(false)}
        error={false}
      />
    </>
  );
};

export default ConsumePacks;

const PACKS_KEY = 'consume-packs-packs';

function usePacks() {
  const [packs, setPacks] = useState<PackLabelData[]>(() =>
    loadLocalStorage(PACKS_KEY, []),
  );

  const addPack = useCallback((pack: PackLabelData) => {
    setPacks((packs) => {
      const newValue = [...packs, pack];
      saveLocalStorage(PACKS_KEY, newValue);
      return newValue;
    });
  }, []);

  const removePack = useCallback((packNumber: string) => {
    setPacks((packs) => {
      const newValue = packs.filter((x) => x.packNumber !== packNumber);
      saveLocalStorage(PACKS_KEY, newValue);
      return newValue;
    });
  }, []);

  const clearPacks = useCallback(() => {
    deleteLocalStorage(PACKS_KEY);
    setPacks([]);
  }, []);

  return {
    packs,
    addPack,
    removePack,
    clearPacks,
  };
}
