import React from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DestinationSelector from '../DestinationSelector';
import TransferStatusSelector from '../TransferStatusSelector';
import { TransferPreviewList } from '../TransferPreviewList';
import { Link } from 'react-router-dom';

const TransferListPage: React.FC = () => (
  <>
    <div style={{padding: '10px 0 0 15px'}} ><Link to='/treatment-history'>Treatment History</Link></div>
    <Container maxWidth="md" style={{ paddingTop: '2rem' }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ padding: '0 4em 3em 4em', overflow: 'hidden' }}
      >
        <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column' }}>
          <TransferStatusSelector />
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h2" style={{ textAlign: 'center' }}>
            Shipments
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <DestinationSelector />
        </Grid>
      </Grid>
      <TransferPreviewList />
    </Container>
  </>
);

export default TransferListPage;
