import React from 'react';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

import styled from 'styled-components';

import { round } from '../../utils';
import { ShipmentTablePack } from './Pages/TransferPage';
import { UnitOfMeasureName } from '../../generated/graphql';

const TableCellStyle = styled(TableCell)`
  min-height: 70px;
`;

const EPSILON = 1e-5;

const nearlyZero = (value: number) => -EPSILON < value && value < EPSILON;

function quantityDisplay(value: number, uom?: UnitOfMeasureName) {
  if (nearlyZero(value)) return '-';
  return uom === UnitOfMeasureName.Pce ? round(value, 2) : value.toFixed(2);
}

const PackRow: React.FC<ShipmentTablePack> = ({
  packNumber,
  stockItem,
  quantity,
  metresCubed,
  weightKilograms,
}) => (
  <TableRow>
    <TableCellStyle component="th" scope="row">
      <span style={{ fontSize: '1.5rem' }}>{packNumber}</span>
    </TableCellStyle>
    <TableCellStyle>
      <span style={{ fontSize: '0.9rem', color: 'rgba(0, 0, 0, 0.54)' }}>
        {stockItem?.stockCode}
      </span>
    </TableCellStyle>
    <TableCellStyle>
      <span style={{ fontSize: '0.8rem', color: 'rgba(0, 0, 0, 0.54)' }}>
        {stockItem?.description}
      </span>
    </TableCellStyle>
    <TableCellStyle size="small" align="right">
      {quantityDisplay(quantity, stockItem?.unitOfMeasure.name)}
    </TableCellStyle>
    <TableCellStyle size="small" align="center">
      {/* The quantity of a pack is in the unit of measure of the stock item */}
      {stockItem?.unitOfMeasure.symbolCapitalized}
    </TableCellStyle>
    <TableCellStyle size="small" align="right">
      {quantityDisplay(metresCubed)}
    </TableCellStyle>
    <TableCellStyle size="small" align="right">
      {quantityDisplay(weightKilograms)}
    </TableCellStyle>
  </TableRow>
);

const TableStyle = styled(Table)`
  margin-bottom: 8em;

  .MuiTableCell-stickyHeader {
    background-color: white !important;
  }
`;

const TransferPacksTable: React.FC<{
  packs: ShipmentTablePack[];
}> = ({ packs }) => {
  return (
    <TableStyle stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell size="small" align="right">
            Tally
          </TableCell>
          <TableCellStyle size="small" align="center">
            UOM
          </TableCellStyle>
          <TableCell size="small" align="right">
            M3
          </TableCell>
          <TableCell size="small" align="right">
            KG
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {packs.map((p) => (
          <PackRow key={p.id} {...p} />
        ))}
      </TableBody>
    </TableStyle>
  );
};

export default TransferPacksTable;
