import * as Sentry from '@sentry/react';
import { useDeleteTransferMutation } from '../../../generated/graphql';
import ConfirmationDialog from '../../ConfirmationDialog';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';

export function DeleteDialog({
  open,
  onClose,
  confirmDisabled,
  inventoryTransferId,
}: {
  open: boolean;
  onClose: () => void;
  confirmDisabled: boolean;
  inventoryTransferId: ID;
}) {
  const history = useHistory();
  const [deleteTransfer, { loading }] = useDeleteTransferMutation({
    variables: {
      input: {
        inventoryTransferId,
      },
    },
  });

  return (
    <ConfirmationDialog
      open={open}
      close={onClose}
      handleOk={() => {
        deleteTransfer()
          .then(() => {
            onClose();
            history.push('/');
          })
          .catch((err) => {
            onClose();
            console.error(err);
            Sentry.captureException(err);
            Swal.fire({
              icon: 'error',
              title: 'Something went wrong',
              text: 'Please contact IT',
            });
          });
      }}
      confirmDisabled={confirmDisabled || loading}
      dialogText="This will reverse any stock transfers performed by this inventory transfer and then delete the transfer itself. Are you sure?"
    />
  );
}
