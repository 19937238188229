import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import {
  InventoryTransferCreateInput,
  InventoryTransferStatus,
  useActiveUserTransfersQuery,
  useCreateTransferMutation,
} from './generated/graphql';
import { useSelector } from './state';
import { UserSelectors } from './state/user';

export function makeCacheId(): ID {
  return `cached:${nanoid()}` as ID;
}

export function isCacheId(id: ID): boolean {
  return id.startsWith('cached:');
}

export function useActiveTransfer(useCached: boolean) {
  const currentUser = useSelector(UserSelectors.current);
  const { data } = useActiveUserTransfersQuery({
    skip: !currentUser,
    fetchPolicy: useCached ? 'cache-only' : 'network-only',
    nextFetchPolicy: useCached ? undefined : 'cache-first',
    variables: {
      status: InventoryTransferStatus.Creating,
      userId: currentUser?.id,
    },
  });

  return {
    loading: !data,
    currentUser,
    transfer: data?.inventoryTransfers[0],
  };
}

export function useCreateTransfer() {
  const [create] = useCreateTransferMutation();
  return useCallback(
    (input: InventoryTransferCreateInput) => {
      return create({
        variables: { input },
        refetchQueries: ['ActiveUserTransfers'],
        awaitRefetchQueries: true,
      });
    },
    [create],
  );
}

export function saveLocalStorage(key: string, data: unknown): void {
  localStorage.setItem(key, JSON.stringify(data));
}

export function loadLocalStorage<T>(key: string, defaultValue: T): T {
  const item = localStorage.getItem(key);
  return item == null ? defaultValue : (JSON.parse(item) as T);
}

export function deleteLocalStorage(key: string): void {
  localStorage.removeItem(key);
}
