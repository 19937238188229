import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { InventoryTransferPack } from '../../generated/graphql';
import styled from 'styled-components';


export interface DetailedPackLineDisplayProps {
  pack: InventoryTransferPack;
  remove: (pack: InventoryTransferPack) => void;
}

export const DetailedPackLineDisplay: React.FC<DetailedPackLineDisplayProps> = ({
  pack,
  remove,
}) => {
  return (
    <DetailedPackLineStyle className='detailed-pack-line'>
      <div className='lineHeader'>
        <div>
          <p><strong>Pack</strong> {pack.packNumber}</p>
        </div>
        <div>
          <p><strong>Stock Code</strong> {pack.stockItem?.stockCode}</p>
        </div>
        <div>
          <IconButton onClick={() => remove(pack)} edge="end" aria-label="delete" size="medium" style={{ marginRight: '2px' }}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
      <p><strong>Unit No.</strong> {pack.unitText ?? 'N/A'}</p>
      <p><strong>Description</strong> {pack.manualDescription ?? pack?.stockItem?.description}</p>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p><strong>M3</strong> {pack.metresCubed}</p>
        <p><strong>KG</strong> {pack.weightKilograms}</p>
      </div>
      <div>
        <p><strong>Treatment</strong> {pack.treatmentType}</p>
      </div>
      <p><strong>Destination</strong> {pack.shippingDestination}</p>
    </DetailedPackLineStyle>
  );
};

const DetailedPackLineStyle = styled.div`
  word-break: break-word;
  padding: 0 2px 15px 2px;
  p { margin: 2px 0; }
  
  .lineHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;