import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { isCacheId } from '../../util';
import { useMaybeOffline } from '../../services/apollo/network';
import { WifiPendingIndicator } from '../WifiPendingIndicator';

export interface PackLineDisplayProps {
  id: ID;
  text: string;
  remove: (id: string) => void;
}

export const PackLineDisplay: React.FC<PackLineDisplayProps> = ({
  id,
  text,
  remove,
}) => {
  const maybeOffline = useMaybeOffline();

  let pendingIcon;
  if (isCacheId(id)) {
    pendingIcon = maybeOffline ? (
      <div style={{ marginTop: '10px' }}>
        <WifiPendingIndicator />
      </div>
    ) : (
      <CircularProgress size={30} />
    );
  }

  return (
    <ListItem>
      <ListItemText
        primary={text}
        style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
      />
      <ListItemSecondaryAction>
        {pendingIcon || (
          <IconButton
            onClick={() => remove(id)}
            edge="end"
            aria-label="delete"
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};