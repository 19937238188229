import { createAction, createReducer, Action } from 'typesafe-actions';
import { AppState } from '.';
import { InventoryTransferStatus } from '../generated/graphql';

export interface TransfersState {
  selectedTransferKind: 'truck' | 'container';
  status: InventoryTransferStatus;
  destinationFilter: ID[] | null;
  reference: string;
}

const initialState: TransfersState = {
  selectedTransferKind: 'truck',
  status: InventoryTransferStatus.InTransit,
  destinationFilter: null,
  reference: '',
};

export const TransferActions = {
  setSelectedTransferKind: createAction(
    '@transfers/SET_SELECTED_TRANSFER_KIND',
    (kind: 'truck' | 'container') => kind,
  )(),
  setStatus: createAction(
    '@transfers/SET_STATUS',
    (status: InventoryTransferStatus) => status,
  )(),
  setReference: createAction(
    '@transfers/SET_REFERENCE',
    (reference: string) => reference,
  )(),
  setDestinationFilter: createAction(
    '@transfers/SET_DESTINATION_FILTER',
    (filter: ID[] | null) => filter,
  )(),
};

export const TransferSelectors = {
  transferKind: (state: AppState) => state.transfers.selectedTransferKind,
  reference: (state: AppState) => state.transfers.reference,
};

const transfersReducer = createReducer<TransfersState, Action>(initialState)
  .handleAction(
    TransferActions.setSelectedTransferKind,
    (state, { payload }) => ({
      ...state,
      selectedTransferKind: payload,
    }),
  )
  .handleAction(TransferActions.setStatus, (state, { payload }) => ({
    ...state,
    status: payload,
  }))
  .handleAction(TransferActions.setDestinationFilter, (state, { payload }) => ({
    ...state,
    destinationFilter: payload,
  }))
  .handleAction(TransferActions.setReference, (state, { payload }) => ({
    ...state,
    reference: payload,
  }));

export default transfersReducer;
