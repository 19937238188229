import React, { useCallback, useState } from 'react';
import { PackLabelData, PackLinesDisplay } from './PackLinesDisplay';
import { BackButtonDialog } from '../BackButtonDialog';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {
  ActiveUserTransfersQuery,
  InventoryTransferType,
  useAddPackToTransferMutation,
  useRemovePackFromTransferMutation,
  ActiveUserTransfersDocument,
  InventoryTransferStatus,
  ActiveUserTransfersQueryVariables,
} from '../../generated/graphql';
import { Location } from '../../constants';
import { Error } from '../Error';
import { makeCacheId } from '../../util';
import { usePersistedActions } from '../../services/persist/Persistor';
import { ApolloCache, ApolloError } from '@apollo/client';
import { DelayedLinearProgress } from '../DelayedLinearProgress';
import Swal from 'sweetalert2';
import * as Sentry from '@sentry/react';


export const ScanPacks: React.FC<{
  transfer: ActiveUserTransfersQuery['inventoryTransfers'][0] | null;
  handlePackScan: () => void;
}> = ({ transfer, handlePackScan }) => {
  const [openDialogue, setOpenDialogue] = useState(false);
  const [packLinesKey, setPackLinesKey] = useState<number>(Date.now());

  const manageTransferPacks = useManageTransferPacks(transfer?.id ?? null);
  const { performAction, hasPending } = usePersistedActions({
    storageKey: 'transfer-packs',
    action: manageTransferPacks.performAction,
  });

  let message = 'Transit';
  if (transfer == null) {
    message = '...';
  } else if (transfer.type === InventoryTransferType.Instant) {
    message = transfer.origin.id === Location.Treatment ? 'Receive' : 'Send';
  }

  const handleCloseDialog = () => {
    setOpenDialogue(false);
    setPackLinesKey(Date.now());
  }

  return (
    <>
      {manageTransferPacks.removeLoading && (
        <DelayedLinearProgress wholeScreen />
      )}
      {transfer ? (
        <PackLinesDisplay key={packLinesKey} transfer={transfer} performAction={performAction} />
      ) : (
        <Error fullscreen errorText="No current active transfer" />
      )}
      <Grid
        container
        justifyContent="center"
        direction="row"
        alignItems="start"
        flexGrow={1}
        spacing={2}
        style={{ marginTop: '0.5rem' }}
      >
        <Grid item xs={6} sm={3}>
          <Button
            style={{ width: '100%', height: '32px' }}
            color="secondary"
            variant="contained"
            disableElevation
            onClick={() => setOpenDialogue(true)}
            disabled={hasPending}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            style={{ width: '100%', height: '32px' }}
            color="primary"
            variant="contained"
            onClick={handlePackScan}
            disableElevation
            disabled={!transfer || hasPending || transfer.packs.length === 0}
          >
            {message}
          </Button>
        </Grid>
      </Grid>

      {transfer && (
        <BackButtonDialog
          inventoryTransferId={transfer.id}
          open={openDialogue}
          close={handleCloseDialog}
          error={false}
        />
      )}
    </>
  );
};

interface AddPackOperation {
  type: 'add';
  transferId: ID;
  userId: ID;
  pack: PackLabelData;
  treatmentWarningAcknowledged: boolean;
}

interface RemovePackOperation {
  type: 'remove';
  userId: ID;
  packId: ID;
}

export type TransferPacksOperation = AddPackOperation | RemovePackOperation;

function useManageTransferPacks(transferId: ID | null) {
  const [addTransferPack] = useAddPackToTransferMutation();
  const [removeTransferPack, removeInfo] = useRemovePackFromTransferMutation();

  const removePack = useCallback(
    async (op: RemovePackOperation) => {
      await removeTransferPack({
        variables: {
          input: {
            packId: op.packId,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          result: {
            __typename: 'InventoryTransferRemovePackPayload',
            inventoryTransfer: {
              __typename: 'InventoryTransfer',
              id: op.packId,
            },
          },
        },
        update: (store, { data }) => {
          cacheUpdate(store, op.userId, data, (transfer) => ({
            ...transfer,
            packs: transfer.packs.filter((x) => x.id !== op.packId),
          }));
        },
      });
    },
    [removeTransferPack],
  );

  const addPack = useCallback(
    async (op: AddPackOperation) => {
      if (op.transferId !== transferId) {
        return;
      }

      await addTransferPack({
        variables: {
          input: {
            inventoryTransferId: transferId,
            packNumber: op.pack.packNumber,
            stockCode: op.pack.stockCode,
            metresCubed: op.pack.metersCubed,
            weightKilograms: op.pack.kilograms,
            quantity: op.pack.quantity,
            manualDescription: op.pack.manualDescription,
            manualShippingDestination: op.pack.manualShippingDestination,
            manualTreatmentType: op.pack.manualTreatmentType,
            unitNumber: op.pack.unitNumber,
            treatmentWarningAcknowledged: op.treatmentWarningAcknowledged
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          result: {
            __typename: 'InventoryTransferAddPackPayload',
            inventoryTransfer: {
              __typename: 'InventoryTransfer',
              id: transferId,
            },
            pack: {
              __typename: 'InventoryTransferPack',
              id: makeCacheId(),
              packNumber: op.pack.packNumber,
              metresCubed: op.pack.metersCubed,
              weightKilograms: op.pack.kilograms,
              stockItem: { __typename: 'StockItem', shippingDestination: null, treatmentType: null, description: null, stockCode: op.pack.stockCode },
              manualDescription: null,
              shippingDestination: null,
              treatmentType: null,
              unitText: null
            },
            warning: null
          },
        },
        update: (store, { data }) => {
          cacheUpdate(store, op.userId, data, (transfer, data) => ({
            ...transfer,
            // Add stocktake item from the mutation to the list of packs.
            packs: [...transfer.packs, data.result.pack],
          }));
          if (data?.result?.warning) {
            Swal.fire('Warning', data.result.warning, 'warning');
          }
        },
        onError: (error: ApolloError) => {
          if (error.graphQLErrors[0].extensions?.code === "BAD_USER_INPUT") {
            Swal.fire('Add Pack Error', error.message, 'error');
          }
          else if (error.graphQLErrors[0].extensions?.code === "TREATMENT_WARNING") {
            Swal.fire({
              title: "Warning",
              text: error.message,
              icon: "warning",
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No"
            }).then(async (result) => {
              if (result.isConfirmed) {
                op.treatmentWarningAcknowledged = true;
                await addPack(op);
              }
            })
          }
          else {
            Swal.fire('Oops...', 'Could not add pack!', 'error');
            Sentry.captureException(error);
          }
        }
      });
    },
    [addTransferPack, transferId],
  );

  const performAction = useCallback(
    async (op: TransferPacksOperation) => {
      if (op.type === 'add') await addPack(op);
      else await removePack(op);
    },
    [addPack, removePack],
  );

  return {
    removeLoading: removeInfo.loading,
    performAction,
  };
}

type ActiveTransfer = ActiveUserTransfersQuery['inventoryTransfers'][0];
function cacheUpdate<T>(
  store: ApolloCache<unknown>,
  userId: ID,
  data: T | null | undefined,
  mapTransfer: (transfer: ActiveTransfer, data: T) => ActiveTransfer,
) {
  const activeTransfers = store.readQuery<
    ActiveUserTransfersQuery,
    ActiveUserTransfersQueryVariables
  >({
    query: ActiveUserTransfersDocument,
    variables: {
      status: InventoryTransferStatus.Creating,
      userId,
    },
  });

  if (data == null || activeTransfers == null) {
    return;
  }

  // Write our data back to the cache.
  store.writeQuery({
    query: ActiveUserTransfersDocument,
    data: {
      ...activeTransfers,
      inventoryTransfers: activeTransfers.inventoryTransfers.map(
        (transfer, idx) => {
          if (idx !== 0) return transfer;
          return mapTransfer(transfer, data);
        },
      ),
    },
  });
}
