import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import * as Integrations from '@sentry/integrations';
import './global.css';
import 'sweetalert2/dist/sweetalert2.css';
import App from './components/Router';
import { Error } from './components/Error';
import { history } from './history';
import { env, releaseProfile } from './runtime-environment';

import apollo from './services/apollo';
import configureStore from './state';

import { ApolloProvider } from '@apollo/client';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import { Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { StylesProvider } from '@mui/styles';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import styled from 'styled-components';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://c0f72b3231a149deb41a133acd2cfb48@o925760.ingest.sentry.io/5933401',
    release: `transit-tracker@${env.version}`,
    environment: releaseProfile(),
    normalizeDepth: 10,
    // We add them with more detail through apollo-link-sentry
    beforeBreadcrumb: excludeGraphQLFetch,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: ['localhost', '.genia.co.nz'],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Integrations.CaptureConsole({
        levels: ['warn', 'error'],
      }),
      new Integrations.Offline(),
    ],
    // We don't have millions of users, better safe than sorry
    tracesSampleRate: 1.0,
  });
}

interface RootContainerProps {
  env: string;
}

const RootContainer = styled.div<RootContainerProps>`
  height: 100%;
  ${props => props.env === 'staging' && 'border: 5px solid red;'}
  `;

export const store = configureStore();

const materialOverrides = createTheme({
  palette: {
    primary: {
      main: '#375954',
    },
    secondary: {
      main: '#999',
    },
    error: {
      main: '#d35f5f',
    },
    warning: {
      main: '#e9822e',
    },
  },
});

const Root: React.FC = () => (
  <RootContainer env={env.profile}>
    <Sentry.ErrorBoundary
      fallback={<Error fullscreen errorText="Uh-oh. The app crashed." />}
      showDialog
    >
      <ReduxProvider store={store}>
        <ApolloProvider client={apollo()}>
          <StylesProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MuiThemeProvider theme={materialOverrides}>
                <Router history={history}>
                  <App />
                </Router>
              </MuiThemeProvider>
            </LocalizationProvider>
          </StylesProvider>
        </ApolloProvider>
      </ReduxProvider>
    </Sentry.ErrorBoundary>
  </RootContainer>
);

ReactDOM.render(<Root />, document.getElementById('root'));
