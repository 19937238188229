import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import { Button, Container, IconButton, Typography } from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBack';
import dateFormat from 'dateformat';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  useFreightCarriersQuery,
  useLocationsQuery,
  TransferQuery,
  useTransferQuery,
  useReceiveTransferMutation,
  useUndoTransferReceiptMutation,
  InventoryTransferType,
} from '../../../generated/graphql';
import { Loading } from '../../Loading';
import { Error } from '../../Error';
import ConfirmationDialog from '../../ConfirmationDialog';
import TransferSummary from '../TransferSummary';
import TransferPacksTable from '../TransferPacksTable';
import { RedirectDialog } from './RedirectDialog';
import { DeleteDialog } from './DeleteDialog';

const FixedBottom = styled.div`
  position: fixed;
  padding: 1em;
  bottom: 2em;
`;

export type ShipmentTablePack = Exclude<
  TransferQuery['inventoryTransfer'],
  null | undefined
>['packs'][0];

const TransferPage: React.FC = () => {
  const { id } = useRouteMatch<{ id: string }>().params;

  const history = useHistory();

  type DialogState =
    | 'confirmUndo'
    | 'confirmReceive'
    | 'confirmRedirect'
    | 'confirmDelete'
    | 'none';
  const [dialogState, setDialogState] = useState<DialogState>('none');

  const { error, data } = useTransferQuery({
    variables: { id },
  });

  const freightCarriers = useFreightCarriersQuery();
  const locations = useLocationsQuery();
  const [receiveTransfer, receiveInfo] = useReceiveTransferMutation();
  const [undoTransfer, undoInfo] = useUndoTransferReceiptMutation();

  const confirmDisabled = receiveInfo.loading || undoInfo.loading;

  if (error) return <Error fullscreen />;
  if (!data) return <Loading />;

  if (freightCarriers.error) return <Error fullscreen />;
  if (!freightCarriers.data) return <Loading />;

  if (locations.error) return <Error fullscreen />;
  if (!locations.data) return <Loading />;

  const { inventoryTransfer } = data;
  if (inventoryTransfer == null) {
    return <Error fullscreen errorText="Shipment not available" />;
  }

  const instant = inventoryTransfer.type === InventoryTransferType.Instant;
  const received = !!inventoryTransfer.dateReceived;

  const handleConfirmReceive = () => {
    receiveTransfer({
      refetchQueries: ['Transfer'],
      variables: {
        input: {
          inventoryTransferId: data.inventoryTransfer
            ? data.inventoryTransfer.id
            : '',
        },
      },
    })
      .then(async (r) => {
        if (r.errors || !r.data) {
          Sentry.captureMessage(
            'Error receiving transfer',
            Sentry.Severity.Error,
          );
          window.alert('Error receiving transfer, please contact IT.');
        } else {
          history.push('/');
        }
        setDialogState('none');
      })
      .catch((error) => {
        Sentry.captureException(error);
        window.alert('Error receiving transfer, please contact IT.');
      });
  };

  const handleUndoShipment = () => {
    undoTransfer({
      refetchQueries: ['Transfer'],
      variables: {
        input: {
          inventoryTransferId: data.inventoryTransfer
            ? data.inventoryTransfer.id
            : '',
        },
      },
    })
      .then(async (r) => {
        if (r.errors || !r.data) {
          Sentry.captureMessage(
            'Error undoing transfer',
            Sentry.Severity.Error,
          );
          window.alert('Error undoing transfer, please contact IT.');
        } else {
          history.push('/');
        }
        setDialogState('none');
      })
      .catch((error) => {
        Sentry.captureException(error);
        window.alert('Error undoing transfer, please contact IT.');
      });
  };

  return (
    <Container maxWidth="md">
      <div
        style={{
          width: '100%',
          padding: '3em 3em 1em 3em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconButton
          onClick={() => history.push('/')}
          style={{ marginRight: '2em' }}
          size="large"
        >
          <BackIcon />
        </IconButton>
        <TransferSummary
          dateCreated={new Date(inventoryTransfer.dateCreated)}
          destinationLocation={
            locations.data.stockLocations.find(
              (x) => x.id === inventoryTransfer.destination.id,
            )?.name
          }
          originLocation={
            locations.data.stockLocations.find(
              (x) => x.id === inventoryTransfer.origin.id,
            )?.name
          }
          carrier={
            inventoryTransfer.type === InventoryTransferType.Container
              ? 'Container'
              : freightCarriers.data.shippingProviders.find(
                  (x) => x.id === inventoryTransfer.shippingProvider?.id!,
                )?.name
          }
          reference={inventoryTransfer.reference}
          metresCubed={inventoryTransfer.totalMetersCubed}
          weightKilograms={inventoryTransfer.totalKilograms}
          transferId={inventoryTransfer.id}
        />
      </div>
      <TransferPacksTable packs={inventoryTransfer.packs} />

      {received && (
        <FixedBottom
          as={Typography}
          color="textSecondary"
          style={{ left: '2em' }}
        >
          <b>
            Received on the{' '}
            {dateFormat(new Date(inventoryTransfer.dateCreated), 'dd/mm/yyyy')}{' '}
            at{' '}
            {dateFormat(new Date(inventoryTransfer.dateReceived!), 'h:MM tt')}
          </b>
        </FixedBottom>
      )}

      <FixedBottom style={{ right: '2em' }}>
        <Button
          onClick={() => setDialogState('confirmDelete')}
          disabled={instant}
          color="error"
        >
          Delete
        </Button>
        <Button
          onClick={() => setDialogState('confirmRedirect')}
          disabled={instant}
          color="secondary"
          style={{ marginLeft: '1em' }}
        >
          Redirect
        </Button>
        <Button
          onClick={() => {
            setDialogState(received ? 'confirmUndo' : 'confirmReceive');
          }}
          color={received ? 'secondary' : 'primary'}
          disabled={instant}
          style={{ margin: '1em' }}
        >
          {received ? 'Undo Receipt' : 'Receive'}
        </Button>
      </FixedBottom>

      <ConfirmationDialog
        open={dialogState === 'confirmReceive'}
        close={() => setDialogState('none')}
        handleOk={handleConfirmReceive}
        confirmDisabled={confirmDisabled}
        dialogText={`Are you sure you want to receive ${
          inventoryTransfer.packCount
        } pack${
          inventoryTransfer.packCount === 1 ? '' : 's'
        } on transfer with ${
          inventoryTransfer.reference
            ? `reference '${inventoryTransfer.reference}'`
            : 'no reference'
        }?`}
      />
      <ConfirmationDialog
        open={dialogState === 'confirmUndo'}
        close={() => setDialogState('none')}
        handleOk={handleUndoShipment}
        confirmDisabled={confirmDisabled}
        dialogText="This will create an inventory movement that is the opposite of the receiving action. Are you sure?"
      />
      <DeleteDialog
        open={dialogState === 'confirmDelete'}
        onClose={() => setDialogState('none')}
        confirmDisabled={confirmDisabled}
        inventoryTransferId={inventoryTransfer.id}
      />
      <RedirectDialog
        open={dialogState === 'confirmRedirect'}
        onClose={() => setDialogState('none')}
        confirmDisabled={confirmDisabled}
        locations={locations.data.stockLocations}
        inventoryTransferId={inventoryTransfer.id}
        currentOrigin={inventoryTransfer.origin.id}
        currentDestination={inventoryTransfer.destination.id}
      />
    </Container>
  );
};

export default TransferPage;
