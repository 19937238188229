import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import {
  LocationsQuery,
  LocationsQueryVariables,
} from '../../generated/graphql';
import { QueryResult } from '@apollo/client';
import { Loading } from '../Loading';
import { Error } from '../Error';
import styled from 'styled-components';

const LocationPicker: React.FC<{
  heading: string;
  onLocationChosen: (loc: ID) => void;
  locations: QueryResult<LocationsQuery, LocationsQueryVariables>;
  locationWhitelist?: ID[];
  locationBlacklist?: ID[];
}> = ({
  heading,
  onLocationChosen,
  locations,
  locationWhitelist,
  locationBlacklist,
}) => {
  if (locations.loading) return <Loading />;
  if (locations.error || !locations.data) return <Error />;

  const filteredLocations = locations.data.stockLocations.filter(
    (loc) =>
      (!locationWhitelist || locationWhitelist.includes(loc.id)) &&
      (!locationBlacklist || !locationBlacklist.includes(loc.id)),
  );

  return (
    <PickerStyle>
      <Typography variant="h5" align="center">
        {heading}
      </Typography>
      <List>
        {filteredLocations.map((loc) => (
          <ListItem
            button
            key={loc.id}
            className="ListItem"
            onClick={() => onLocationChosen(loc.id)}
          >
            <Typography
              align="center"
              style={{
                width: '100%',
                fontSize: '20pt',
              }}
            >
              {tidyName(loc.name)}
            </Typography>
          </ListItem>
        ))}
      </List>
    </PickerStyle>
  );
};

export function tidyName(location: string) {
  return location.split('.')?.[1]?.replace(/ Stock/, '') ?? location;
}

export const PickerStyle = styled.div`
  overflow: auto;

  .ListItem {
    padding: 1.2rem 1rem;

    &:nth-child(even) {
      background-color: #f7f7f7;
    }
    &:nth-child(odd) {
      background-color: #f1f1f130;
    }
  }
`;

export default LocationPicker;
