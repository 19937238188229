import React, { useEffect } from 'react';
import { SentryRoute } from '../SentryRoute';
import { useHistory, useLocation } from 'react-router-dom';
import { LoginPage } from './Pages/LoginPage';

import HomePage from './Pages/HomePage';
import NewTransferPage from './Pages/NewTransferPage';
import TransferPacksPage from './Pages/TransferPacksPage';
import NewTreatmentTransferPage from './Pages/NewTreatmentTransferPage';
import NewConsumeTransferPage from './Pages/NewConsumeTransferPage';
import { Loading } from '../Loading';
import { useActiveTransfer } from '../../util';

const Router: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const { loading, currentUser, transfer } = useActiveTransfer(false);

  const activeTransferId = transfer?.id;
  useEffect(() => {
    if (loading) {
      return;
    }

    const onPacksPage = pathname === '/mobile/packs';
    if (activeTransferId == null && onPacksPage) {
      history.push('/mobile');
    } else if (activeTransferId != null && !onPacksPage) {
      history.push('/mobile/packs');
    }
  }, [history, activeTransferId, pathname, loading]);

  if (!currentUser) {
    return <LoginPage />;
  }

  if (loading) {
    return <Loading mobile />;
  }

  if (activeTransferId != null) {
    return (
      <SentryRoute exact path="/mobile/packs" component={TransferPacksPage} />
    );
  }

  return (
    <>
      <SentryRoute exact path="/mobile" component={HomePage} />

      <SentryRoute exact path="/mobile/transfer" component={NewTransferPage} />
      <SentryRoute
        exact
        path="/mobile/treatment"
        component={NewTreatmentTransferPage}
      />
      <SentryRoute
        exact
        path="/mobile/consume-packs"
        component={NewConsumeTransferPage}
      />
    </>
  );
};

export default Router;
